import { FC } from 'react';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { FEATURES } from 'constants/features';

import WqAssignedToMeSection from 'views/WorkQueue/Sections/WqAssignToMeSection';
import WqOverdueReportsSection from 'views/WorkQueue/Sections/WqOverdueReportsSection';
import WqPatientReportsSection from 'views/WorkQueue/Sections/WqPatientReportsSection';
import WqTasksSection from 'views/WorkQueue/Sections/WqTasksSection';
import WqTicketsAndCallbackRequests from 'views/WorkQueue/Sections/WqTicketsAndCallbackRequestsSection';
import WqUrgentSection from 'views/WorkQueue/Sections/WqUrgentSection';

import { NoTickets } from 'components/Ticket/TicketsContainers/TicketsOverview';

import 'components/Ticket/TicketsContainers/TicketsOverview.scss';

interface Props {
  isLoading: boolean;
}

const WorkQueueSectionsComponent: FC<Props> = ({ isLoading }) => {
  const { settingsStore, workQueueStore, ticketFiltersStore } = useStores();

  const {
    assignedToMe,
    tasksDue,
    ticketsAndCallbackRequests,
    otherPatientReports,
    overdueReports,
    urgentPatientReports
  } = workQueueStore.sectionDataMap;

  const noTickets =
    urgentPatientReports.totalItems === 0 &&
    otherPatientReports.totalItems === 0 &&
    assignedToMe.totalItems === 0 &&
    overdueReports.totalItems === 0 &&
    otherPatientReports.totalItems === 0 &&
    ticketsAndCallbackRequests.totalItems === 0 &&
    tasksDue.totalPages === 0;

  if (noTickets && !isLoading) {
    return <NoTickets />;
  }

  const isAssignToMeSectionVisible = assignedToMe.totalItems > 0;

  const isTicketsAndCallbackRequestsSectionVisible =
    ticketsAndCallbackRequests.totalItems > 0 &&
    settingsStore.hasFeature(FEATURES.TICKETS_AND_CALLBACK_REQUESTS);

  const isUrgentPatientReportsSectionVisible =
    urgentPatientReports.totalItems > 0 &&
    settingsStore.hasFeature(FEATURES.URGENT_PATIENT_REPORTS);

  const isTasksDueSectionVisible =
    ticketFiltersStore.showTasks && settingsStore.hasFeature(FEATURES.TASKS_DUE);

  const isOtherPatientReportsSectionVisible =
    otherPatientReports.totalItems > 0 && settingsStore.hasFeature(FEATURES.OTHER_PATIENTS_REPORTS);

  const isOverdueReportsSectionVisible =
    overdueReports.totalItems > 0 && settingsStore.hasFeature(FEATURES.OVERDUE_REPORTS);

  return (
    <>
      {isAssignToMeSectionVisible && <WqAssignedToMeSection />}

      {isUrgentPatientReportsSectionVisible && <WqUrgentSection />}

      {isTicketsAndCallbackRequestsSectionVisible && <WqTicketsAndCallbackRequests />}

      {isTasksDueSectionVisible && <WqTasksSection />}

      {isOtherPatientReportsSectionVisible && <WqPatientReportsSection />}

      {isOverdueReportsSectionVisible && <WqOverdueReportsSection />}
    </>
  );
};

export default observer(WorkQueueSectionsComponent);
