// @ts-strict-ignore
import { FC } from 'react';

import { styled } from '@mui/material/styles';
import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';

import classNames from 'classnames';

import { flow, map, orderBy, uniqBy } from 'lodash/fp';
import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { getFormattedPhoneNumber } from 'utils/PhoneUtils';

import Patient from 'models/Patient';

import Ticket from 'models/Ticket';

import FemaleAvatarSrc from 'img/avatars/female_avatar.svg';
import MaleAvatarSrc from 'img/avatars/male_avater.svg';

import IconButton from 'components/Buttons/IconButton';
import Icon from 'components/Icons/Icon';

import { SaveDraftButton, SaveDraftMenu } from './CallLoggingSaveDraft';

import './CallLoggingHeader.scss';

interface CallLoggingHeaderProps {
  patient: Patient;
  minimized: boolean;
  onCloseClicked: () => void;
  onSaveDraftClicked: (isCallAttempt?: boolean) => void;
  onToggleMinimizeClicked: () => void;
  isSaveInProgress: boolean;
  isSummaryManuallyEdited: boolean;
}

const Header: FC<CallLoggingHeaderProps> = (props) => {
  const { ticketTypesStore, ticketsStore, callLoggingStore } = useStores();
  const { currentCall } = callLoggingStore;

  const getCallbackText = () => {
    const { ticketsMap } = ticketsStore;
    // ticket may be resolved while call logging is open, we must check ticket still exists
    const ticketIds = Array.from(currentCall.ticketIds).filter((ticketId) =>
      ticketsMap.get(ticketId)
    );
    if (ticketIds.length === 0) {
      return `Callback Patient: ${getFormattedPhoneNumber(props.patient.phone)}`;
    }

    if (ticketIds.length === 1) {
      return getCallbackTextForSingleTicket(ticketIds[0]);
    }

    const tickets = getSortedTicketsForCallbackText(ticketIds);

    if (tickets.length === 1 && tickets[0]?.id) {
      return getCallbackTextForSingleTicket(tickets[0].id);
    }

    return (
      <ul>
        {ticketIds.map((ticketId) => (
          <li key={ticketId}>{getCallbackTextForSingleTicket(ticketId, true)}</li>
        ))}
      </ul>
    );
  };

  const getSortedTicketsForCallbackText = (ticketIds: number[]) =>
    flow(
      uniqBy<number>((id) => {
        const ticket = ticketsStore.ticketsMap.get(id);
        return ticket.operatorTicket?.patientContactId;
      }),
      map(ticketsStore.ticketsMap.get),
      orderBy(['createdAt'], ['desc'])
    )(ticketIds) as Ticket[];

  const getCallbackTextForSingleTicket = (ticketId: number, withTicketData: boolean = false) => {
    const ticket = ticketsStore.ticketsMap.get(ticketId);
    if (ticket.isOperatorTicket) {
      let ticketName;
      if (ticket.isSymptomOperatorTicket) {
        ticketName = 'Symptom Report via Triage Call –';
      } else {
        const [subTypeId] = ticket.operatorTicket.subTicketTypeIds;
        const subTypeNode = ticketTypesStore.getOperatorTicketTypeById(subTypeId);
        ticketName = `${subTypeNode.fullName} –`;
      }
      const textStart = withTicketData ? `${ticketName}` : 'Callback';
      if (
        ticket.operatorTicket.patientContactId &&
        ticket.operatorTicket.patientContactId !== ticket.patientId
      ) {
        return `${textStart} ${ticket.operatorTicket.contactName} (${
          ticket.operatorTicket.contactRelationship
        }): ${getFormattedPhoneNumber(ticket.operatorTicket.contactPhoneNumber)}`;
      } else {
        return `${textStart} Patient: ${getFormattedPhoneNumber(props.patient.phone)}`;
      }
    } else {
      return `${
        withTicketData ? 'Patient Report –' : 'Callback'
      } Patient: ${getFormattedPhoneNumber(props.patient.phone)}`;
    }
  };

  const renderSaveDraftMenu = currentCall.ticketIds.size === 1;

  return (
    <div
      className={classNames('window-header', {
        minimized: props.minimized,
        active: callLoggingStore.isTimerActive()
      })}
    >
      <img
        src={props.patient.isFemale ? FemaleAvatarSrc : MaleAvatarSrc}
        alt="patient avatar"
        className="header-avatar"
      />
      <div className="header-details-container">
        <p className="header-name">{props.patient.fullName}</p>
        <p className="header-callback">{getCallbackText()}</p>
      </div>
      <div className="call-logging-window-actions">
        {renderSaveDraftMenu ? (
          <SaveDraftMenu
            onSaveDraftClicked={props.onSaveDraftClicked}
            call={currentCall}
            isSaveInProgress={props.isSaveInProgress}
            isSummaryManuallyEdited={props.isSummaryManuallyEdited}
          />
        ) : (
          <SaveDraftButton
            onSaveDraftClicked={() => {
              trackActionButtonAnalyticsEvent({
                action: AnalyticEventAction.SaveDraft,
                virtual_page: 'call logger',
                summary_manual_edited: props.isSummaryManuallyEdited ? 'yes' : 'no'
              });
              props.onSaveDraftClicked();
            }}
            isSaveInProgress={props.isSaveInProgress}
          />
        )}

        <StyledWrapper className="minimize-btn-wrapper">
          <i
            className="minimize-btn cursor-pointer"
            onClick={!props.minimized ? props.onToggleMinimizeClicked : null}
          />
        </StyledWrapper>

        <IconButton
          data-test-hook="closeCallWindowBtn"
          className="close-btn ml-3"
          testHook="closeCall"
          onClick={props.onCloseClicked}
        >
          <Icon.XThick />
        </IconButton>
      </div>
    </div>
  );
};

const StyledWrapper = styled('span')`
  display: inline-block;
  margin-left: 12px;
`;

export const CallLoggingHeader = observer(Header);
