import { PageMocksOverrides } from 'tests/models/pages/base-page/base-page.types';

import { GenerateSmartSummaryResponse } from 'fetchers/CallsFetcher';

export interface PatientPageOpenConfig {
  mocksOverrides?: PageMocksOverrides<PatientPageMocksOverrides>;
  patientId?: number;
  params?: {
    tab?: PatientPageTabs;
    extra?: string;
  };
}

export interface PatientPageMocksOverrides {
  patient?: any;
  clinicianAlerts?: any;
  auditPageView?: any;
  quickAdditionReasons?: any;
  patientDxCodes?: any;
  tickets?: any;
  tasks?: any;
  cmFilterData?: any;
  cmIneligibilityReasons?: any;
  cmOptOutReasons?: any;
  cmEndedReasons?: any;
  patientEpisodes?: any;
  cmPatientData?: any;
  smartSummary?: GenerateSmartSummaryResponse;
}

export enum PatientPageTabs {
  OpenItems = 'open-items',
  ResolvedTickets = 'resolved-tickets',
  Calls = 'calls',
  EpisodesAndTasks = 'episodes-and-tasks'
}

export enum ReportProtocolModalTab {
  Symptom = 'symptom-assessment',
  Oral = 'oral-oncolytics',
  DrugSpecific = 'drug-specific'
}
