// @ts-strict-ignore
import { FunctionComponent, ReactNode } from 'react';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { getRegimenText } from 'utils/OralOncoUtils';

import Patient from 'models/Patient';

import QuestionnaireAnswer, { ReportType } from 'models/QuestionnaireAnswer';

import Ticket from 'models/Ticket';

import DistressCausesReport from 'components/Ticket/TicketRow/DistressCausesReport';
import { DrugSpecificReportSummary } from 'components/Ticket/TicketRow/DrugSpecificReportSummary';
import { useTicketProviderLocation } from 'components/Ticket/TicketRow/shared';

import CovidReport from './CovidReport';
import OralReportSummary from './OralReportSummary';

interface Props {
  children: ReactNode;
  ticket: Ticket;
  report: QuestionnaireAnswer;
  patient: Patient;
}

const SymptomReportSummary: FunctionComponent<Props> = ({ ticket, report, patient, children }) => {
  const { constantsStore } = useStores();
  const firstReport = report.patientReportIndex === 0;
  const secondReport = report.patientReportIndex === 1;
  const providerLocationText = useTicketProviderLocation(ticket);

  const getCallbackText = () => {
    let callbackText = report.answer.callbackRequest.value ? 'Yes' : 'No';
    if (!report.answer.callbackRequest.value) {
      if (report.answer.callbackRequest.customMessage) {
        callbackText += ` - ${report.answer.callbackRequest.customMessage}`;
      } else if (report.callbackDenyReasonId) {
        callbackText += ` - ${
          constantsStore.getDenyCallbackReasonById(report.callbackDenyReasonId).text
        }`;
      }
    }
    return callbackText;
  };

  const getTitle = () => {
    if (report.type === ReportType.Oral) {
      const { regimen, isCustom } = report.answer.oral;
      return `Oral Oncolytics Assessment (${getRegimenText(regimen, isCustom)})`;
    }

    if (report.type === ReportType.DrugSpecific) {
      const drugId = patient.mainScheduledProtocol?.info?.drugId;
      const drug = constantsStore.getSpecificDrugById(drugId);
      const drugName = drug?.name;

      if (drugName) {
        return `Symptom Assessment (${drugName})`;
      }
    }

    return `Symptom Assessment`;
  };

  return (
    <>
      {firstReport && <div className="highlighted-title">First report submission</div>}
      {secondReport && <div className="highlighted-title">Second report submission</div>}

      <span className="bold title-text">{getTitle()}</span>
      {providerLocationText && <span className="subtitle-text"> — {providerLocationText}</span>}

      {report.answer.distressLevel >= 0 && (
        <div>
          <p className="mb-0">
            <span className="symptom-name">Distress:&nbsp;</span>
            {report.answer.distressLevel}
          </p>
        </div>
      )}

      {report.type === ReportType.Oral && <OralReportSummary report={report} patient={patient} />}

      {report.type === ReportType.Distress && (
        <DistressCausesReport report={report} patient={patient} />
      )}

      {report.type === ReportType.DrugSpecific && (
        <DrugSpecificReportSummary report={report} patient={patient} />
      )}

      {report.answer.callbackRequest?.isAnswered && (
        <div>
          <span className="callback-preference">Callback Preference: </span>
          {getCallbackText()}
        </div>
      )}

      {report.type === ReportType.COVID && <CovidReport report={report} patient={patient} />}

      {children}
    </>
  );
};

export default observer(SymptomReportSummary);
