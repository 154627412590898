const {
  REACT_APP_ENVIRONMENT,
  REACT_APP_VERSION,
  REACT_APP_OVERRIDE_API_HOST,
  REACT_APP_API_HOST,
  REACT_APP_REPORT_TO_ANALYTICS,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENVIRONMENT,
  REACT_APP_TRACES_SAMPLE_RATE,
  REACT_APP_SENTRY_RELEASE,
  REACT_APP_THOUGHT_SPOT_HOST,
  REACT_APP_TS_CARE_TIMER_CALLS_BOARD_ID,
  REACT_APP_TS_TICKET_MANAGEMENT_ID,
  REACT_APP_TS_PATIENTS_ID,
  REACT_APP_PATHWAY_ANALYSIS_ID,
  REACT_APP_PRACTICE_HEALTH_ID,
  REACT_APP_CM_ENROLLMENT_ID,
  REACT_APP_CM_BILLING_ID,
  REACT_APP_MIXPANEL_TOKEN,
  REACT_APP_IS_CANARY
} = process.env;

export const ENV = REACT_APP_ENVIRONMENT || 'development';
export const VERSION = REACT_APP_VERSION;
export const API_HOST = REACT_APP_OVERRIDE_API_HOST || REACT_APP_API_HOST;
export const REPORT_TO_ANALYTICS = Boolean(REACT_APP_REPORT_TO_ANALYTICS);
export const MIXPANEL_TOKEN = REACT_APP_MIXPANEL_TOKEN || 'fake token required for mixpanel';
export const SENTRY = {
  dsn: REACT_APP_SENTRY_DSN || '',
  environment: REACT_APP_SENTRY_ENVIRONMENT || '',
  ...(Boolean(REACT_APP_TRACES_SAMPLE_RATE) && {
    tracesSampleRate: Number(REACT_APP_TRACES_SAMPLE_RATE)
  }),
  release: REACT_APP_SENTRY_RELEASE || ''
};
export const THOUGHT_SPOT_HOST = REACT_APP_THOUGHT_SPOT_HOST;
export const TS_LIVE_BOARDS_IDS = {
  ticketManagement: REACT_APP_TS_TICKET_MANAGEMENT_ID,
  careTimerCalls: REACT_APP_TS_CARE_TIMER_CALLS_BOARD_ID,
  patients: REACT_APP_TS_PATIENTS_ID,
  pathwayAnalysis: REACT_APP_PATHWAY_ANALYSIS_ID,
  practiceHealth: REACT_APP_PRACTICE_HEALTH_ID,
  careManagementEnrollment: REACT_APP_CM_ENROLLMENT_ID,
  careManagementBilling: REACT_APP_CM_BILLING_ID
};
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || '';
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
export const AUTH0_AUDIENCE = `https://${AUTH0_DOMAIN}/api/v2/`;

export const publicUrlsEnvVars = {
  login: process.env.REACT_APP_LOGIN,
  loginApi: process.env.REACT_APP_LOGIN_API,
  portal: process.env.REACT_APP_PORTAL,
  portalCanary: process.env.REACT_APP_PORTAL_CANARY,
  api: process.env.REACT_APP_API,
  apiCanary: process.env.REACT_APP_API_CANARY
};

export const isCanary = REACT_APP_IS_CANARY === 'true';
