import { FC, MouseEvent, ReactNode } from 'react';

import { FormControl, FormLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { Optional } from 'utils/TypeUtils';

import {
  ExclusiveToggleButton,
  ExclusiveToggleProps
} from 'components/UIkit/atoms/Button/Toggle/ExclusiveToggleButton';

interface Props extends Optional<ExclusiveToggleProps, 'onChange' | 'value'> {
  name: string;
  label: ReactNode;
  required: boolean;
  disabled: boolean;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  onChange?: (event: MouseEvent<HTMLElement>, newSelection: string | number) => void;
}

export const FormExclusiveToggleButton: FC<Props> = ({
  label,
  required,
  name,
  options,
  disabled,
  fullWidth,
  labelPlacement = 'top',
  onChange
}) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;
  const isError = Boolean(errors[name]);

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required
      }}
      render={({ field }) => (
        <FormControl error={isError} fullWidth={fullWidth}>
          <FormLabel>{label}</FormLabel>

          <ExclusiveToggleButton
            options={options}
            value={field.value}
            onChange={(event, newSelection) => {
              field.onChange(newSelection);

              if (onChange) {
                onChange(event, newSelection);
              }
            }}
            fullWidth={fullWidth}
            disabled={disabled}
            isError={isError}
          />
        </FormControl>
      )}
    />
  );
};
