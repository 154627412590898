// @ts-strict-ignore
import { compact } from 'lodash/fp';

import Ticket, { TicketClass } from 'models/Ticket';
export interface TicketActionHandlers {
  resolve: () => any;
  resolveAndComment: () => any;
  reassign: () => any;
  logCall: () => any;
  rescheduleTask: () => any;
  editTask: () => any;
  editOperatorTicket: () => any;
  pickUp: () => any;
}

export const getTicketActionOptions = (
  actionHandlers: TicketActionHandlers,
  switchTaskStatusText: string,
  ticket: Ticket,
  isAssigned: boolean
) => {
  let assignText = isAssigned ? 'Reassign' : 'Assign';
  let options = [];

  const resolve = {
    text: <span>Resolve</span>,
    id: 'resolve',
    onClick: actionHandlers.resolve,
    testHook: `tooltip_option_resolve_${ticket.id}`
  };

  const resolveAndComment = {
    text: <span>Resolve & Comment</span>,
    id: 'resolveAndComment',
    onClick: actionHandlers.resolveAndComment,
    testHook: `tooltip_option_resolveAndComment_${ticket.id}`
  };

  const reassign = {
    text: <span>{assignText}</span>,
    id: 'reassign',
    onClick: actionHandlers.reassign,
    testHook: `tooltip_option_reassign_${ticket.id}`
  };

  const logCall = {
    text: <span>Log Call</span>,
    id: 'logCall',
    onClick: actionHandlers.logCall,
    testHook: `tooltip_option_logCall_${ticket.id}`
  };

  const editTask = {
    text: <span>Edit</span>,
    id: 'editTask',
    onClick: actionHandlers.editTask,
    testHook: `tooltip_option_editTask_${ticket.id}`
  };

  const editOperatorTicket = {
    text: <span>Edit</span>,
    id: 'editOperatorTicket',
    onClick: actionHandlers.editOperatorTicket,
    testHook: `tooltip_option_editOperatorTicket_${ticket.id}`
  };

  const rescheduleTask = {
    text: <span>Reschedule</span>,
    id: 'reschedule',
    onClick: actionHandlers.rescheduleTask,
    testHook: `tooltip_option_reschedule_${ticket.id}`
  };

  const pickupTicket = {
    text: <span>Pick Up</span>,
    id: 'pickUpTicket',
    onClick: actionHandlers.pickUp,
    testHook: `tooltip_option_pickUpTicket_${ticket.id}`
  };

  switch (ticket.class) {
    case TicketClass.patient:
      options = [logCall, reassign, resolve, resolveAndComment];
      break;
    case TicketClass.operator:
      options = [logCall, reassign, editOperatorTicket, resolve, resolveAndComment];
      break;
    case TicketClass.task:
      const editTaskOption = ticket.taskTicket.isNoneEpisode ? editTask : null;
      const pickUpOption = !isAssigned ? pickupTicket : null;
      options = [pickUpOption, reassign, rescheduleTask, editTaskOption];
      break;

    case TicketClass.callbackRequest:
      options = [logCall, reassign, resolve, resolveAndComment];
      break;

    default:
      return [];
  }
  return compact(options);
};
