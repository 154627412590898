import { PaginatorAction } from 'components/Paginator';
import { Section } from 'components/Ticket/TicketsContainers/TicketsContainers.constants';

import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action =
  | AnalyticEventAction.Expand
  | AnalyticEventAction.Collapse
  | AnalyticEventAction.RefreshUpdates
  | AnalyticEventAction.PageDropdown
  | AnalyticEventAction.NextPage
  | AnalyticEventAction.PreviousPage
  | AnalyticEventAction.LastPage
  | AnalyticEventAction.FirstPage;

interface Properties {
  action: Action;
  value: string;
  page_number?: number;
  virtual_page?: 'call logger';
  tab?: 'Key Questions' | 'All Questions';
}

export const trackSectionUsageAnalyticsEvent = ({
  action,
  value,
  ...optionalProps
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.SectionUsage,
    properties: { action, value, ...optionalProps }
  });
};

export const trackWqPaginationSectionUsageAnalyticsEvent = (
  sectionName: Section,
  action: PaginatorAction,
  pageNumber: number
) => {
  const analyticsAction = getPaginationAnalyticsAction(action);

  trackSectionUsageAnalyticsEvent({
    action: analyticsAction,
    value: sectionName,
    page_number: pageNumber
  });
};

export const getPaginationAnalyticsAction = (action: PaginatorAction) => {
  switch (action) {
    case 'first':
      return AnalyticEventAction.FirstPage;
    case 'previous':
      return AnalyticEventAction.PreviousPage;
    case 'last':
      return AnalyticEventAction.LastPage;
    case 'next':
      return AnalyticEventAction.NextPage;
    case 'select':
      return AnalyticEventAction.PageDropdown;
  }
};
