import { ErrorName } from 'errors';

import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

const httpService = new HttpService('Location');

export class SmsFetcher {
  static async sendSmsToNumber(phone: string, message: string): Promise<boolean> {
    return await httpService.post({
      url: API_URLS.SEND_SMS_TO_NUMBER,
      data: { phone, message },
      transformResponse: () => true,
      transformError: (error) => {
        if (error.name === ErrorName.UserBlockSendingSms) {
          error.ui.title = 'Patient Invited, but SMS Failed';
          error.ui.description =
            'While the patient was successfully invited / updated, the explanatory Invite SMS failed to send. This occurs when a patient has replied STOP to an SMS message from Canopy. To re-subscribe, the patient can text START to the same number.';
          return error;
        }
        error.ui.title = 'Failed to send sms to patient';
        return error;
      }
    });
  }
}
