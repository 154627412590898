// @ts-strict-ignore

import { sum } from 'lodash';
import { groupBy, map } from 'lodash/fp';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { computedFn } from 'mobx-utils';

import { RootStore } from 'mobx/stores';
import { DataMap } from 'mobx/stores/DataMap';

import CareManagementFetcher, { StatusUpdateBody } from 'fetchers/CareManagementFetcher';
import {
  AntineoplasticResponseType,
  CareManagementCheckInviteProgressResponse,
  CmStatusEnum,
  CmStatusResponseType,
  InsuranceResponseType,
  NextVisitLocationResponseType,
  ProviderResponseType
} from 'fetchers/responses/care-management.response';

import { getCurrentPageByUrl, getCurrentSortByUrl, getRecordsPerPageByUrl } from 'utils/urlUtils';

import {
  showCmBulkInviteInProgressToast,
  showCmBulkInviteIsDoneToast,
  updateAndShowCmBulkInviteIsDoneToast
} from 'utils/UserMessageUtils';

import { FEATURES } from 'constants/features';

import { ActiveTxDateRange } from 'models/ActiveTxDateRange';
import { CmPatient, CmStatus, EnrolledPatient } from 'models/CmPatient';

import { getEnrolledSortIdFromTable } from 'views/Pages/CareManagement/CareManagementEnrolledPatients';
import { getReportSortIdFromTable } from 'views/Pages/CareManagement/CareManagementReport';
import { ISelectOption } from 'views/Widgets/StyledSelect';

export interface CareManagementReportFiltersType {
  nameOrMrn: string;
  providers: ISelectOption<string>[];
  primaryInsurances: ISelectOption<InsuranceResponseType>[];
  secondaryInsurances: ISelectOption<InsuranceResponseType>[];
  cmStatuses: ISelectOption<number>[];
  nextVisitLocations: ISelectOption<number>[];
  antineoplasticAdmins: ISelectOption<number>[];
  activeTx: ISelectOption<ActiveTxDateRange>[];
}

export interface CareManagementEnrolledFiltersType {
  nameOrMrn: string;
  providers: ISelectOption<string>[];
  episodes: ISelectOption<number>[];
}

export enum InsuranceTypeParam {
  Primary = 'primaryInsurances',
  Secondary = 'secondaryInsurances'
}

export interface CmQueryType {
  nameOrMrn: string;
  providers: string[];
  primaryInsurances: InsuranceResponseType[];
  secondaryInsurances: InsuranceResponseType[];
  cmStatuses: number[];
  nextVisitLocations: number[];
  antineoplasticAdminsValues: number[];
  activeTx: string[];
}

const defaultEnrolledFilters: CareManagementEnrolledFiltersType = {
  nameOrMrn: '',
  providers: null,
  episodes: null
};

const defaultFilters: CareManagementReportFiltersType = {
  nameOrMrn: '',
  providers: null,
  primaryInsurances: null,
  secondaryInsurances: null,
  cmStatuses: null,
  nextVisitLocations: null,
  antineoplasticAdmins: null,
  activeTx: null
};

export const CM_REPORT_PAGE_SIZE = 50;
export const CM_BULK_INVITE_INTERVAL_DURATION = 10_000;
export const CARE_MANAGEMENT_REPORT_FILTERS_LOCAL_STORAGE_KEY = '_careManagementPageFilters';
export const CARE_MANAGEMENT_ENROLLED_PATIENTS_FILTERS_LOCAL_STORAGE_KEY =
  '_careManagementEnrolledPatientsFilters';
export const CARE_MANAGEMENT_BULK_INVITE_LOCAL_STORAGE_KEY = 'careManagementBulkInvite';

const valueMapper = map('value');

const enumToTextMap: Record<CmStatusEnum, string> = {
  [CmStatusEnum.Ineligible]: 'Ineligible',
  [CmStatusEnum.OptedOut]: 'Opted Out',
  [CmStatusEnum.EligibleForCcm]: 'Eligible: CCM',
  [CmStatusEnum.EligibleForPcm]: 'Eligible: PCM',
  [CmStatusEnum.None]: 'Enrollment Status',
  [CmStatusEnum.Ended]: 'Ended',
  [CmStatusEnum.Active]: 'Enrolled',
  [CmStatusEnum.SuggestedCcm]: 'Suggested: CCM',
  [CmStatusEnum.SuggestedPcm]: 'Suggested: PCM',
  [CmStatusEnum.InvitedCcm]: 'Invite Sent: CCM',
  [CmStatusEnum.InvitedPcm]: 'Invite Sent: PCM',
  [CmStatusEnum.ConsentedCcm]: 'Consented: CCM',
  [CmStatusEnum.ConsentedPcm]: 'Consented: PCM',
  [CmStatusEnum.LearnMoreCcm]: 'Learn More: CCM',
  [CmStatusEnum.LearnMorePcm]: 'Learn More: PCM',
  [CmStatusEnum.InvitationFailedCcm]: 'Invitation Failed: CCM',
  [CmStatusEnum.InvitationFailedPcm]: 'Invitation Failed: PCM'
};

const enumToFilterLabelMap = {
  [CmStatusEnum.Ineligible]: 'Ineligible',
  [CmStatusEnum.OptedOut]: 'Opted Out',
  [CmStatusEnum.EligibleForCcm]: 'Eligible: CCM',
  [CmStatusEnum.EligibleForPcm]: 'Eligible: PCM',
  [CmStatusEnum.None]: 'Not Enrolled',
  [CmStatusEnum.Ended]: 'Ended (Manually)',
  [CmStatusEnum.Active]: 'Enrolled',
  [CmStatusEnum.SuggestedCcm]: 'Suggested: CCM',
  [CmStatusEnum.SuggestedPcm]: 'Suggested: PCM',
  [CmStatusEnum.InvitedCcm]: 'Invite Sent: CCM',
  [CmStatusEnum.InvitedPcm]: 'Invite Sent: PCM',
  [CmStatusEnum.ConsentedCcm]: 'Consented: CCM',
  [CmStatusEnum.ConsentedPcm]: 'Consented: PCM',
  [CmStatusEnum.LearnMoreCcm]: 'Learn More: CCM',
  [CmStatusEnum.LearnMorePcm]: 'Learn More: PCM',
  [CmStatusEnum.InvitationFailedCcm]: 'Invite Failed: CCM',
  [CmStatusEnum.InvitationFailedPcm]: 'Invite Failed: PCM'
};

const convertEnumToLabel = ({ id, name }: { id: CmStatusEnum; name: string }) =>
  enumToFilterLabelMap[id] || name;

export class CareManagementReportStore {
  rootStore: RootStore;

  @observable
  eligiblePatients: DataMap<CmPatient> = null;

  @observable
  enrolledPatients: DataMap<EnrolledPatient> = null;

  @observable
  totalPages = 0;

  @observable
  totalEligiblePatientsCount = 0;

  @observable
  providers = new DataMap<ProviderResponseType>();

  @observable
  nextVisitLocations = new DataMap<NextVisitLocationResponseType>();

  @observable
  providersForEnrolledPatients = new DataMap<ProviderResponseType>();

  @observable
  primaryInsurances: InsuranceResponseType[] = [];

  @observable
  secondaryInsurances: InsuranceResponseType[] = [];

  @observable
  antineoplasticAdmins: AntineoplasticResponseType[] = [];

  @observable
  filtersInitialized = false;

  @observable
  enrolledFilters = defaultEnrolledFilters;

  @observable
  reportFilters = defaultFilters;

  @observable
  bulkInviteSessionId: string = '';

  @observable
  checkBulkInviteProgressInterval: NodeJS.Timeout;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @action
  async fetchCareManagementEnrolled() {
    const recordsPerPage = getRecordsPerPageByUrl() || CM_REPORT_PAGE_SIZE;
    const pageNumber = getCurrentPageByUrl();
    const sorting = getCurrentSortByUrl();
    if (sorting) {
      sorting.sortBy = getEnrolledSortIdFromTable(sorting.sortBy);
    }
    const careManagementEnrolledResponse = await CareManagementFetcher.fetchCareManagementEnrolled({
      ...this.enrolledFiltersToQuery,
      ...sorting,
      pageNumber,
      recordsPerPage
    });
    runInAction(() => {
      this.totalPages = careManagementEnrolledResponse.totalPages;
      if (!this.enrolledPatients) {
        this.enrolledPatients = new DataMap<EnrolledPatient>();
      }
      this.enrolledPatients.setItems(careManagementEnrolledResponse.enrolledPatients);
    });
  }

  @action
  async fetchCareManagementReport() {
    const recordsPerPage = getRecordsPerPageByUrl() || CM_REPORT_PAGE_SIZE;
    const pageNumber = getCurrentPageByUrl();
    const sorting = getCurrentSortByUrl();
    if (sorting) {
      sorting.sortBy = getReportSortIdFromTable(sorting.sortBy);
    }

    const careManagementReportResponse = await CareManagementFetcher.fetchCareManagementReport({
      ...this.reportFiltersToQuery,
      ...sorting,
      pageNumber,
      recordsPerPage
    });
    runInAction(() => {
      this.totalPages = careManagementReportResponse.totalPages;
      this.totalEligiblePatientsCount = careManagementReportResponse.totalEligiblePatientsCount;
      if (!this.eligiblePatients) {
        this.eligiblePatients = new DataMap<CmPatient>();
      }
      this.eligiblePatients.setItems(careManagementReportResponse.eligiblePatients);
    });
  }

  @action
  async fetchCareManagementFilterData() {
    const {
      providers,
      providersForEnrolledPatients,
      primaryInsurances,
      secondaryInsurances,
      nextVisitLocations,
      antineoplasticAdmins
    } = await this.rootStore.stores.constantsStore.fetchCareManagementFilterData();

    runInAction(() => {
      this.filtersInitialized = true;
      this.providers.setItems(providers);
      this.providersForEnrolledPatients.setItems(providersForEnrolledPatients);
      this.primaryInsurances = primaryInsurances;
      this.secondaryInsurances = secondaryInsurances;
      this.nextVisitLocations.setItems(nextVisitLocations);
      this.antineoplasticAdmins = antineoplasticAdmins;
    });
  }

  @action
  async updateStatus(
    cmPatientId: number,
    statusUpdateBody: StatusUpdateBody,
    onUpdate: () => void
  ) {
    await CareManagementFetcher.updatePatientStatus(cmPatientId, statusUpdateBody);
    onUpdate();
  }

  @action
  setEnrolledFilters(filters: CareManagementEnrolledFiltersType) {
    this.enrolledFilters = filters;
  }

  @action
  setReportFilters(filters: CareManagementReportFiltersType) {
    this.reportFilters = filters;
  }

  @action
  resetPagination = () => {
    this.totalPages = 0;
  };

  @action
  resetStore() {
    this.resetPagination();
    this.cmStatuses.clear();
    this.providers.clear();
    this.filtersInitialized = false;
    this.resetEnrolled();
    this.resetEligible();
  }

  @action
  resetEnrolled() {
    this.enrolledPatients = null;
  }

  resetEligible() {
    this.eligiblePatients = null;
  }

  @computed
  get cmStatuses() {
    return this.rootStore.stores.constantsStore.cmStatuses;
  }

  @computed
  get enrolledProvidersForCmSelect() {
    return this.providersForEnrolledPatients.items.map(({ id, name }) => ({
      value: id,
      label: name
    }));
  }

  @computed
  get providersForCmSelect() {
    return this.providers.items.map(({ id, name }) => ({ value: id, label: name }));
  }

  cmStatusesForSelect = computedFn((episodesOnly: boolean) => {
    const createSelectOption = ({ id, name }: { id: number; name: string }) => {
      const isEnum = CmStatus.NonEpisodeIds.includes(id);
      const labelPrefix = isEnum || episodesOnly ? '' : 'Enrolled: ';
      const label = isEnum ? convertEnumToLabel({ id, name }) : name;
      return { value: id, label: `${labelPrefix}${label}` };
    };

    if (episodesOnly) {
      return this.cmStatuses.items
        .filter((item) => !CmStatus.NonEpisodeIds.includes(item.id))
        .map(createSelectOption);
    }

    const { items } = this.cmStatuses;

    const hasPatientLedEnrollmentFeature = this.rootStore.stores.settingsStore.hasFeature(
      FEATURES.PATIENT_LED_ENROLLMENT
    );

    const hasSuggestedFeature = this.rootStore.stores.settingsStore.hasFeature(
      FEATURES.CM_SUGGESTED_STATUSES
    );

    let newItems: CmStatusResponseType[] = items;

    if (!hasPatientLedEnrollmentFeature) {
      newItems = newItems.filter(
        (item) => !CmStatus.PatientLedEnrollmentStatuses.includes(item.id)
      );
    }

    if (!hasSuggestedFeature) {
      newItems = newItems.filter((item) => !CmStatus.SuggestedStatuses.includes(item.id));
    }

    const classifiedItems = groupBy(this.classifyOption, newItems);

    const ccmOptions = (classifiedItems.ccmOptions || []).map(createSelectOption);
    const pcmOptions = (classifiedItems.pcmOptions || []).map(createSelectOption);
    const nonEpisodeOptions = (classifiedItems.nonEpisodeOptions || []).map(createSelectOption);
    const otherOptions = (classifiedItems.otherOptions || []).map(createSelectOption);

    return [
      ...nonEpisodeOptions,
      { label: 'CCM', options: ccmOptions },
      { label: 'PCM', options: pcmOptions },
      { label: 'ENROLLED', options: otherOptions }
    ];
  });

  @computed
  get antineoplasticForSelect() {
    return this.antineoplasticAdmins.map(({ id, name }) => ({ value: id, label: name }));
  }

  @computed
  get nextVisitLocationsForSelect() {
    return this.nextVisitLocations.items.map(({ id, name }) => ({ value: id, label: name }));
  }

  insuranceFilters = computedFn((type: InsuranceTypeParam) =>
    this[type].map((insuranceItem) => ({
      value: insuranceItem,
      label: insuranceItem.name
    }))
  );

  statusText(cmStatus: CmStatus) {
    let statusText = enumToTextMap[cmStatus.status];
    let extraText = '';

    if (cmStatus.isActive) {
      const episodeData = this.cmStatuses.get(cmStatus.episodeId);

      if (!episodeData) {
        // todo: add error?
      }

      const nameText = episodeData?.name ? `: ${episodeData.name}` : '';
      statusText = `${statusText}${nameText}`;
    }

    if (cmStatus.isEndedManually) {
      statusText = `${statusText} (Manually)`;
    }

    if (cmStatus.isInviteFailed && cmStatus.reason) {
      extraText += `Reason: ${cmStatus.reason.title} - `;
    }

    if (cmStatus.date) {
      if (cmStatus.isActive) {
        extraText = `${cmStatus.isExpired ? 'Expired' : 'Start'} `;
      }

      extraText += cmStatus.formattedDate;
    }

    if (cmStatus.hasSuggestions) {
      statusText = enumToTextMap[CmStatusEnum.None];

      if (this.rootStore.stores.settingsStore.hasFeature(FEATURES.CM_SUGGESTED_STATUSES)) {
        extraText = `Suggestion: ${cmStatus.status === CmStatusEnum.SuggestedCcm ? 'CCM' : 'PCM'}`;
      }
    }

    return { statusText, extraText };
  }

  episodeById(episodeId: number) {
    return this.cmStatuses.get(episodeId);
  }

  episodeName(cmStatus: CmStatus) {
    const episodeData = this.cmStatuses.get(cmStatus.episodeId);
    return episodeData?.name;
  }

  @computed
  get enrolledFiltersToQuery(): CareManagementEnrolledFiltersType {
    const { nameOrMrn, providers, episodes } = this.enrolledFilters;
    return {
      nameOrMrn,
      providers: valueMapper<ISelectOption<string>>(providers),
      episodes: valueMapper<ISelectOption<number>>(episodes)
    };
  }

  @computed
  get reportFiltersToQuery(): CmQueryType {
    const {
      nameOrMrn,
      providers,
      cmStatuses,
      secondaryInsurances,
      primaryInsurances,
      nextVisitLocations,
      antineoplasticAdmins,
      activeTx
    } = this.reportFilters;
    return {
      nameOrMrn,
      providers: valueMapper<ISelectOption<string>>(providers),
      cmStatuses: valueMapper<ISelectOption<number>>(cmStatuses),
      secondaryInsurances: valueMapper<ISelectOption<InsuranceResponseType>>(secondaryInsurances),
      primaryInsurances: valueMapper<ISelectOption<InsuranceResponseType>>(primaryInsurances),
      nextVisitLocations: valueMapper<ISelectOption<number>>(nextVisitLocations),
      antineoplasticAdminsValues: valueMapper<ISelectOption<number>>(antineoplasticAdmins),
      activeTx: valueMapper<ISelectOption<ActiveTxDateRange>>(activeTx)
    };
  }

  private classifyOption = (item: CmStatusResponseType) => {
    if (CmStatus.CcmStatusIds.includes(item.id)) {
      return 'ccmOptions';
    } else if (CmStatus.PcmStatusIds.includes(item.id)) {
      return 'pcmOptions';
    } else if (CmStatus.NonEpisodeIds.includes(item.id)) {
      return 'nonEpisodeOptions';
    } else if (item.isActive) {
      return 'otherOptions';
    }
    return 'unclassified';
  };

  @action
  async startRecoverCheckBulkInviteProgressFlow(sessionId: string) {
    this.setBulkInviteSessionId(sessionId);
    const { isDone, cmBulkInviteData } = await this.isCheckBulkInviteProgressFlowDone();

    if (isDone) {
      this.clearBulkInviteSessionId();
      showCmBulkInviteIsDoneToast(
        cmBulkInviteData,
        this.rootStore.stores.uiStore.clearBulkInviteToastId
      );
      this.fetchCareManagementReport();
      return;
    }

    this.rootStore.stores.uiStore.bulkInviteToastId = showCmBulkInviteInProgressToast(
      cmBulkInviteData.totalRequestCount
    );
    this.startCheckBulkInviteProgressInterval();
  }

  @action
  async startCheckBulkInviteProgressFlow(sessionId: string, bulkInvitationsCount: number) {
    this.setBulkInviteSessionId(sessionId);
    this.rootStore.stores.uiStore.bulkInviteToastId =
      showCmBulkInviteInProgressToast(bulkInvitationsCount);
    localStorage.setItem(CARE_MANAGEMENT_BULK_INVITE_LOCAL_STORAGE_KEY, sessionId);
    const { isDone, cmBulkInviteData } = await this.isCheckBulkInviteProgressFlowDone();

    if (isDone) {
      this.clearBulkInviteSessionId();
      updateAndShowCmBulkInviteIsDoneToast(
        this.rootStore.stores.uiStore.bulkInviteToastId,
        cmBulkInviteData,
        this.rootStore.stores.uiStore.clearBulkInviteToastId
      );
      this.fetchCareManagementReport();
      return;
    }

    this.startCheckBulkInviteProgressInterval();
  }

  @action
  startCheckBulkInviteProgressInterval() {
    this.checkBulkInviteProgressInterval = setInterval(async () => {
      const { isDone, cmBulkInviteData } = await this.isCheckBulkInviteProgressFlowDone();

      if (isDone) {
        this.clearBulkInviteSessionId();
        updateAndShowCmBulkInviteIsDoneToast(
          this.rootStore.stores.uiStore.bulkInviteToastId,
          cmBulkInviteData,
          this.rootStore.stores.uiStore.clearBulkInviteToastId
        );
        clearInterval(this.checkBulkInviteProgressInterval);
        this.checkBulkInviteProgressInterval = null;
        this.fetchCareManagementReport();
      }
    }, CM_BULK_INVITE_INTERVAL_DURATION);
  }

  @action
  async isCheckBulkInviteProgressFlowDone(): Promise<{
    isDone: boolean;
    cmBulkInviteData: CareManagementCheckInviteProgressResponse;
  }> {
    const cmBulkInviteData = await CareManagementFetcher.checkInviteProgress(
      this.bulkInviteSessionId
    );

    const { totalRequestCount, success, fail, incompatible } = cmBulkInviteData;

    if (sum([success, fail, incompatible]) === totalRequestCount) {
      return { isDone: true, cmBulkInviteData };
    }

    return { isDone: false, cmBulkInviteData };
  }

  @action
  setBulkInviteSessionId(sessionId: string) {
    this.bulkInviteSessionId = sessionId;
  }

  @action
  clearBulkInviteSessionId() {
    this.setBulkInviteSessionId('');
  }
}

export default CareManagementReportStore;
