import { ForwardedRef, MouseEvent, ReactNode } from 'react';

import { ButtonBase, css } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Testable } from 'utils/TypeUtils';

import { getTextButtonColor } from 'components/UIkit/atoms/Button/utils';

export interface ButtonProps extends Testable {
  children: ReactNode;
  onClick: (event: MouseEvent<HTMLButtonElement>) => any;
  disabled?: boolean;
  ref?: ForwardedRef<HTMLButtonElement>;
  id?: string;
}

const OUTLINED_MEDIUM_MIN_WIDTH = '115px';
const FILLED_MIN_WIDTH = '115px';

// Canopy buttons: https://www.figma.com/file/dvGOw2FFkptFtzpBTfEsNF/Clinician-Design-System?node-id=9%3A7091
//shouldForwardProp option -> https://mui.com/system/styled/

export const StyledFilled = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'fullWidth'
})<{ fullWidth: boolean }>(
  ({ theme, fullWidth }) => css`
    color: ${theme.palette.natural.white};
    background-color: ${theme.palette.primary.main};
    width: ${fullWidth ? '100%' : 'initial'};
    min-width: ${FILLED_MIN_WIDTH};

    &:hover {
      background-color: ${theme.palette.primary.dark};
    }

    &:disabled {
      background-color: ${theme.palette.natural.inactiveBackground};
      color: ${theme.palette.text.disabled};
    }
  `
);

export const StyledOutlined = styled(ButtonBase, {
  shouldForwardProp: (prop) =>
    prop !== 'color' && prop !== 'size' && prop !== 'isActive' && prop !== 'fullWidth'
})<{
  color?: 'error';
  size?: 'small' | 'medium';
  isActive?: boolean;
  fullWidth?: boolean;
}>(
  ({ theme, color, size, isActive, fullWidth }) => css`
    color: ${color === 'error' ? theme.palette.error.dark : theme.palette.text.primary};
    border: 1px solid ${theme.palette.natural.border};
    background-color: ${isActive ? theme.palette.natural.hover : theme.palette.natural.white};
    width: ${fullWidth ? '100%' : 'initial'};
    min-width: ${size === 'medium' ? OUTLINED_MEDIUM_MIN_WIDTH : 'fit-content'};

    &:hover,
    &:active {
      background-color: ${theme.palette.natural.hover};
    }

    &:disabled {
      border: 1px solid ${theme.palette.natural.border};
      background-color: ${theme.palette.natural.inactiveBackground};
      color: ${theme.palette.text.disabled};
    }
  `
);

export const StyledText = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'variant'
})<{
  variant?: 'primary' | 'secondary';
  isActive: boolean;
}>(
  ({ theme, variant, isActive }) => css`
    height: auto;
    color: ${getTextButtonColor(variant || 'primary', isActive)};

    &:hover {
      color: ${variant === 'primary' ? theme.palette.primary.dark : theme.palette.secondary.main};

      span {
        color: ${variant === 'primary' ? theme.palette.primary.dark : theme.palette.secondary.main};
      }
    }

    &:disabled {
      color: ${theme.palette.text.disabled};
    }
  `
);
