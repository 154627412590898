import { RootStore } from 'mobx/stores';

import QaFetcher, {
  CmRowRequestData,
  DxRequestData,
  EmrRowRequestData,
  LocationRequestData,
  ProviderData
} from 'fetchers/QaFetcher';

import { showToast } from 'utils/UserMessageUtils';

export class QaStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  resetInstitution = async () => {
    showToast({ message: 'Resetting institution...' });
    await QaFetcher.resetInstitution();
    window.location.reload();
  };

  clearPatients = async () => {
    showToast({ message: 'Clearing patients...' });
    await QaFetcher.clearPatients();
    window.location.reload();
  };

  addEmrRow = (emrRow: EmrRowRequestData) => {
    showToast({ message: 'Adding row to EMR...' });
    QaFetcher.createEmrRow(emrRow);
  };

  addCmRow = (cmRow: CmRowRequestData) => {
    showToast({ message: 'Adding row to Care Management...' });
    QaFetcher.createCmRow(cmRow);
  };

  addDiagnosisToPatient = (dxRequest: DxRequestData) => {
    showToast({ message: 'Adding Diagnosis to Patient...' });
    QaFetcher.createDxRow(dxRequest);
  };

  adjustPatientTimeline = (patientId: number, moveHours: number) => {
    showToast({ message: 'Adjusting Patient Timeline...' });
    QaFetcher.adjustPatientTimeline(patientId, moveHours);
  };

  addProvider = (provider: ProviderData) => {
    showToast({ message: 'Adding Provider...' });
    QaFetcher.createProvider(provider);
  };

  addTreatment = (emrPatientIds: string[]) => {
    showToast({ message: 'Adding Treatment...' });
    QaFetcher.addTreatment(emrPatientIds);
  };

  addLocation = (location: LocationRequestData) => {
    showToast({ message: 'Adding Location...' });
    QaFetcher.createLocation(location);
  };

  async generateFakeReports() {
    await QaFetcher.generateFakeReports();
  }

  async resetDemoEnvironment() {
    await QaFetcher.resetDemoEnvironment();
  }

  async generateNotifications() {
    await QaFetcher.generateNotifications();
    showToast({ message: 'Generating new notifications for clinician...' });
  }

  async adjustPatientAutoProtocol(patientId: number) {
    await QaFetcher.adjustPatientAutoProtocol(patientId);
    showToast({ message: 'Adjusting patient auto protocol' });
  }

  addOralAutoProtocol = (patientId: number) => {
    return QaFetcher.addOralAutoProtocol(patientId);
  };
}

export default QaStore;
