export enum AnalyticEventName {
  Login = 'Login',
  ActionButton = 'Action Button',
  HeaderMenu = 'Header Menu',
  SearchHeaderUsage = 'Search Header Usage',
  SearchUsage = 'Search Usage',
  SectionUsage = 'Section Usage',
  ActionsMenu = 'Actions Menu',
  ShowDetails = 'Show More',
  EditTicketModal = 'Edit Ticket Modal',
  PathwayMenu = 'Pathway Menu',
  ReassignMenu = 'Reassign Menu',
  RequestReport = 'Request Report',
  FilterUsage = 'Filter Usage',
  TabNavigation = 'Tab Navigation',
  SortBy = 'Sort by',
  ExpandAllDxToggle = 'Expand All Dx Toggle',
  StatusMenu = 'Status Menu',
  InputUsage = 'Input Usage',
  SubmitCallSideMenu = 'Submit Call Menu',
  PathwayAnswer = 'Pathway Answer',
  SaveDraftMenu = 'Save Draft Menu',
  EditTimerModal = 'Edit Timer Modal',
  EditConnectedTicketsModal = 'Edit Connected Tickets Modal',
  EditPatientModal = 'Edit Patient Modal',
  Multiselect = 'Multiselect',
  EPROPTimeBuckets = 'ePRO Time Buckets',
  TaskDateFilter = 'Task Date Filter',
  EnrollEpisodeModal = 'Enroll Episode Modal',
  ReportProtocolModal = 'Report Protocol Modal',
  OptOutRemoteMonitoringModal = 'Opt Out Remote Monitoring Modal',
  OptOutRemoteMonitoring = 'Opt Out Remote Monitoring',
  Scroll = 'Scroll',
  Popup = 'Popup',
  DEBUG = 'DEBUG', // NOT FOR ACTUAL USE, special cases and temporarily only
  SectionHeaderView = 'Section Header View',
  NotificationsPanel = 'Notifications Panel',
  InvitationsToastSummary = 'Invitations Toast Summary',
  TaskStatusMenu = 'Task Status Menu'
}
