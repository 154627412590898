import React, { FC, useEffect } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackTabNavigationAnalyticsEvent } from 'analytics/events/tab-navigation';
import { observer } from 'mobx-react';

import { RouteComponentProps } from 'react-router-dom';

import { useStores } from 'mobx/hooks/useStores';

import { sortParamsToUrlString } from 'utils/urlUtils';

import { defaultCmSorting, defaultEnrolledCmSorting } from 'constants/careManagement.const';

import { useDebouncedCmReportData } from 'views/Pages/CareManagement/CareManagement.hooks';
import CareManagementEnrolledPatients from 'views/Pages/CareManagement/CareManagementEnrolledPatients';
import { CareManagementEnrollmentProvider } from 'views/Pages/CareManagement/CareManagementEnrollment/CareManagementEnrollmentProvider';
import CareManagementReport from 'views/Pages/CareManagement/CareManagementReport';
import ToggleBar, { TabOption } from 'views/Widgets/ToggleBar';

import Loading from 'components/Loaders/Loading';

import './CareManagementPage.scss';

export enum CareManagementTabs {
  ENROLLMENT_MANAGER = 'all',
  ENROLLED_PATIENTS = 'enrolled'
}

export type CareManagementTabNames = 'Enrollment Manager' | 'Enrolled Patients';

export const cmTabsToNames: Record<CareManagementTabs, CareManagementTabNames> = {
  [CareManagementTabs.ENROLLMENT_MANAGER]: 'Enrollment Manager',
  [CareManagementTabs.ENROLLED_PATIENTS]: 'Enrolled Patients'
};

const tabOptions: TabOption<CareManagementTabs>[] = [
  {
    value: CareManagementTabs.ENROLLMENT_MANAGER,
    label: 'Enrollment Manager',
    testHook: 'reports-tab'
  },
  {
    value: CareManagementTabs.ENROLLED_PATIENTS,
    label: 'Enrolled Patients',
    testHook: 'enrolled-tab'
  }
];

const CareManagementPage: FC<RouteComponentProps<{ activeTab: CareManagementTabs }>> = (props) => {
  const { activeTab } = props.match.params;
  const { careManagementReportStore } = useStores();
  const fetchCmReportData = useDebouncedCmReportData();

  useEffect(
    function fetchFilterData() {
      careManagementReportStore.fetchCareManagementFilterData();
      return () => {
        careManagementReportStore.resetStore();
      };
    },
    [careManagementReportStore]
  );

  const handleTabSelected = (option: CareManagementTabs) => {
    trackTabNavigationAnalyticsEvent({
      action: AnalyticEventAction.TabSelect,
      value: cmTabsToNames[option],
      tab: cmTabsToNames[activeTab]
    });

    if (option !== activeTab) {
      const defaultSort =
        option === CareManagementTabs.ENROLLMENT_MANAGER
          ? defaultCmSorting
          : defaultEnrolledCmSorting;
      const defaultCmUrlParams = sortParamsToUrlString(defaultSort.sortBy, defaultSort.sortAsc);
      props.history.push(`/care-management/${option}?${defaultCmUrlParams}`);
    }
  };
  const loading = !careManagementReportStore.filtersInitialized;

  return (
    <div className="animated fadeIn wide-view dashboard-view care-management-page">
      <h1 className="title">Care Management</h1>
      <ToggleBar
        options={tabOptions}
        selected={activeTab}
        onOptionSelected={handleTabSelected}
        id="care-management-toggle"
        size="small"
        isSquared
        className="care-toggle"
      />
      {loading ? (
        <Loading primaryColor />
      ) : activeTab === CareManagementTabs.ENROLLMENT_MANAGER ? (
        <CareManagementEnrollmentProvider onUpdateStatus={fetchCmReportData}>
          <CareManagementReport {...props} />
        </CareManagementEnrollmentProvider>
      ) : (
        <CareManagementEnrolledPatients {...props} />
      )}
    </div>
  );
};

export default observer(CareManagementPage);
