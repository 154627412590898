import { ReactElement, ReactNode } from 'react';

import { css, styled } from '@mui/material';

import { isNil } from 'lodash/fp';

import { SymptomTicketUrgency, OperatorTicketUrgency } from 'models/OperatorTicket';
import { TicketClass } from 'models/Ticket';
import { TicketTypeKind } from 'models/TicketTypes';

import Icon from 'components/Icons/Icon';

export enum BadgeNames {
  MILD = 'mild',
  CALLBACK = 'callback',
  STANDARD_OPERATOR = 'standard-operator',
  HIGH_OPERATOR = 'high-operator',
  ATTENTION_TODAY = 'attention-today',
  IMMEDIATE_ATTENTION = 'immediate-attention',
  NURSE_REVIEW = 'nurse-review',
  TASK = 'task'
}

export const StyledImmediateAttentionUrgencyContainer = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.error.dark};
  `
);

export const StyledAttentionTodayUrgencyContainer = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.warning.main};
  `
);

const StyledNurseReviewUrgencyContainer = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.tag.tag2};
  `
);

const StyledHighUrgencyContainer = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.natural.white};
  `
);

const StyledStandardUrgencyContainer = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.natural.white};
  `
);

const StyledMildContainer = styled('div')(
  ({ theme }) => css`
    width: 24px;
    height: 24px;
    border-radius: ${theme.borderRadius.xSmall};
    background-color: ${theme.palette.success.dark};
  `
);

export const badgeIconsMap: Record<BadgeNames, ReactElement> = {
  [BadgeNames.MILD]: <StyledMildContainer />,
  [BadgeNames.STANDARD_OPERATOR]: (
    <StyledStandardUrgencyContainer>
      <Icon.StandardOperatorUrgency />
    </StyledStandardUrgencyContainer>
  ),
  [BadgeNames.CALLBACK]: <div className="standard-operator circle-badge" />, // black circle
  [BadgeNames.TASK]: <div className="standard-operator circle-badge" />, // black circle
  [BadgeNames.HIGH_OPERATOR]: (
    <StyledHighUrgencyContainer>
      <Icon.HighOperatorUrgency />
    </StyledHighUrgencyContainer>
  ),
  [BadgeNames.NURSE_REVIEW]: (
    <StyledNurseReviewUrgencyContainer>
      <Icon.NurseReview />
    </StyledNurseReviewUrgencyContainer>
  ),
  [BadgeNames.ATTENTION_TODAY]: (
    <StyledAttentionTodayUrgencyContainer>
      <Icon.AttentionToday />
    </StyledAttentionTodayUrgencyContainer>
  ),
  [BadgeNames.IMMEDIATE_ATTENTION]: (
    <StyledImmediateAttentionUrgencyContainer>
      <Icon.ImmediateAttention />
    </StyledImmediateAttentionUrgencyContainer>
  )
};

export const symptomSeverityToBadgeMap = {
  [SymptomTicketUrgency.NurseReview]: BadgeNames.NURSE_REVIEW,
  [SymptomTicketUrgency.AttentionToday]: BadgeNames.ATTENTION_TODAY,
  [SymptomTicketUrgency.ImmediateAttention]: BadgeNames.IMMEDIATE_ATTENTION
};

export const operatorUrgencyToBadgeMap = {
  [TicketTypeKind.other]: {
    [OperatorTicketUrgency.High]: BadgeNames.HIGH_OPERATOR,
    [OperatorTicketUrgency.Standard]: BadgeNames.STANDARD_OPERATOR
  },
  [TicketTypeKind.symptom]: {
    [SymptomTicketUrgency.NurseReview]: BadgeNames.NURSE_REVIEW,
    [SymptomTicketUrgency.AttentionToday]: BadgeNames.ATTENTION_TODAY,
    [SymptomTicketUrgency.ImmediateAttention]: BadgeNames.IMMEDIATE_ATTENTION
  }
};

export const getBadgeForTicket = (
  ticketClass: TicketClass,
  ticketKind: TicketTypeKind | undefined,
  urgency: OperatorTicketUrgency | SymptomTicketUrgency | null,
  isResolved?: boolean
): ReactNode => {
  if (isResolved) {
    return null;
  }

  switch (ticketClass) {
    case TicketClass.task:
      return badgeIconsMap[BadgeNames.TASK];

    case TicketClass.patient: {
      if (!isNil(urgency)) {
        return badgeIconsMap[symptomSeverityToBadgeMap[urgency]];
      }
      return badgeIconsMap[BadgeNames.MILD];
    }
    case TicketClass.operator: {
      if (ticketKind) {
        return badgeIconsMap[
          operatorUrgencyToBadgeMap[ticketKind][urgency as OperatorTicketUrgency]
        ];
      }

      return null;
    }
    case TicketClass.callbackRequest:
      return badgeIconsMap[BadgeNames.STANDARD_OPERATOR];

    default:
      return null;
  }
};
