import { ActionMeta } from 'react-select';

import { isAllDigits } from 'utils/ValidationUtils';

import { FilterName } from 'views/Filters/filters.types';

import { CareManagementTabNames } from 'views/Pages/CareManagement/CareManagementPage';

import { SelectActionMetaName } from 'components/UIkit/atoms/Dropdown/SelectUtils';

import { AnalyticsService, AnalyticEventName, AnalyticEventAction } from '../';

type Action =
  | AnalyticEventAction.Remove
  | AnalyticEventAction.Add
  | AnalyticEventAction.Clear
  | AnalyticEventAction.Preselected
  | AnalyticEventAction.SearchedByName
  | AnalyticEventAction.SearchedByMrn
  | AnalyticEventAction.SearchedByTask
  | AnalyticEventAction.SearchedByTasks
  | AnalyticEventAction.SearchedByLocations
  | AnalyticEventAction.SearchedByProviders
  | AnalyticEventAction.SearchedByTicketType;

type Tab = CareManagementTabNames;

interface Properties {
  action: Action;
  value: FilterName;
  tab?: Tab;
}

export const trackFilterUsageAnalyticsEvent = ({ action, value, ...optionalProps }: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.FilterUsage,
    properties: { action, value, ...optionalProps }
  });
};

export const trackDropdownFilterUsageAnalyticsEvent = (
  actionMeta: ActionMeta<any>,
  filterName: FilterName,
  tab?: CareManagementTabNames
) => {
  let analyticsEventAction:
    | AnalyticEventAction.Clear
    | AnalyticEventAction.Remove
    | AnalyticEventAction.Add = AnalyticEventAction.Clear;

  if (actionMeta?.action === SelectActionMetaName.Remove) {
    analyticsEventAction = AnalyticEventAction.Remove;
  }

  if (actionMeta?.action === SelectActionMetaName.Select) {
    analyticsEventAction = AnalyticEventAction.Add;
  }

  trackFilterUsageAnalyticsEvent({ action: analyticsEventAction, value: filterName, tab });
};

export const trackPreselectedFilterUsageAnalyticsEvent = (
  filters: Object,
  filtersToName: Record<any, FilterName>
) => {
  Object.entries(filters).forEach(([key, value]) => {
    if (Boolean(value)) {
      trackFilterUsageAnalyticsEvent({
        action: AnalyticEventAction.Preselected,
        value: filtersToName[key]
      });
    }
  });
};

export const trackSearchByNameOrMrnFilterUsageAnalyticsEvent = (
  value: string,
  tab?: CareManagementTabNames
) => {
  let eventAction = AnalyticEventAction.SearchedByName;

  if (isAllDigits(value)) {
    eventAction = AnalyticEventAction.SearchedByMrn;
  }

  trackFilterUsageAnalyticsEvent({
    action: !value ? AnalyticEventAction.Clear : eventAction,
    value: FilterName.SearchByNameOrMrn,
    tab
  });
};
