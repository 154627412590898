import { FC } from 'react';

import { AnalyticEventAction } from 'analytics';

import {
  trackSectionUsageAnalyticsEvent,
  trackWqPaginationSectionUsageAnalyticsEvent
} from 'analytics/events/section-usage';

import { pluralize } from 'utils/StringUtils';

import { useWqSection } from 'hooks/useWqSection';

import { SECTION_HEADER_OFFSET, sectionToNameMap } from 'views/WorkQueue/WorkQueue.constants';
import { StyledPaginatorAndRefreshButtonContainer } from 'views/WorkQueue/WorkQueue.styled';

import { WorkQueueSectionName } from 'views/WorkQueue/WorkQueue.types';

import Icon from 'components/Icons/Icon';
import { Paginator, PaginatorAction } from 'components/Paginator';
import { OutlinedIconSubtextButton } from 'components/UIkit/atoms/Button';

interface Props {
  sectionName: WorkQueueSectionName;
  isBottom?: boolean;
}

export const WqSectionControl: FC<Props> = ({ sectionName, isBottom = false }) => {
  const {
    totalPages,
    currentPage,
    onPageChange,
    isRefreshButtonVisible,
    onRefreshButtonClick,
    newItemsCount,
    testHookPrefix
  } = useWqSection(sectionName);

  const handleSectionPageChange = (
    currentPage: number,
    action: PaginatorAction,
    previousPage: number
  ) => {
    if (isBottom) {
      scrollToSectionTop();
    }

    onPageChange(currentPage, action, previousPage);
    trackWqPaginationSectionUsageAnalyticsEvent(
      sectionToNameMap[sectionName],
      action,
      previousPage
    );
  };

  const handleRefreshButtonClick = () => {
    if (isBottom) {
      scrollToSectionTop();
    }

    onRefreshButtonClick();
    trackSectionUsageAnalyticsEvent({
      action: AnalyticEventAction.RefreshUpdates,
      value: sectionToNameMap[sectionName],
      page_number: currentPage
    });
  };

  const scrollToSectionTop = () => {
    const sectionElement = document.getElementById(sectionToNameMap[sectionName]);

    if (sectionElement) {
      window.scrollTo(0, sectionElement.offsetTop + SECTION_HEADER_OFFSET);
    }
  };

  return (
    <StyledPaginatorAndRefreshButtonContainer>
      {isRefreshButtonVisible && (
        <OutlinedIconSubtextButton
          subText={`${newItemsCount} ${pluralize('Update', newItemsCount)}`}
          icon={<Icon.Refresh />}
          onClick={handleRefreshButtonClick}
          mr={20}
        >
          Refresh
        </OutlinedIconSubtextButton>
      )}

      <Paginator
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handleSectionPageChange}
        testHookPrefix={`${testHookPrefix}-${isBottom ? 'bottom' : 'top'}`}
      />
    </StyledPaginatorAndRefreshButtonContainer>
  );
};
