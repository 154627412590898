import { FC } from 'react';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { FEATURES } from 'constants/features';

import { PageFiltersProps } from 'views/Filters/filters.types';
import { TicketFilters } from 'views/Filters/TicketFilters';

import { useWqFilters } from 'views/Filters/useWqFilters';

const WorkQueueTicketFiltersBase: FC<PageFiltersProps> = ({ className }) => {
  const { settingsStore } = useStores();
  const { filters, updateFiltersByKey } = useWqFilters();

  return (
    <TicketFilters
      filters={filters}
      updateFiltersByKey={updateFiltersByKey}
      className={className}
      showTaskFilters={settingsStore.hasFeature(FEATURES.SEARCH_BY_TASK_FILTER)}
    />
  );
};

export const WorkQueueTicketFilters = observer(WorkQueueTicketFiltersBase);
