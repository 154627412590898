import { WorkQueueSectionName } from 'views/WorkQueue/WorkQueue.types';

export const workQueueTestSelectors = {
  Page: 'work-queue-page',
  RefreshDataButton: 'wq-button',
  SectionTrigger: (sectionName: WorkQueueSectionName) => `${sectionName}-trigger`,
  SectionTopPaginatorNextButton: (sectionName: WorkQueueSectionName) =>
    `${sectionName}-paginator-top-next-button`,
  SectionTopPaginatorLastButton: (sectionName: WorkQueueSectionName) =>
    `${sectionName}-paginator-top-last-button`,
  SectionTopPaginatorPreviousButton: (sectionName: WorkQueueSectionName) =>
    `${sectionName}-paginator-top-previous-button`,
  SectionTopPaginatorFirstButton: (sectionName: WorkQueueSectionName) =>
    `${sectionName}-paginator-top-first-button`,
  RequestAllOverdueReportsButton: 'request-all-overdue-reports-button',
  RequestAllOverdueReportsPopup: 'request-all-overdue-reports-popup',
  RequestAllOverdueReportsPopupSubmitButton: 'popup-submit-btn-request-all-overdue-reports-popup',
  ResolveAllPatientReportsButton: 'resolve-all-patient-reports-button',
  ResolveAllPatientReportsPopup: 'resolve-all-patient-reports-popup',
  ResolveAllPatientReportsPopupSubmitButton: 'popup-submit-btn-resolve-all-patient-reports-popup'
};
