import { FC } from 'react';

import {
  CareManagementMultiAutocompleteProps,
  MultiAutocomplete
} from 'components/UIkit/atoms/Dropdown/Select/Autocomplete/Autocomplete';
import { CmFilterClearIndicator } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/CmFilterMultiAutocomplete/CmFilterClearIndicator';
import { CmFilterMultiValueRemove } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/CmFilterMultiAutocomplete/CmFilterMultiValueRemove';
import { CmFilterOption } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/CmFilterMultiAutocomplete/CmFilterOption';
import { SelectComponents } from 'components/UIkit/atoms/Dropdown/Select.shared';

export const CmFilterMultiAutocomplete: FC<CareManagementMultiAutocompleteProps> = (props) => {
  let components: SelectComponents = {};

  if (props.selectedTableRowsCount > 0) {
    components = {
      MultiValueRemove: (filterMultiValueRemoveProps) => (
        <CmFilterMultiValueRemove
          {...filterMultiValueRemoveProps}
          selectedTableRowsCount={props.selectedTableRowsCount}
        />
      ),
      ClearIndicator: (clearIndicatorProps) => (
        <CmFilterClearIndicator
          {...clearIndicatorProps}
          selectedTableRowsCount={props.selectedTableRowsCount}
        />
      ),
      Option: (filterOptionProps) => (
        <CmFilterOption
          {...filterOptionProps}
          selectedTableRowsCount={props.selectedTableRowsCount}
        />
      )
    };
  }

  return <MultiAutocomplete components={components} {...props} />;
};
