import { FC, useMemo } from 'react';

import {
  trackDropdownInputUsageAnalyticsEvent,
  trackMultiSelectionInputUsageAnalyticsEvent,
  trackOpenTextInputUsageAnalyticsEvent
} from 'analytics/events/input-usage';

import { findIndex, isArray } from 'lodash/fp';

import { observer } from 'mobx-react';

import { useFormContext } from 'react-hook-form';

import { ActionMeta } from 'react-select';

import { createTicketsTestSelectors } from 'tests/models/pages/create-ticket/create-tickets-page.selectors';

import { useStores } from 'mobx/hooks/useStores';

import { TicketSubTypeOption } from 'utils/TicketType.utils';

import { Testable } from 'utils/TypeUtils';

import { FEATURES } from 'constants/features';

import { OperatorTicketSource } from 'models/OperatorTicket';

import Patient from 'models/Patient';

import { TicketTypeKind } from 'models/TicketTypes';

import StyledFieldsForm from 'views/Widgets/StyledFieldsForm';

import { LightweightPathways } from 'components/LightweightPathway/LightweightPathways';
import { useLightweightPathways } from 'components/LightweightPathway/useLightweightPathways';
import FixedLoader from 'components/Loaders/FixedLoader';

import { isSymptomTicket } from 'components/Ticket/TicketForms/OperatorEditTicketInfoForm';
import { TicketFormField } from 'components/Ticket/TicketForms/ticket.shared';
import {
  defaultNonSymptomUrgencyValue,
  defaultSymptomUrgencyValue,
  getUrgencyDefaultValue,
  getUrgencyOptions,
  useTicketTypesOptions
} from 'components/Ticket/TicketForms/TicketsInfoFormFragments/OperatorTicketsInfoFormCommon';

import PatientLocationFormField from 'components/Ticket/TicketForms/TicketsInfoFormFragments/OperatorTicketsInfoFormLocation';
import PatientProviderFormField from 'components/Ticket/TicketForms/TicketsInfoFormFragments/OperatorTicketsInfoFormProvider';
import ClinicianSelectionFormField from 'components/Ticket/TicketForms/TicketsInfoFormFragments/TicketsInfoFormAssignee';
import {
  FormAutocompleteInlineLabel,
  FormTicketTypeAutocomplete,
  FormTicketTypeMultiAutocomplete
} from 'components/UIkit/atoms/Dropdown';
import { ISelectOption } from 'components/UIkit/atoms/Dropdown/Select.shared';
import { SelectActionMetaName } from 'components/UIkit/atoms/Dropdown/SelectUtils';
import { FormRichText } from 'components/UIkit/atoms/RichText/FormRichText';
import { getDefaultToolbarListeners } from 'components/UIkit/atoms/RichText/Plugins/ToolbarPlugin/ToolbarPlugin.utils';

interface IProps extends Testable {
  fieldName: string;
  patient: Patient;
  errors: any;
  ticketSource?: OperatorTicketSource;
  categoryId: string;
  ticketSubTypeIds: string[];
  onDropdownChange?: (
    actionMeta: ActionMeta<any>,
    name: 'Provider' | 'Location' | 'Assignee' | 'Urgency',
    isKeyboardSource: boolean,
    hasPreviousValue?: boolean
  ) => void;
  removeDynamicFormTickets: (index?: number | number[]) => void;
  updateDynamicFormTickets: (index: number, newValue: Record<string, any>) => void;
}

const OperatorCreateTicketInfoFormItem: FC<IProps> = ({
  fieldName,
  patient,
  errors,
  ticketSource,
  testHook,
  onDropdownChange = () => null,
  removeDynamicFormTickets,
  updateDynamicFormTickets
}) => {
  const { ticketTypesStore, settingsStore } = useStores();
  const { watch, setValue } = useFormContext();

  const isLightweightPathwaysFeatureEnabled = settingsStore.hasFeature(
    FEATURES.LIGHTWEIGHT_PATHWAYS
  );

  const { lightweightPathways, handleAnswerChanged, isLoading, handleTextQuestionBlur } =
    useLightweightPathways(isLightweightPathwaysFeatureEnabled, 'create ticket', {
      symptomsDropdown: `${fieldName}.ticketTypeSelectorValue`,
      answers: `${fieldName}.lightweightPathwaysAnswers`,
      urgency: `${fieldName}.urgency`,
      pathwaySummary: `${fieldName}.lightweightPathwaySummaries`
    });

  const isLightweightPathwaysQuestionsVisible =
    isLightweightPathwaysFeatureEnabled && lightweightPathways.length > 0;

  const [
    locationId,
    providerId,
    currentTicketTypeSelectorValue,
    currentTicketTypes,
    currentTickets
  ] = watch([
    `${fieldName}.locationId`,
    `${fieldName}.providerId`,
    `${fieldName}.ticketTypeSelectorValue`,
    'ticketTypes',
    'tickets'
  ]);

  const isSymptomsMultiSelect =
    isArray(currentTicketTypeSelectorValue) ||
    currentTicketTypeSelectorValue?.parentKind === TicketTypeKind.symptom;

  const currentSelectedTicketTypesIds =
    currentTicketTypes?.map((ticketType: any) => ticketType.value) || [];

  const ticketTypeOptions = useTicketTypesOptions({
    filterFn: (node) =>
      node.isActive &&
      node.reportableInAll &&
      !node.isFever &&
      !currentSelectedTicketTypesIds.includes(node.id.toString()),
    showFullNameForNonSymptomTicketTypes: true
  });

  const groupedTicketTypesOptions = useMemo(() => {
    if (isSymptomsMultiSelect) {
      return ticketTypeOptions.filter((t) => t.kind === TicketTypeKind.symptom);
    }

    if (
      currentTickets.find((ticket: TicketFormField) => isSymptomTicket(ticket, ticketTypesStore))
    ) {
      return ticketTypeOptions.filter((t) => t.kind !== TicketTypeKind.symptom);
    }

    return ticketTypeOptions;
  }, [ticketTypeOptions, isSymptomsMultiSelect, ticketTypesStore, currentTickets]);

  const FormTicketTypeSelector = isSymptomsMultiSelect
    ? FormTicketTypeMultiAutocomplete
    : FormTicketTypeAutocomplete;

  const formTicketTypeSelectorAdditionalProps: {
    noBorderRadiusBottom?: boolean;
    warnOnRemove?: boolean;
  } = {};

  if (isSymptomsMultiSelect) {
    formTicketTypeSelectorAdditionalProps.noBorderRadiusBottom = true;
    formTicketTypeSelectorAdditionalProps.warnOnRemove = true;
  }

  const fields = [
    <PatientProviderFormField
      key={`${fieldName}.providerId`}
      fieldName={`${fieldName}.providerId`}
      isRequired
      isInline
      patient={patient}
      onChange={(_, actionMeta, eventKey) => {
        onDropdownChange(actionMeta, 'Provider', eventKey === 'Enter', Boolean(providerId));
      }}
    />,

    <PatientLocationFormField
      key={`${fieldName}.locationId`}
      fieldName={`${fieldName}.locationId`}
      isRequired
      isInline
      patient={patient}
      onChange={(_, actionMeta, eventKey) =>
        onDropdownChange(actionMeta, 'Location', eventKey === 'Enter', Boolean(locationId))
      }
    />,

    <ClinicianSelectionFormField
      key={`${fieldName}.assignee`}
      fieldName={`${fieldName}.assignee`}
      isInline
      placeholder="Unassigned"
      label="Assignee"
      withClearableOption
      onChange={(_, actionMeta, eventKey) =>
        onDropdownChange(actionMeta, 'Assignee', eventKey === 'Enter')
      }
    />,

    <FormAutocompleteInlineLabel
      label="Urgency"
      key={`${fieldName}.urgency`}
      name={`${fieldName}.urgency`}
      options={getUrgencyOptions(isSymptomsMultiSelect)}
      sortAlphabetically={false}
      defaultValue={getUrgencyDefaultValue(isSymptomsMultiSelect)}
      isClearable={false}
      onChange={(_, actionMeta, eventKey) =>
        onDropdownChange(actionMeta, 'Urgency', eventKey === 'Enter')
      }
    />
  ];

  const handleSymptomTicketTypeSelect = (selectedOption: TicketSubTypeOption) => {
    let previousValue = currentTicketTypeSelectorValue;

    if (
      Boolean(previousValue) &&
      'parentKind' in previousValue &&
      previousValue.parentKind !== TicketTypeKind.symptom
    ) {
      const newTicketTypes = [...currentTicketTypes];

      const currentTicketType = newTicketTypes.find(
        (ticketType) => ticketType.value === previousValue.value
      );

      currentTicketType.label = selectedOption.label;
      currentTicketType.labelFull = selectedOption.labelFull;
      currentTicketType.parentId = selectedOption.parentId;
      currentTicketType.parentName = selectedOption.parentName;
      currentTicketType.parentKind = selectedOption.parentKind;
      currentTicketType.value = selectedOption.value;

      setValue(`ticketTypes`, newTicketTypes);

      const symptomsTicketIndex = currentTickets.findIndex(
        (ticket: TicketFormField) =>
          isArray(ticket.ticketTypeSelectorValue) ||
          ticket.ticketTypeSelectorValue.parentKind === TicketTypeKind.symptom
      );

      const categoryNode = ticketTypesStore.getCategoryByParentId(
        isArray(selectedOption) ? selectedOption[0].parentId : selectedOption.parentId
      );
      setValue(`tickets[${symptomsTicketIndex}].parentId`, selectedOption.parentId);
      setValue(`tickets[${symptomsTicketIndex}].categoryId`, String(categoryNode.id));
      setValue(`tickets[${symptomsTicketIndex}].ticketSubTypeIds`, [selectedOption.value]);
      setValue(`tickets[${symptomsTicketIndex}].urgency`, defaultSymptomUrgencyValue);
    } else {
      // if selectedOption is an object -> there is one symptom
      // if selectedOption is an array -> there are more than one symptom, in this case we want to contact the last symptom added
      setValue('ticketTypes', [
        ...currentTicketTypes,
        isArray(selectedOption) ? selectedOption[selectedOption.length - 1] : selectedOption
      ]);

      const symptomsTicket = currentTickets.find(
        (ticket: TicketFormField) =>
          isArray(ticket.ticketTypeSelectorValue) ||
          ticket.ticketTypeSelectorValue.parentKind === TicketTypeKind.symptom
      );

      const symptomsTicketIndex = currentTickets.findIndex(
        (ticket: TicketFormField) =>
          isArray(ticket.ticketTypeSelectorValue) ||
          ticket.ticketTypeSelectorValue.parentKind === TicketTypeKind.symptom
      );

      const categoryNode = ticketTypesStore.getCategoryByParentId(
        isArray(selectedOption) ? selectedOption[0].parentId : selectedOption.parentId
      );

      setValue(
        `tickets[${symptomsTicketIndex}].parentId`,
        isArray(selectedOption) ? selectedOption[0].parentId : selectedOption.parentId
      );

      setValue(`tickets[${symptomsTicketIndex}].categoryId`, String(categoryNode.id));

      setValue(
        `tickets[${symptomsTicketIndex}].ticketSubTypeIds`,
        isArray(selectedOption)
          ? [...symptomsTicket.ticketSubTypeIds, selectedOption[selectedOption.length - 1].value]
          : [selectedOption.value]
      );
    }
  };

  const handleNonSymptomTicketTypeSelect = (selectedOption: TicketSubTypeOption) => {
    const newTicketTypes = [...currentTicketTypes];
    const ticketTypeInMainSelect = newTicketTypes.find(
      (ticketType: TicketSubTypeOption) => ticketType.value === currentTicketTypeSelectorValue.value
    );

    ticketTypeInMainSelect.label = selectedOption.label;
    ticketTypeInMainSelect.labelFull = selectedOption.labelFull;
    ticketTypeInMainSelect.parentId = selectedOption.parentId;
    ticketTypeInMainSelect.parentName = selectedOption.parentName;
    ticketTypeInMainSelect.parentKind = selectedOption.parentKind;
    ticketTypeInMainSelect.value = selectedOption.value;

    const ticketIndex = currentTickets.findIndex(
      (ticket: TicketFormField) =>
        (ticket.ticketTypeSelectorValue as TicketSubTypeOption).value === selectedOption.value
    );

    const categoryNode = ticketTypesStore.getCategoryByParentId(selectedOption.parentId);

    setValue('ticketTypes', newTicketTypes);
    setValue(`tickets[${ticketIndex}].parentId`, selectedOption.parentId);
    setValue(`tickets[${ticketIndex}].ticketSubTypeIds`, [selectedOption.value]);
    setValue(`tickets[${ticketIndex}].categoryId`, String(categoryNode.id));
  };

  const handleTicketTypeSelect = (selectedOption: TicketSubTypeOption) => {
    //the first ticket type
    if (!currentTicketTypes || currentTicketTypes.length === 0) {
      const categoryNode = ticketTypesStore.getCategoryByParentId(selectedOption.parentId);

      setValue('ticketTypes', [selectedOption]);
      setValue('tickets[0].parentId', selectedOption.parentId);
      setValue('tickets[0].ticketSubTypeIds', [selectedOption.value]);
      setValue('tickets[0].categoryId', String(categoryNode.id));
      setValue(
        'tickets[0].urgency',
        getUrgencyDefaultValue(selectedOption.parentKind === TicketTypeKind.symptom)
      );
      return;
    }

    if (selectedOption.parentKind === TicketTypeKind.symptom || isArray(selectedOption)) {
      handleSymptomTicketTypeSelect(selectedOption);
      return;
    }

    handleNonSymptomTicketTypeSelect(selectedOption);
  };

  const handleTicketTypeRemove = (
    selectedOption: TicketSubTypeOption,
    removedValue: TicketSubTypeOption
  ) => {
    const ticketTypeInMainSelect = currentTicketTypes.find(
      (ticketType: TicketSubTypeOption) => ticketType.value === removedValue.value
    );

    //we should update the main ticket types dropdown
    setValue(
      'ticketTypes',
      currentTicketTypes.filter(
        (ticketType: TicketSubTypeOption) => ticketType.value !== ticketTypeInMainSelect.value
      )
    );

    if (selectedOption) {
      //selectedOption is existed means that we still have symptom/s selected
      const existingTicketFormFieldIndex = findIndex<TicketFormField>(
        (item) => item.parentId === removedValue.parentId,
        currentTickets
      );

      const symptomsTicket = currentTickets[existingTicketFormFieldIndex];

      //we find this ticket and update the ticket sub type ids field
      setValue(fieldName, {
        ...symptomsTicket,
        ticketSubTypeIds: symptomsTicket.ticketSubTypeIds.filter(
          (id: string) => id !== removedValue.value
        )
      });
    }

    const symptomsTicketIndex = currentTickets.findIndex(
      (ticket: TicketFormField) => !ticket.ticketTypeSelectorValue
    );

    const isLastTicket = currentTicketTypes.length === 1;

    if (isLastTicket) {
      //if is last ticket we want to update the first array item to be empty
      updateDynamicFormTickets(0, {
        ...currentTickets[0],
        urgency: defaultNonSymptomUrgencyValue,
        notes: '',
        assignee: null,
        categoryId: '',
        ticketSubTypeIds: [],
        parentId: null,
        ticketTypeSelectorValue: null
      });
      return;
    }

    const shouldRemoveTicket = 'parentKind' in currentTicketTypeSelectorValue || !selectedOption;

    if (shouldRemoveTicket) {
      //remove ticket - not the last one
      removeDynamicFormTickets(symptomsTicketIndex);
    }
  };

  const onTicketTypeChange = (
    selectedOption: TicketSubTypeOption,
    actionMeta: ActionMeta<ISelectOption<any>>,
    eventKey: string | null
  ) => {
    if (actionMeta.action === SelectActionMetaName.Select) {
      trackDropdownInputUsageAnalyticsEvent(
        actionMeta,
        'Ticket Types Sub Dropdown',
        eventKey === 'Enter',
        Boolean(currentTicketTypeSelectorValue)
      );

      handleTicketTypeSelect(selectedOption);
    }

    if (actionMeta.action === SelectActionMetaName.Remove) {
      trackMultiSelectionInputUsageAnalyticsEvent(
        actionMeta,
        'Ticket Types Sub Dropdown',
        eventKey === 'Enter'
      );

      // @ts-ignore
      handleTicketTypeRemove(selectedOption, actionMeta.removedValue);
    }
  };

  return (
    <>
      {isLoading && <FixedLoader />}

      <StyledFieldsForm
        testHook={testHook}
        mainField={
          <>
            <FormTicketTypeSelector
              isRequired
              options={groupedTicketTypesOptions}
              name={`${fieldName}.ticketTypeSelectorValue`}
              placeholder="Select Ticket Type"
              // @ts-ignore
              onChange={onTicketTypeChange}
              isSub
              {...formTicketTypeSelectorAdditionalProps}
            />

            <FormRichText
              name={`${fieldName}.notes`}
              placeholder="Add notes here..."
              disabled={ticketSource === OperatorTicketSource.Patient}
              size="medium"
              onBlur={(_, currentValue, valueAfterFocus) => {
                trackOpenTextInputUsageAnalyticsEvent(
                  currentValue,
                  valueAfterFocus,
                  'ticket notes'
                );
              }}
              isPartialBorder
              toolbarListeners={getDefaultToolbarListeners(patient.id)}
              testHook={`${testHook}_ticketNotes`}
            />

            {isLightweightPathwaysQuestionsVisible && (
              <LightweightPathways
                lightweightPathways={lightweightPathways}
                answers={watch(`${fieldName}.lightweightPathwaysAnswers`)}
                handleAnswerChanged={handleAnswerChanged}
                handleTextQuestionBlur={handleTextQuestionBlur}
                testHook={createTicketsTestSelectors.lightweightPathwaysContainer}
              />
            )}
          </>
        }
        extraFields={fields}
      />
    </>
  );
};

export default observer(OperatorCreateTicketInfoFormItem);
