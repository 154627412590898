import { FC } from 'react';

import Box from '@mui/material/Box';
import classNames from 'classnames';

import { useHistory } from 'react-router-dom';

import { useStores } from 'mobx/hooks/useStores';

import { FEATURES } from 'constants/features';

import Patient from 'models/Patient';

import { usePatientEmrLink } from 'views/Patient/PatientDetails/usePatientEmrLink';

import Icon from 'components/Icons/Icon';
import { OutlinedIconButton } from 'components/UIkit/atoms/Button';
import { ExternalLinkWithIcon } from 'components/UIkit/atoms/Link/ExternalLinkWithIcon';

import { PatientDetailsCardActions } from './PatientDetailsCardActions';
import { PatientDetailsCardCareTimer } from './PatientDetailsCardCareTimer';
import { PatientDetailsCardEdit } from './PatientDetailsCardEdit';
import { PatientDetailsCardRefresh } from './PatientDetailsCardRefresh';
import PatientInfoBar from './PatientInfoBar';

import './PatientDetailsCard.scss';

interface Props {
  patient: Patient;
  isEditable?: boolean;
  disableCallLogging?: boolean;
  withRefreshButton?: boolean;
  selectedTab?: string;
}

export const PatientDetailsCard: FC<Props> = ({
  isEditable,
  patient,
  withRefreshButton,
  selectedTab
}) => {
  const history = useHistory();
  const { settingsStore } = useStores();
  const patientEmrLink = usePatientEmrLink(patient);
  const hasEmrSummaryLinkFeature = settingsStore.hasFeature(FEATURES.EMR_LINK_PATIENT_SUMMARY);
  const showExternalLink = patientEmrLink && hasEmrSummaryLinkFeature;

  const handleBackClicked = () => {
    if (history.length <= 2) {
      history.push('/patient-list');
    } else {
      history.goBack();
    }
  };
  const patientsProfileImageClasses = classNames('patient-profile-img', {
    female: patient.isFemale,
    male: !patient.isFemale
  });

  return (
    <div className="patient-details-card">
      <div className="details-container">
        <div className="top-btns-container">
          {isEditable && (
            <OutlinedIconButton
              onClick={handleBackClicked}
              icon={<Icon.Chevron left />}
              testHook="patient-details-back"
              mr="auto"
            >
              Back
            </OutlinedIconButton>
          )}
          {isEditable && <PatientDetailsCardCareTimer patient={patient} />}
          {withRefreshButton && (
            <PatientDetailsCardRefresh patient={patient} selectedTab={selectedTab} />
          )}
        </div>
        <div className="profile-column">
          <i className={patientsProfileImageClasses} />
          {isEditable && <PatientDetailsCardEdit patient={patient} selectedTab={selectedTab} />}
        </div>
        <PatientInfoBar patient={patient} />
        {isEditable && (
          <Box display="flex" alignItems="center">
            {showExternalLink && (
              <ExternalLinkWithIcon icon={<Icon.ExternalLink />} href={patientEmrLink} mr={20}>
                OncoEMR
              </ExternalLinkWithIcon>
            )}
            <PatientDetailsCardActions patient={patient} />
          </Box>
        )}
      </div>
    </div>
  );
};
