import * as React from 'react';
import { MouseEvent, FC } from 'react';

import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

import { Testable } from 'utils/TypeUtils';

type Option = {
  value: string | number;
  label: string;
  disabled?: boolean;
} & Testable;

export interface MultiToggleProps {
  options: Option[];
  value: string[] | number[];
  onChange: (newFormats: string[] | number[]) => void;
  fullWidth?: boolean;
  disabled?: boolean;
  isError?: boolean;
}

export const MultiToggleButton: FC<MultiToggleProps> = ({
  options,
  value: selectedValues,
  onChange,
  fullWidth = false,
  disabled = false,
  isError = false
}) => {
  const handleChange = (event: MouseEvent<HTMLElement>, newFormats: string[] | number[]) => {
    onChange(newFormats);
  };

  return (
    <ToggleButtonGroup
      value={selectedValues}
      onChange={handleChange}
      color={isError ? 'error' : 'primary'}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {options.map(({ value: optionValue, label, disabled = false, testHook }) => (
        <ToggleButton
          key={optionValue}
          value={optionValue}
          selected={selectedValues.includes(optionValue as never)}
          disabled={disabled}
          data-test-hook={testHook}
        >
          <Typography variant="form-text">{label}</Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
