import {
  TEST_PATHWAY_ID,
  TEST_PATHWAY_OPTION_ID_IN_MULTIPLE_QUESTION
} from 'tests/models/pages/patient-page/patient-page.constants';
import { PatientPageTabs } from 'tests/models/pages/patient-page/patient-page.types';

export const patientPageTestSelectors = {
  tab: (tab: PatientPageTabs) => `${tab}-tab`,
  actionsButton: 'patient-actions-button',
  editPatientButton: 'edit-patient-button',
  actions: {
    logCallOption: 'log-call-option',
    noPathwayOption: 'no-pathway-option',
    pathwayOption: `${TEST_PATHWAY_ID}-option`
  },
  pathwayView: {
    pathwayAnswerInMultipleQuestion: `${TEST_PATHWAY_OPTION_ID_IN_MULTIPLE_QUESTION}_label`
  },
  callLogger: {
    submitButton: 'call-logger-submit-button',
    regenerateSmartSummaryButton: 'regenerate-smart-summary-button'
  }
};
