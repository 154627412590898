// @ts-strict-ignore
import { AnalyticEventAction } from 'analytics';
import { trackActionButtonAnalyticsEvent } from 'analytics/events/action-button';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';

import { useDoctorsAndAssignees } from 'hooks/useDoctor';
import { useTicketAssign } from 'hooks/useTicketAssign';

import TicketReassignAction from 'components/Ticket/TicketRow/TicketReassignAction';
import { TicketActionsProps } from 'components/Ticket/TicketsContainers/TicketOverviewProvider';

import { OutlinedButton } from 'components/UIkit/atoms/Button';

import TicketActionsMenu from './TicketActionsMenu';

const TicketActions = ({ ticket, ticketIndex, ticketSectionCurrentPage }: TicketActionsProps) => {
  const { tasksStore } = useStores();
  const assignTicket = useTicketAssign();
  const { mainAssign, isCurrentDoctorAssigned, currentDoctor, ticketDoctor, handleDoctorSelected } =
    useDoctorsAndAssignees(ticket);
  const { id: doctorId } = currentDoctor;

  const handleReopen = () => {
    trackActionButtonAnalyticsEvent({
      action: AnalyticEventAction.Reopen,
      patient_id: ticket.patientId,
      ticket_id: ticket.id,
      item_index: ticketIndex + 1,
      page_number: ticketSectionCurrentPage + 1
    });
    tasksStore.reopenTask(ticket);
  };

  const handlePickUp = async () => {
    trackActionButtonAnalyticsEvent({
      action: AnalyticEventAction.PickUp,
      patient_id: ticket.patientId,
      ticket_id: ticket.id,
      item_index: ticketIndex + 1,
      page_number: ticketSectionCurrentPage + 1
    });
    await assignTicket(ticket, doctorId);
  };

  if (ticket.isClosedOrResolved) {
    return ticket.isTask && <OutlinedButton onClick={handleReopen}>Reopen</OutlinedButton>;
  }

  if (!mainAssign) {
    if (ticket.isTask) {
      return (
        <TicketActionsMenu
          ticket={ticket}
          ticketIndex={ticketIndex}
          ticketSectionCurrentPage={ticketSectionCurrentPage}
        />
      );
    } else {
      return <OutlinedButton onClick={handlePickUp}>Pick Up</OutlinedButton>;
    }
  }

  return (
    <>
      {isCurrentDoctorAssigned ? (
        <TicketActionsMenu
          ticket={ticket}
          ticketIndex={ticketIndex}
          ticketSectionCurrentPage={ticketSectionCurrentPage}
        />
      ) : (
        <TicketReassignAction
          mainAssign={mainAssign}
          ticketDoctor={ticketDoctor}
          handleDoctorSelected={handleDoctorSelected}
          ticketId={ticket.id}
          testHook={`ticketReassignAction_${ticket.id}`}
          ticketIndex={ticketIndex}
          ticketSectionCurrentPage={ticketSectionCurrentPage}
        />
      )}
    </>
  );
};

export default observer(TicketActions);
