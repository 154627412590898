import { isNumber } from 'lodash/fp';
import { computed, makeObservable, observable, runInAction } from 'mobx';

import ClinicianAlertsFetcher from 'fetchers/ClinicianAlertsFetcher';

import ClinicianAlert, { ConditionType, PopulationType } from 'models/ClinicianAlert';
import ClinicianAlertCondition from 'models/Conditions/ClinicianAlertCondition';
import SpecificCauseCondition, { DxCode } from 'models/Conditions/SpecificCauseCondition';
import SeverityReportedCause, { ReportedCause } from 'models/ReportedCause';
import { ProtocolName } from 'models/ScheduledProtocol';

export default class AlertsStore {
  @observable
  alerts: ClinicianAlert[] = [];

  constructor() {
    makeObservable(this);
  }

  @computed
  get globalAlerts() {
    return this.alerts.filter(
      (alert: ClinicianAlert) =>
        // TODO: should be compared to the actual ReportType enum but server returns in Capital
        alert.reportType !== ProtocolName.oralOncolytics &&
        !this.isDxAlert(alert) &&
        !this.isSpecificDrug(alert)
    );
  }

  @computed
  get diagnosisAlerts() {
    return this.alerts.filter(
      (alert) => alert.population.type === PopulationType.PatientsWithDiagnosis
    );
  }

  @computed
  get specificDrugAlerts() {
    return this.alerts.filter((alert) => this.isSpecificDrug(alert));
  }

  isDxAlert = (alert: ClinicianAlert) =>
    alert.population.type === PopulationType.PatientsWithDiagnosis;

  isSpecificDrug = (alert: ClinicianAlert) =>
    alert.population.type === PopulationType.PatientsWithSpecificDrug;

  getClinicianAlerts = async () => {
    const result = await ClinicianAlertsFetcher.getAlerts();
    runInAction(() => {
      if (JSON.stringify(this.alerts) !== JSON.stringify(result)) {
        this.alerts = result;
      }
    });
  };

  removeClinicianAlert = async (id: number) => {
    await ClinicianAlertsFetcher.removeAlert(id);
    await this.getClinicianAlerts();
  };

  submitNewClinicianAlert = async (clinicianAlert: ClinicianAlert) => {
    await ClinicianAlertsFetcher.createAlert(clinicianAlert);
    await this.getClinicianAlerts();
  };

  updateClinicianAlert = async (clinicianAlert: ClinicianAlert) => {
    await ClinicianAlertsFetcher.updateAlert(clinicianAlert);
    await this.getClinicianAlerts();
  };

  isConditionMatchForSymptom = (
    condition: ClinicianAlertCondition,
    symptom: ReportedCause | number
  ) => {
    //Used by symptom operator ticket which doesn't have severity, so we send just the symptom id
    if (isNumber(symptom)) {
      return condition.getId() === symptom;
    }

    return (
      condition.getId() === symptom.causeId &&
      (symptom as SeverityReportedCause).severity >= condition.getBottomThreshold()
    );
  };

  getMatchedDxAlertsConditionsForSymptom = (
    symptom: ReportedCause | number
  ): SpecificCauseCondition[] => {
    const conditions: SpecificCauseCondition[] = [];

    this.diagnosisAlerts.forEach((alert) => {
      alert.conditions.forEach((condition) => {
        if (
          // safety check for data even though dx alerts should only have specific cause conditions
          condition.type === ConditionType.SpecificCause &&
          this.isConditionMatchForSymptom(condition, symptom)
        ) {
          conditions.push(condition as SpecificCauseCondition);
        }
      });
    });

    return conditions;
  };

  getDxAlertConditionsForSymptom = (symptom: ReportedCause | number, patientDxCodes: string[]) => {
    const conditions: SpecificCauseCondition[] =
      this.getMatchedDxAlertsConditionsForSymptom(symptom);

    if (!conditions || conditions?.length === 0) {
      return [];
    }

    return conditions.filter((condition) => condition.hasMatchingDx(patientDxCodes));
  };

  getDxAlertCodesForSymptom = (
    symptom: SeverityReportedCause | number,
    patientDxCodes: string[]
  ) => {
    const conditions = this.getDxAlertConditionsForSymptom(symptom, patientDxCodes);
    const codes: DxCode[] = [];

    conditions.forEach((condition) => {
      const matchingDxCodes = condition.matchingDxCodes(patientDxCodes);
      codes.push(...matchingDxCodes);
    });

    return codes;
  };

  getDxAlertDescriptionsForSymptom = (
    symptom: ReportedCause | number,
    patientDxCodes: string[]
  ) => {
    const conditions = this.getDxAlertConditionsForSymptom(symptom, patientDxCodes);
    const dxAlertsDescriptions: string[] = [];

    conditions.forEach((condition) => {
      const matchingDxDescriptions = condition.matchingDxCodeDescriptions(patientDxCodes);
      dxAlertsDescriptions.push(...matchingDxDescriptions);
    });

    return dxAlertsDescriptions;
  };
}
