"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublicUrlsService = void 0;
const public_urls_types_1 = require("./public-urls.types");
function createPublicUrls(publicUrlsInput, isDev) {
    Object.entries(publicUrlsInput).forEach(([key, url]) => {
        if (!url) {
            throw new Error(`Missing public url (key: ${key})`);
        }
        if (!validatePublicUrl(url, isDev)) {
            throw new Error(`Invalid public url for ${key}: ${url} (should contain "localhost" for development, or end with "canopycare.us" otherwise)`);
        }
    });
    return publicUrlsInput;
}
function validatePublicUrl(url, isDev) {
    if (isDev) {
        return url.includes('localhost');
    }
    else {
        const domainRegex = /canopycare\.us\/?$/;
        return domainRegex.test(url);
    }
}
class PublicUrlsService {
    get publicUrls() {
        return this._publicUrls;
    }
    constructor(isDev, publicUrls) {
        this._publicUrls = public_urls_types_1.developmentPublicUrls;
        const isValid = Boolean(publicUrls) || isDev;
        if (!isValid) {
            throw new Error('PublicUrlsUtils must be initialized unless in dev mode');
        }
        if (PublicUrlsService._initialized) {
            throw new Error('PublicUrlsUtils already initialized');
        }
        if (publicUrls) {
            this._publicUrls = createPublicUrls(publicUrls, isDev);
        }
        PublicUrlsService._initialized = true;
    }
    getUrlWithCurrentProtocol(url) {
        const host = this.publicUrls[url];
        return `${window.location.protocol}//${host}`;
    }
}
exports.PublicUrlsService = PublicUrlsService;
PublicUrlsService._initialized = false;
