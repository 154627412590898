import { FC, Fragment } from 'react';

import { AssignNotification } from 'components/NotificationCenter/AssignNotification';
import { CommentNotification } from 'components/NotificationCenter/CommentNotification';
import { MentionNotification } from 'components/NotificationCenter/MentionNotification';

import {
  ClinicianAssignNotification,
  ClinicianCommentNotification,
  ClinicianMentionNotification,
  ClinicianNotification,
  NotificationType
} from 'components/NotificationCenter/types';

interface Props {
  notifications: ClinicianNotification[];
  onNotificationClick: (notification: ClinicianNotification) => void;
}

export const Notifications: FC<Props> = ({ notifications, onNotificationClick }) => (
  <>
    {notifications.map((notification) => {
      const { type } = notification;

      return (
        <Fragment key={notification.id}>
          {type === NotificationType.Comment && (
            <CommentNotification
              notification={notification as ClinicianCommentNotification}
              onClick={onNotificationClick}
            />
          )}

          {type === NotificationType.Mention && (
            <MentionNotification
              notification={notification as ClinicianMentionNotification}
              onClick={onNotificationClick}
            />
          )}

          {type === NotificationType.Assign && (
            <AssignNotification
              notification={notification as ClinicianAssignNotification}
              onClick={onNotificationClick}
            />
          )}
        </Fragment>
      );
    })}
  </>
);
