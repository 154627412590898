// @ts-strict-ignore
import moment from 'moment';

import { DataMap } from 'mobx/stores';

import { isSameDay } from 'utils/DateUtils';
import { getMissedDosesReasonsText, getRegimenText } from 'utils/OralOncoUtils';

import MissedDosesReasons from 'models/MissedDosesReasons';

import { ReportQuestionAnswer, OralReport } from 'models/QuestionnaireAnswerOral';

import {
  ReportTableCellData,
  ReportTableCellDataColor,
  ReportTableIcon,
  TableReportItem,
  ReportTableSymptomSection,
  ReportTableOralChemoSection
} from './ReportTable.model';

const FIRST_PILL_UNKNOWN = 'Start of Cycle Date Unknown';

function getNewCycleTooltip(date: Date, oral: OralReport): string {
  const { regimen, isCustom } = oral;
  const regimenStr = getRegimenText(regimen, isCustom);
  const dateStr = moment(date).format('MMM Do, YYYY');
  return `New ${regimenStr} Cycle: ${dateStr}`;
}

// new cycle report (created when first pill date is not the same as report date) contain only start of cycle date
export function addNewCycleReportData(
  oralChemoSection: ReportTableOralChemoSection,
  symptomsSection: ReportTableSymptomSection,
  report: TableReportItem
) {
  oralChemoSection.NewCycle.push({
    color: ReportTableCellDataColor.gray,
    iconContent: ReportTableIcon.pill,
    tooltip: getNewCycleTooltip(report.createdAt, report.answer.oral)
  });

  oralChemoSection.MedReceived.push(null);
  oralChemoSection.RegimenAdherence.push(null);
  oralChemoSection.ChangeInOtherMeds.push(null);
  oralChemoSection.MissedDose.push(null);
  Object.keys(symptomsSection).forEach((causeName: string) => {
    symptomsSection[causeName].push(null);
  });
}

function getNewCycleOralCellData(
  reportedOnDifferentDay: boolean,
  oral?: OralReport
): ReportTableCellData {
  if (!oral) {
    return null;
  }

  const { newCycleDate } = oral;
  const unknownCycleDate = !newCycleDate && oral.firstPill?.isIDontKnow;
  let cellData: ReportTableCellData = null;

  const shouldDisplay = (!!newCycleDate || unknownCycleDate) && !reportedOnDifferentDay;
  if (shouldDisplay) {
    cellData = {
      color: unknownCycleDate ? ReportTableCellDataColor.purple : ReportTableCellDataColor.gray,
      iconContent: unknownCycleDate ? ReportTableIcon.question : ReportTableIcon.pill,
      tooltip: unknownCycleDate ? FIRST_PILL_UNKNOWN : getNewCycleTooltip(newCycleDate, oral)
    };
  }

  return cellData;
}

function getOralCellData(answer?: ReportQuestionAnswer<any>): ReportTableCellData {
  let cellData: ReportTableCellData = null;
  if (answer?.isAnswered) {
    cellData = {
      color: answer.isOk ? ReportTableCellDataColor.green : ReportTableCellDataColor.red,
      tooltip: answer.customMessage
    };
  }
  return cellData;
}

function getOralMissedDosesCellData(
  missedDoses: ReportQuestionAnswer<number>,
  missedDosesReasonsMap: DataMap<MissedDosesReasons>
): ReportTableCellData {
  const cellData: ReportTableCellData = getOralCellData(missedDoses);
  if (missedDoses?.isAnswered && missedDoses.value > 0) {
    cellData.numberContent = missedDoses.value;
    cellData.tooltip = getMissedDosesReasonsText(missedDoses, missedDosesReasonsMap);
  }
  return cellData;
}

export function addOralReportData(
  oralChemoSection: ReportTableOralChemoSection,
  report: TableReportItem,
  missedDosesReasonsMap: DataMap<MissedDosesReasons>
) {
  const { oral } = report.answer;
  const newCycleDate = oral?.newCycleDate;
  const reportedOnDifferentDay = newCycleDate && !isSameDay(newCycleDate, report.createdAt);

  let dataCell = getNewCycleOralCellData(reportedOnDifferentDay, oral);
  oralChemoSection.NewCycle.push(dataCell);

  dataCell = getOralCellData(oral?.medReceived);
  oralChemoSection.MedReceived.push(dataCell);

  dataCell = getOralCellData(oral?.regimenAsPrescribed);
  oralChemoSection.RegimenAdherence.push(dataCell);

  dataCell = getOralCellData(oral?.changesInOtherMeds);
  oralChemoSection.ChangeInOtherMeds.push(dataCell);

  dataCell = getOralMissedDosesCellData(oral?.missedDoses, missedDosesReasonsMap);
  oralChemoSection.MissedDose.push(dataCell);
}

// if at least one report has oral data - oral section will be displayed
export function displayOralSection(oralChemoSection: ReportTableOralChemoSection) {
  return (
    oralChemoSection.ChangeInOtherMeds.some((dataCell) => dataCell !== null) ||
    oralChemoSection.MedReceived.some((dataCell) => dataCell !== null) ||
    oralChemoSection.MissedDose.some((dataCell) => dataCell !== null) ||
    oralChemoSection.NewCycle.some((dataCell) => dataCell !== null) ||
    oralChemoSection.RegimenAdherence.some((dataCell) => dataCell !== null)
  );
}
