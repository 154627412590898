import { FC, useEffect, useRef, useState } from 'react';

import { fromNow } from 'utils/DateUtils';

import { Testable } from 'utils/TypeUtils';

import Icon from 'components/Icons/Icon';
import { OutlinedIconSubtextButton } from 'components/UIkit/atoms/Button';

const UPDATE_INTERVAL_DELAY = 5000;
const DEFAULT_TIME_STR = 'Unknown';

function getLastUpdatedString(timestamp: Date) {
  let timeStr = DEFAULT_TIME_STR;
  if (timestamp) {
    timeStr = fromNow(timestamp, { short: true, useSeconds: true });
  }
  return timeStr;
}

interface Props extends Testable {
  lastUpdatedTime: Date;
  isLoading: boolean;
  onClick: () => void;
}

const RefreshButton: FC<Props> = ({ lastUpdatedTime, isLoading, onClick, testHook }) => {
  const [time, setTime] = useState<string>();
  const intervalRef = useRef<NodeJS.Timeout>();

  useEffect(
    function initLastUpdateInterval() {
      const updateTime = () => {
        const initTimeStr = getLastUpdatedString(lastUpdatedTime);
        setTime(initTimeStr);
      };
      updateTime();

      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      intervalRef.current = setInterval(updateTime, UPDATE_INTERVAL_DELAY);
      return () => clearInterval(intervalRef.current);
    },
    [setTime, lastUpdatedTime]
  );

  return (
    <OutlinedIconSubtextButton
      onClick={onClick}
      icon={<Icon.Refresh />}
      testHook={testHook}
      subText={isLoading ? '' : time}
      disabled={isLoading}
    >
      {isLoading ? 'Refreshing Data…' : 'Refresh Data'}
    </OutlinedIconSubtextButton>
  );
};

export default RefreshButton;
