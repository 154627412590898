import { FC } from 'react';

import { useStores } from 'mobx/hooks/useStores';

import { AlertUrgency } from 'models/ClinicianAlert';
import Patient from 'models/Patient';
import QuestionnaireAnswer from 'models/QuestionnaireAnswer';
import { isNumericReportedCause, NumericReportedCause } from 'models/ReportedCause';

import DistressCausesReport from 'components/Ticket/TicketRow/DistressCausesReport';

interface Props {
  report: QuestionnaireAnswer;
  patient: Patient;
}

export const DrugSpecificReportSummary: FC<Props> = ({ report, patient }) => {
  const { alertsStore } = useStores();

  const numericCauses = report.answer?.causes?.filter((cause) => isNumericReportedCause(cause));
  const temperatureCause = numericCauses && (numericCauses[0] as NumericReportedCause);

  let isFever = false;

  if (temperatureCause) {
    const feverCriticalAlert = alertsStore.specificDrugAlerts.find(
      (alert) => alert.urgency === AlertUrgency.CriticalAlert
    );

    const feverUrgentAlert = alertsStore.specificDrugAlerts.find(
      (alert) => alert.urgency === AlertUrgency.UrgentAlert
    );

    isFever =
      feverCriticalAlert?.conditions[0]?.isMet(report, patient) ||
      feverUrgentAlert?.conditions[0]?.isMet(report, patient) ||
      false;
  }

  return (
    <>
      {temperatureCause && (
        <div>
          <p className="mb-0">
            <span className="symptom-name">{isFever ? 'Fever' : 'Temperature'}:&nbsp;</span>
            {temperatureCause.value === 'iDontKnow' ? 'Unknown' : `${temperatureCause.value}°F`}
          </p>
        </div>
      )}

      <DistressCausesReport report={report} patient={patient} />
    </>
  );
};
