import { transformErrorUiProps } from 'errors';

import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import { ProtocolType } from 'models/ScheduledProtocol';

export interface ReportRequestDetails {
  patientId: number;
  message: string;
  reportType: ProtocolType;
  reportName: string;
}

export interface ReportRequestResponse {
  successIds?: number[];
  blockedIds?: number[];
}

const httpService = new HttpService('patient communication');

export class PatientCommunicationFetcher {
  static async sendReportRequest(requestReportDetails: ReportRequestDetails) {
    return await httpService.post({
      url: API_URLS.REQUEST_REPORT,
      data: { requestReportDetails },
      transformResponse: (response: ReportRequestResponse) => ({
        successIds: response.successIds || [],
        blockedIds: response.blockedIds || []
      }),
      transformError: (error) => {
        error.ui.title = 'Failed to send sms to patient';
        return error;
      }
    });
  }

  static async snoozePatient(patientId: number) {
    return await httpService.post({
      url: API_URLS.SNOOZE_PATIENT,
      data: { patientId },
      transformError: transformErrorUiProps('Failed to send sms to patient')
    });
  }
}
