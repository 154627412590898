import { FC, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Dialogs } from 'analytics/events/dialog';

import Slider from 'react-rangeslider';

import { useStores } from 'mobx/hooks/useStores';

import { AlertTargetPopulationName } from 'models/AlertTargetPopulation';

import ClinicianAlert, { AlertUrgency, ConditionType, PopulationType } from 'models/ClinicianAlert';

import SpecificCauseCondition from 'models/Conditions/SpecificCauseCondition';
import { SymptomTicketUrgencyText } from 'models/OperatorTicket';

import { useSymptomsAlertModal } from 'hooks/useSymptomsAlertModal';

import { SymptomsAlertFormFields } from 'views/Modals/SymptomsAlertModals/SymptomsAlertModal.types';

import {
  conditionTypeOptions,
  urgencyOptions,
  populationTypeOptions,
  severityOptions
} from 'views/Modals/SymptomsAlertModals/SymptomsAlertModals.constants';

import {
  formatAlert,
  getAlertConditionsDefaultValues,
  getSelectedCausesDefaultValues
} from 'views/Modals/SymptomsAlertModals/SymptomsAlertModals.utils';

import { FormExclusiveToggleButton } from 'components/UIkit/atoms/Button';
import { MessageDialog } from 'components/UIkit/atoms/Dialog';
import { FormMultiAutocomplete, FormSelect } from 'components/UIkit/atoms/Dropdown';
import { FormSpecificPatientsAsyncMultiAutocomplete } from 'components/UIkit/atoms/Dropdown/Select/FeatureSpecific/SpecificPatientsAsyncMultiAutocomplete';
import { FormModal } from 'components/UIkit/atoms/Modal/FormModal';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  alert: ClinicianAlert;
  onDeleteAlert: () => void;
}

export const EditSymptomsAlertModal: FC<Props> = ({ isOpen, closeModal, alert, onDeleteAlert }) => {
  const { constantsStore, alertsStore } = useStores();

  const {
    isLoading,
    setIsLoading,
    formMethods,
    conditionalFieldsVisibleState,
    loadOptions,
    handleSpecificPatientsSearched,
    isSpecificPatientsSelectMenuOpen,
    handleConditionTypeChanged,
    distressLevelField,
    analyticsEvents
  } = useSymptomsAlertModal('edit rule');

  const {
    isDistressSliderVisible,
    isIssuesDropdownVisible,
    isSearchPatientsVisible,
    isThresholdToggleButtonVisible
  } = conditionalFieldsVisibleState;

  const { handleSubmit, setValue, formState } = formMethods;

  const [isDeleteRulePopupOpen, setIsDeleteRulePopupOpen] = useState(false);

  const onSubmit = async (formValues: SymptomsAlertFormFields) => {
    setIsLoading(true);
    analyticsEvents.trackSaveAlert();
    const newAlert = formatAlert(formValues);

    newAlert.id = alert.id;
    try {
      await alertsStore.updateClinicianAlert(newAlert);
      closeModal();
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };
  const handleDeleteAlert = async () => {
    setIsLoading(true);
    try {
      await alertsStore.removeClinicianAlert(alert.id);
      setIsDeleteRulePopupOpen(false);
      closeModal();
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };
  const formDefaultValues: SymptomsAlertFormFields = {
    populationType: {
      value: alert?.population?.type as PopulationType.All | PopulationType.SpecificPatients,
      label: alert?.population?.name as AlertTargetPopulationName
    },
    urgency: {
      value: alert?.urgency as
        | AlertUrgency.Alert
        | AlertUrgency.UrgentAlert
        | AlertUrgency.CriticalAlert,
      label: alert?.getUrgencyName() as SymptomTicketUrgencyText
    },
    conditionType: getAlertConditionsDefaultValues(alert),
    selectedCauses: getSelectedCausesDefaultValues(alert),
    selectedPatients: alert?.population?.patients?.map((patient) => ({
      value: patient.patientId,
      label: `${patient.firstName} ${patient.lastName}`
    })),
    distressLevel:
      alert?.conditions[0]?.type === ConditionType.Distress
        ? alert.conditions[0].getBottomThreshold()
        : 0,
    threshold: alert?.conditions[0]?.getBottomThreshold()
  };

  const options = useMemo(() => {
    return constantsStore.symptomsMap.items
      .filter((item) => {
        return (
          !item.isDeleted ||
          alert?.conditions.some((condition) => {
            return condition instanceof SpecificCauseCondition && condition.getId() === item.id;
          })
        );
      })
      .map((item) => ({
        value: item.id,
        label: item.name
      }));
  }, [constantsStore.symptomsMap, alert?.conditions]);

  return (
    <>
      <FormModal
        defaultValues={formDefaultValues}
        methods={formMethods}
        isOpen={isOpen}
        title="Edit Rule"
        confirmActions={[
          {
            onClick: handleSubmit(onSubmit),
            text: 'Save',
            disabled: !formState.isValid || isLoading
          }
        ]}
        closeAction={{
          onClick: () => {
            analyticsEvents.trackCancelChanges();
            closeModal();
          },
          disabled: isLoading
        }}
        secondaryAction={{
          type: 'button',
          text: 'Delete',
          disabled: isLoading,
          onClick: () => {
            onDeleteAlert();
            setIsDeleteRulePopupOpen(true);
          }
        }}
        resetDataAfterClose={() => setIsLoading(false)}
      >
        <Box mb={20}>
          <FormSelect
            placeholder="Select which patients this rule applies to"
            name="populationType"
            testHook="rule-applies-to-select"
            options={populationTypeOptions}
            label="Rule Applies to:"
            isRequired
            onChange={(_, actionMeta, eventKey) => {
              setValue('selectedPatients', []);
              analyticsEvents.trackPopulationType(actionMeta, eventKey);
            }}
          />
        </Box>

        {isSearchPatientsVisible && (
          <Box mb={20}>
            <FormSpecificPatientsAsyncMultiAutocomplete
              name="selectedPatients"
              loadOptions={loadOptions}
              placeholder="Search by Name, MRN, or DOB."
              openMenuOnClick={false}
              menuIsOpen={isSpecificPatientsSelectMenuOpen}
              isRequired={isSearchPatientsVisible}
              onInputChange={handleSpecificPatientsSearched}
              onChange={(_, actionMeta, eventKey) =>
                analyticsEvents.trackSpecificPatients(actionMeta, eventKey)
              }
            />
          </Box>
        )}

        <Box mb={20}>
          <FormSelect
            placeholder="Select urgency"
            name="urgency"
            testHook="urgency-select"
            options={urgencyOptions}
            sortAlphabetically={false}
            label="Urgency"
            isRequired
            onChange={(_, actionMeta, eventKey) =>
              analyticsEvents.trackUrgency(actionMeta, eventKey)
            }
          />
        </Box>

        <Box mb={20}>
          <FormSelect
            placeholder="Select trigger type"
            name="conditionType"
            testHook="trigger-type-select"
            options={conditionTypeOptions}
            label="Trigger Type"
            isRequired
            onChange={(_, actionMeta, eventKey) => {
              handleConditionTypeChanged();
              analyticsEvents.trackTriggerType(actionMeta, eventKey);
            }}
          />
        </Box>

        {isDistressSliderVisible && (
          <Box mb={40} mt={28} px={2}>
            <Slider
              min={0}
              max={10}
              step={1}
              onChange={distressLevelField.onChange}
              value={distressLevelField.value || 0}
              onChangeComplete={analyticsEvents.trackDistressLevel}
            />
          </Box>
        )}

        {isIssuesDropdownVisible && (
          <Box mb={20}>
            <FormMultiAutocomplete
              displaySelectedSummary={false}
              name="selectedCauses"
              options={options}
              isRequired={isIssuesDropdownVisible}
              placeholder="Search by Issue"
              onChange={(_, actionMeta, eventKey) =>
                analyticsEvents.trackCauses(actionMeta, eventKey)
              }
            />
          </Box>
        )}

        {isThresholdToggleButtonVisible && (
          <Box mb={40} width="65%">
            <FormExclusiveToggleButton
              options={severityOptions}
              value={1}
              label="Threshold"
              fullWidth
              name="threshold"
              disabled={false}
              required={isThresholdToggleButtonVisible}
              onChange={analyticsEvents.trackThreshold}
            />
          </Box>
        )}
      </FormModal>

      <MessageDialog
        id={Dialogs.DeleteTriageRule}
        isOpen={isDeleteRulePopupOpen}
        title="Delete Triage Rule?"
        handleClose={() => setIsDeleteRulePopupOpen(false)}
        primaryActionProps={{ text: 'Delete', onClick: handleDeleteAlert }}
        secondaryActionProps={{ text: 'Cancel', onClick: () => setIsDeleteRulePopupOpen(false) }}
      >
        This action cannot be undone.
      </MessageDialog>
    </>
  );
};
