import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Click | AnalyticEventAction.Select;

type Value =
  | 'Log Call'
  | 'Request Report'
  | 'Create Ticket'
  | 'Create Task'
  | 'Resolve'
  | 'Reassign'
  | 'Resolve And Comment'
  | 'Change Status'
  | 'Reschedule Task'
  | 'Edit Task'
  | 'Edit Operator Ticket'
  | 'Pick up'
  | 'Send invitation - CCM'
  | 'Send invitation - PCM'
  | 'To Do'
  | 'In Progress'
  | 'Closed'
  | 'Resolved'
  | 'Reschedule'
  | 'Send invitation';

interface Properties {
  action: Action;
  value?: Value;
  ticket_id?: number | number[];
  source?: 'patient' | 'task' | 'ticket';
  item_index?: number;
  page_number?: number;
  bulk_action?: boolean;
}

export const trackActionsMenuAnalyticsEvent = ({
  action,
  value,
  bulk_action = false,
  ...optionalProps
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.ActionsMenu,
    properties: { action, value, bulk_action, ...optionalProps }
  });
};
