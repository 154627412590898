import { NotificationsFetcher } from 'fetchers/NotificationsFetcher';

import { fromNow } from 'utils/DateUtils';

import { TicketClass, TicketStatus } from 'models/Ticket';
import { TicketTypeKind } from 'models/TicketTypes';

import {
  HIGHLIGHT_TICKET_QUERY_PARAM,
  PATIENT_TABS,
  TAB_QUERY_PARAM
} from 'views/Patient/PatientMain/PatientMainView.constants';

import { TicketDetails } from 'components/NotificationCenter/types';

export const getNotificationSummary = (notificationTicketDetails: TicketDetails) => {
  const { ticketKind, ticketClass, displayName, dueDate } = notificationTicketDetails;

  switch (ticketClass) {
    case TicketClass.operator:
      return ticketKind === TicketTypeKind.other ? displayName : 'Symptom Report via Triage Call';

    case TicketClass.patient:
      return 'Symptom Assessment';

    case TicketClass.task:
      if (!dueDate) {
        return displayName;
      }

      return `Due ${fromNow(new Date(dueDate))}: ${displayName}`;
  }
};

const tabsToStatusMap = {
  [TicketStatus.OPEN]: PATIENT_TABS.OPEN_TICKETS,
  [TicketStatus.RESOLVED]: PATIENT_TABS.RESOLVED_TICKETS
};

export const getNotificationPathName = (
  patientId: number,
  ticketId: number,
  status: TicketStatus.OPEN | TicketStatus.RESOLVED,
  type: TicketClass
) => {
  let patientTab;

  if (type === TicketClass.task) {
    patientTab = PATIENT_TABS.EPISODES_AND_TASKS;
  } else {
    patientTab = tabsToStatusMap[status];
  }

  return `/patient/${patientId}?${TAB_QUERY_PARAM}=${patientTab}&${HIGHLIGHT_TICKET_QUERY_PARAM}=${ticketId}`;
};

export const getNotificationTicketClassText = (ticket: TicketDetails | undefined) => {
  if (!ticket) {
    return;
  }

  return ticket.ticketClass === TicketClass.task ? 'task' : 'ticket';
};

export async function getHasNewNotifications() {
  const { unseenNotifications } = await NotificationsFetcher.getNewNotificationsNumber();
  return unseenNotifications > 0;
}

export const NOTIFICATIONS_PANEL_WIDTH = 515;
export const PANEL_BOTTOM_SPACE = 20;
export const NOTIFICATIONS_PAGE_SIZE = 20;
export const NOTIFICATIONS_FIRST_PAGE = 0;
// Threshold - Number between 0 and 1 indicating the percentage that should be visible before triggering.
export const NEW_NOTIFICATIONS_THRESHOLD = 0;
export const NEW_NOTIFICATIONS_DELAY = 0;
export const INTERSECTION_THRESHOLD = 0;
export const LOAD_MORE_DELAY = 100;
