// @ts-strict-ignore
import { action, observable, runInAction, makeObservable } from 'mobx';

import { SettingsFetcher } from 'fetchers/SettingsFetcher';

import { FEATURES } from 'constants/features';

import InstitutionSettings from 'models/Settings';

export default class SettingsStore {
  @observable
  institutionSettings: InstitutionSettings = null;

  @observable
  features: FEATURES[] = null;

  constructor() {
    makeObservable(this);
  }

  init(): Promise<void> {
    return this.fetchInstitutionSettings();
  }

  @action
  async fetchInstitutionSettings() {
    const institutionSettings = await SettingsFetcher.getInstitutionSettings();
    runInAction(() => {
      const { features, settings } = institutionSettings;
      this.institutionSettings = settings;
      this.features = features;
    });
  }

  hasFeature = (featureName: FEATURES) => {
    return this.features?.includes(featureName);
  };
}
