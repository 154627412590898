import { computed } from 'mobx';

import { rootStore } from 'mobx/stores';

import { BaseOption, PathwayQuestionTypes } from 'models/PathwayTemplates';

import { TicketClinician } from 'models/Ticket';

import { TicketTypeKind } from './TicketTypes';

interface TicketSubTypesIdsField {
  ids: number[];
}

export enum OperatorTicketUrgency {
  Standard = 0,
  High = 1
}

export enum SymptomTicketUrgency {
  NurseReview = 0,
  AttentionToday = 1,
  ImmediateAttention = 2
}

export enum OperatorTicketUrgencyText {
  Standard = 'Standard',
  High = 'High'
}

export enum SymptomTicketUrgencyText {
  NurseReview = 'Nurse Review',
  AttentionToday = 'Attention Today',
  ImmediateAttention = 'Immediate Attention'
}

export enum OperatorTicketSource {
  Operator = 'operator',
  Patient = 'patient'
}

type Source = OperatorTicketSource.Operator | OperatorTicketSource.Patient;

export interface TicketLightweightPathwayAnswer {
  id: string;
  questionType: PathwayQuestionTypes;
  pathwayId: string;
  pathwayName: string;
  questionId: string;
  questionTitle: string;
  categoryId: string;
  value: any;
  order: number;
  deniedOptions?: BaseOption[];
}

export class OperatorTicket {
  ticketId: number;
  reportingDoctor: TicketClinician;
  patientContactId?: number;
  contactName: string;
  contactPhoneNumber?: string;
  contactRelationship?: string;
  contactHippaAuthDate?: Date;
  providerId?: string;
  locationId: number;
  ticketTypeId?: number;
  ticketSubTypesIds: TicketSubTypesIdsField;
  urgency?: OperatorTicketUrgency | SymptomTicketUrgency;
  // createdAt - This may be string
  createdAt: Date;
  updatedAt: Date;
  notes?: string;
  source: Source;
  ticketTypeDisplayName?: string;
  pathwayAnswers: TicketLightweightPathwayAnswer[];

  constructor(
    ticketId: number,
    reportingDoctor: TicketClinician,
    patientContactId: number,
    contactName: string,
    contactPhoneNumber: string,
    contactRelationship: string,
    contactHippaAuthDate: Date,
    notes: string,
    providerId: string,
    locationId: number,
    ticketTypeId: number,
    ticketSubTypesIds: TicketSubTypesIdsField,
    urgency: OperatorTicketUrgency,
    createdAt: Date,
    updatedAt: Date,
    source: Source,
    ticketTypeDisplayName: string,
    pathwayAnswers: TicketLightweightPathwayAnswer[] = []
  ) {
    this.ticketId = ticketId;
    this.patientContactId = patientContactId;
    this.contactName = contactName;
    this.reportingDoctor = reportingDoctor;
    this.contactPhoneNumber = contactPhoneNumber;
    this.contactRelationship = contactRelationship;
    this.contactHippaAuthDate = contactHippaAuthDate;
    this.notes = notes;
    this.providerId = providerId;
    this.locationId = locationId;
    this.ticketTypeId = ticketTypeId;
    this.ticketSubTypesIds = ticketSubTypesIds;
    this.urgency = urgency;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.source = source;
    this.ticketTypeDisplayName = ticketTypeDisplayName;
    this.pathwayAnswers = pathwayAnswers;
  }

  // based on assumption of 2 level tree - should be used for symptoms only
  get getOperatorTicketTypes() {
    const { ticketTypeId, ticketSubTypesIds } = this;
    const subTypeIds = ticketSubTypesIds.ids.map((subTypeId) => `${ticketTypeId}_${subTypeId}`);
    return {
      type: ticketTypeId,
      subTypes: subTypeIds
    };
  }

  @computed
  get subTicketTypeIds(): number[] {
    return this.ticketSubTypesIds.ids;
  }

  @computed
  get kind(): TicketTypeKind {
    const type = rootStore.stores.ticketTypesStore.getTicketType(
      this.ticketTypeId?.toString() || ''
    );
    return type?.kind;
  }

  @computed get pathwayIds(): string[] {
    return Array.from(new Set(this.pathwayAnswers.map((pathwayAnswer) => pathwayAnswer.pathwayId)));
  }
}

export default OperatorTicket;
