import { FC, ReactNode, MouseEvent } from 'react';

import { css, DialogActions, DialogContent, DialogTitle, styled } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';

import { Dialogs as DialogAnalyticsId, trackDialogAnalyticsEvent } from 'analytics/events/dialog';

import { Testable } from 'utils/TypeUtils';

import IconButton from 'components/Buttons/IconButton';
import Icon from 'components/Icons/Icon';
import { FilledButton, OutlinedButton } from 'components/UIkit/atoms/Button';

import { Dialog } from './Dialog';
import { DialogCloseReason } from './MessageDialog.constants';
import { DialogActionButtonProps } from './MessageDialog.types';
import { closeReasonToAnaltiycsEventAction } from './MessageDialog.utils';

export interface MessageDialogProps extends Testable {
  id: DialogAnalyticsId;
  /** Whether the dialog is shown. */
  isOpen: boolean;
  /** Should set the `isOpen` prop to false. */
  handleClose: (reason: DialogCloseReason) => void;
  title: string;
  children?: ReactNode;
  /** Whether to prevent the dialog from closing when clicking the corner `x` button. */
  disableClosing?: boolean;
  primaryActionProps: DialogActionButtonProps;
  secondaryActionProps?: DialogActionButtonProps;
  disableAutoFocus?: boolean;
}

/** A message dialog that displays a message to the user and allows customizable action buttons. Events are tracked to analytics. */
export const MessageDialog: FC<MessageDialogProps> = ({
  id,
  isOpen,
  handleClose,
  title,
  children,
  primaryActionProps,
  secondaryActionProps,
  disableClosing = false,
  disableAutoFocus,
  testHook
}) => {
  const handleActionClick = (
    buttonProps: DialogActionButtonProps,
    event: MouseEvent<HTMLButtonElement>
  ) => {
    trackDialogAnalyticsEvent(buttonProps.text, id);
    buttonProps.onClick(event);
  };

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      testHook={isOpen ? testHook : ''}
      disableAutoFocus={disableAutoFocus}
    >
      <DialogTitle>
        <StyledCloseButton
          disabled={disableClosing}
          aria-label="close"
          onClick={() => {
            if (!disableClosing) {
              trackDialogAnalyticsEvent(
                closeReasonToAnaltiycsEventAction(DialogCloseReason.CloseButtonClick),
                id
              );
              handleClose && handleClose(DialogCloseReason.CloseButtonClick);
            }
          }}
        >
          <Icon.XThick />
        </StyledCloseButton>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {secondaryActionProps && (
          <OutlinedButton
            display="contents"
            fullWidth
            onClick={(event) => handleActionClick(secondaryActionProps, event)}
            disabled={secondaryActionProps.disabled}
          >
            {secondaryActionProps.text}
          </OutlinedButton>
        )}
        {primaryActionProps && (
          <FilledButton
            display="contents"
            fullWidth
            testHook={`message-dialog-primary-button${testHook ? `-${testHook}` : ''}`}
            onClick={(event) => handleActionClick(primaryActionProps, event)}
            disabled={primaryActionProps.disabled}
          >
            {primaryActionProps.text}
          </FilledButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

const StyledCloseButton = styled(IconButton)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing(8)};
    right: ${theme.spacing(8)};
    color: ${theme.palette.natural.black};
  `
);
