// @ts-strict-ignore

import { isEmpty } from 'lodash/fp';

import { observer } from 'mobx-react';

import ReactDOMServer from 'react-dom/server';

import { useTicketNetworkLoading } from 'mobx/hooks/useNetworkLoading';

import { useStores } from 'mobx/hooks/useStores';

import { useTicketCalls } from 'mobx/hooks/useTicketCalls';

import { FEATURES } from 'constants/features';

import Ticket from 'models/Ticket';

import { useVerticalOverflowDetector } from 'hooks/useVerticalOverflowDetector';

import CardStack from 'views/Widgets/CardStack';

import { TicketPatientCallsList } from 'views/Widgets/PatientCallsList';

import { usePatientModel } from 'components/Patient/usePatientModel';
import AlertTags from 'components/Ticket/TicketRow/AlertTags';
import TicketCommentsAndContact from 'components/Ticket/TicketRow/CommentsAndCallback/TicketCommentsAndContact';
import { HtmlContent } from 'components/Ticket/TicketRow/HtmlContent';
import { TicketPathwaysAnswers } from 'components/Ticket/TicketRow/TicketPathwaysAnswers';
import TicketRowIcon from 'components/Ticket/TicketRow/TicketRowIcon';
import { TicketSummaryButton } from 'components/Ticket/TicketRow/TicketSummaryButton/TicketSummaryButton';
import { useTicketToggle } from 'components/Ticket/TicketRow/useTicketToggle';
import useTicketOverviewContext from 'components/Ticket/TicketsContainers/useTicketOverviewContext';
import { Text } from 'components/UIkit/atoms/Text';
import { TextProps } from 'components/UIkit/atoms/Text/Text';

import {
  getSymptomIconAndAlertType,
  OPERATOR_TICKETS_DESCRIPTION_MAX_LINES,
  useTicketProviderLocation
} from './shared';
import TicketCreatedBy from './TicketCreatedBy';

import TicketRenderer from './TicketRenderer';

export interface OperatorTicketRowProps {
  ticket: Ticket;
  className?: string;
  withPatientLink: boolean;
  ticketRefsCallback?: (node: HTMLElement, ticketId: number) => void;
  ticketIndex: number;
  ticketSectionCurrentPage?: number;
}

const SymptomOperatorTicketRow = observer(
  ({
    ticket,
    className,
    withPatientLink,
    ticketRefsCallback,
    ticketIndex,
    ticketSectionCurrentPage = 0
  }: OperatorTicketRowProps) => {
    const { ref: descriptionRef, isActive } = useVerticalOverflowDetector();
    const { isExpanded, toggle } = useTicketToggle(
      ticket.id,
      false,
      ticketSectionCurrentPage,
      isActive
    );
    const { ticketTypesStore, ticketsStore, alertsStore, settingsStore } = useStores();

    const { ticketActions: Actions } = useTicketOverviewContext();
    const { operatorTicket } = ticket;
    const patient = usePatientModel(ticket.patientId);
    const providerLocationText = useTicketProviderLocation(ticket);
    const isActionInProgress = useTicketNetworkLoading(ticket.id);

    const { calls, isLoadingCalls } = useTicketCalls(ticket, isExpanded);

    const ticketSelected = ticketsStore.ticketsBulkActionSet.has(ticket.id);

    const iconType = getSymptomIconAndAlertType(operatorTicket.urgency);
    const getTitleText = () => {
      return (
        <div>
          <span className="bold title-text">Symptom Report via Triage Call</span>
          {providerLocationText && <span className="subtitle-text"> — {providerLocationText}</span>}
        </div>
      );
    };

    const getDescriptionText = () => {
      const { subTypes } = operatorTicket.getOperatorTicketTypes;

      const descriptionContainerOptionalProps: {} | Pick<TextProps, 'maxNumberOfLines'> = isExpanded
        ? {}
        : { maxNumberOfLines: OPERATOR_TICKETS_DESCRIPTION_MAX_LINES };

      const isLightweightPathwaysQuestionsAnswersVisible =
        settingsStore.hasFeature(FEATURES.LIGHTWEIGHT_PATHWAYS) &&
        !isEmpty(ticket.operatorTicket?.pathwayIds);

      return (
        <>
          {subTypes.map((subType, index) => {
            const ticketType = ticketTypesStore.getTicketType(subType);
            const alertDescriptions = alertsStore.getDxAlertDescriptionsForSymptom(
              ticketType.id,
              patient.dxCodeIds
            );

            return (
              <span key={subType} className="d-inline-block">
                <span className="symptom-name">{ticketType?.name}</span>
                <span className="d-inline-block">
                  <AlertTags tags={alertDescriptions} />
                </span>
                {index < subTypes.length - 1 && <>,&nbsp;</>}
              </span>
            );
          })}

          {operatorTicket.notes && (
            <span className="bold">
              {' '}
              - <HtmlContent inline>{operatorTicket.notes}</HtmlContent>
            </span>
          )}

          <Text
            variant="body2"
            {...descriptionContainerOptionalProps}
            component="div"
            ref={descriptionRef}
          >
            {isLightweightPathwaysQuestionsAnswersVisible && (
              <HtmlContent>
                {ReactDOMServer.renderToStaticMarkup(
                  <TicketPathwaysAnswers ticket={ticket} isExpanded={isExpanded} />
                )}
              </HtmlContent>
            )}
          </Text>

          <TicketSummaryButton
            onClick={toggle}
            isExpanded={isExpanded}
            items={{
              draft: ticket.draftCallCount,
              comment: ticket.commentCount,
              call: ticket.callCount,
              callAttempt: ticket.callAttemptsCount,
              pathway: isLightweightPathwaysQuestionsAnswersVisible
                ? ticket.operatorTicket?.pathwayIds?.length
                : 0
            }}
          />
        </>
      );
    };

    const summary = (
      <div>
        <div>{getTitleText()}</div>
        <div>{getDescriptionText()}</div>
      </div>
    );

    return (
      <CardStack
        isSelected={ticketSelected}
        isOpen={isExpanded}
        classes={{
          root: className,
          closed: 'report-row-closed'
        }}
        numberOfStackedItems={ticket.callsAndDraftsCount}
      >
        <TicketRenderer
          ticketId={ticket.id}
          isLoading={isActionInProgress}
          ticketRefsCallback={ticketRefsCallback}
          icon={<TicketRowIcon type={iconType} />}
          summary={summary}
          classes={{
            container: 'no-border',
            body: 'wide-summary'
          }}
          actions={
            <Actions
              ticket={ticket}
              ticketIndex={ticketIndex}
              ticketSectionCurrentPage={ticketSectionCurrentPage}
            />
          }
          patient={patient}
          createdByText={<TicketCreatedBy ticket={ticket} />}
          withPatientLink={withPatientLink}
        >
          {isExpanded && <TicketCommentsAndContact ticket={ticket} />}
        </TicketRenderer>
        {TicketPatientCallsList({ calls, patient, isLoadingCalls, ticket })}
      </CardStack>
    );
  }
);

export default SymptomOperatorTicketRow;
