import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { AnalyticEventAction } from 'analytics';
import { trackNotificationsPanelAnalyticsEvent } from 'analytics/events/notifications-panel';

import { partition } from 'lodash/fp';
import { observer } from 'mobx-react';

import { useInView } from 'react-intersection-observer';
import { useHistory } from 'react-router-dom';

import { useMount } from 'react-use';

import { useStores } from 'mobx/hooks/useStores';

import { NotificationsFetcher } from 'fetchers/NotificationsFetcher';

import { TicketStatus } from 'models/Ticket';

import { HEADER_HEIGHT } from 'containers/Layout/Layout.constants';

import Icon from 'components/Icons/Icon';
import { EmptyNotifications } from 'components/NotificationCenter/EmptyNotifications';
import { ItemDeletedPopup } from 'components/NotificationCenter/ItemDeletedPopup';
import { Notifications } from 'components/NotificationCenter/Notifications';
import { NotificationsLoadError } from 'components/NotificationCenter/NotificationsLoadError';
import { NotificationsPagingError } from 'components/NotificationCenter/NotificationsPagingError';

import { ClinicianNotification } from 'components/NotificationCenter/types';
import {
  getHasNewNotifications,
  getNotificationPathName,
  INTERSECTION_THRESHOLD,
  LOAD_MORE_DELAY,
  NEW_NOTIFICATIONS_DELAY,
  NEW_NOTIFICATIONS_THRESHOLD,
  NOTIFICATIONS_FIRST_PAGE,
  NOTIFICATIONS_PAGE_SIZE,
  NOTIFICATIONS_PANEL_WIDTH,
  PANEL_BOTTOM_SPACE
} from 'components/NotificationCenter/utils';
import { Tooltip } from 'components/Tooltip';

import { NotificationLoader } from './NotificationLoader';

const BaseNotificationPanel: FC = () => {
  const { uiStore, settingsStore } = useStores();
  const history = useHistory();
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const [notifications, setNotifications] = useState<ClinicianNotification[] | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(NOTIFICATIONS_FIRST_PAGE);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeletedPopupOpen, setIsDeletedTicketPopupOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [lastOpenTimestamp, setLastOpenTimestamp] = useState<number | null>(null);
  const hasNewNotificationsInterval = useRef<NodeJS.Timeout | null>();
  const notificationIconRef = useRef<HTMLDivElement | null>(null);

  const updateHasNewNotifications = useCallback(async () => {
    const hasNew = await getHasNewNotifications();
    setHasNewNotifications(hasNew);
  }, []);

  useMount(updateHasNewNotifications);

  useEffect(
    function checkNewNotifications() {
      const clearNotificationInterval = () => {
        if (hasNewNotificationsInterval.current) {
          clearInterval(hasNewNotificationsInterval.current);
        }

        hasNewNotificationsInterval.current = null;
      };

      if (!uiStore.isNotificationPanelOpen && !hasNewNotificationsInterval.current) {
        hasNewNotificationsInterval.current = setInterval(() => {
          if (uiStore.isNotificationPanelOpen) {
            clearNotificationInterval();
            return;
          }

          if (!hasNewNotifications) {
            // since we use numberless badge - once hasNewNotifications is true, no need for further fetching)
            updateHasNewNotifications();
          }
        }, settingsStore.institutionSettings.notificationsInterval);
      }

      return clearNotificationInterval;
    },
    [uiStore.isNotificationPanelOpen, hasNewNotifications, settingsStore, updateHasNewNotifications]
  );

  const [previouslySeenNotifications, newNotifications] = partition(function (notification) {
    return notification.isSeen;
  }, notifications);

  const handleNewNotificationsSeen = useCallback(
    async (inView: boolean) => {
      if (inView && uiStore.isNotificationPanelOpen) {
        await NotificationsFetcher.updateNotifications({
          seen: newNotifications.map((notification) => notification.id)
        });

        updateHasNewNotifications();
      }
    },
    [newNotifications, uiStore.isNotificationPanelOpen, updateHasNewNotifications]
  );

  const { ref: newNotificationsRef } = useInView({
    threshold: NEW_NOTIFICATIONS_THRESHOLD,
    delay: NEW_NOTIFICATIONS_DELAY,
    onChange: handleNewNotificationsSeen
  });

  const handleLoaderIntersect = useCallback(
    async (inView: boolean) => {
      if (inView && hasMore && uiStore.isNotificationPanelOpen) {
        try {
          setIsLoading(true);
          const timestampParams = lastOpenTimestamp ? { timestamp: lastOpenTimestamp } : {};
          const newNotifications = await NotificationsFetcher.getNotifications({
            recordsPerPage: NOTIFICATIONS_PAGE_SIZE,
            pageNumber: page,
            ...timestampParams
          });
          setHasError(false);
          const mergedNotifications = [...(notifications || []), ...newNotifications];
          setNotifications(mergedNotifications);
          setPage((currentPage) => {
            return currentPage + 1;
          });
          setHasMore(
            newNotifications.length > 0 && !(newNotifications.length < NOTIFICATIONS_PAGE_SIZE)
          );
        } catch (err) {
          setHasError(true);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [lastOpenTimestamp, hasMore, notifications, page, uiStore.isNotificationPanelOpen]
  );

  const { ref: loaderRef } = useInView({
    threshold: INTERSECTION_THRESHOLD,
    delay: LOAD_MORE_DELAY,
    onChange: handleLoaderIntersect
  });

  const onNotificationClick = (notification: ClinicianNotification) => {
    trackNotificationsPanelAnalyticsEvent({
      action: AnalyticEventAction.Click,
      value: notification.isRead ? 'read' : 'unread',
      section: notification.isSeen ? 'seen' : 'unseen',
      type: notification.type
    });

    if (!notification.isRead) {
      NotificationsFetcher.updateNotifications({
        read: [notification.id]
      });
    }

    switch (notification.notificationDetails.ticket?.status) {
      case TicketStatus.RESOLVED:
      case TicketStatus.OPEN:
        const pathname = getNotificationPathName(
          notification.notificationDetails.patient.id,
          notification.notificationDetails.ticket.id,
          notification.notificationDetails.ticket?.status,
          notification.notificationDetails.ticket?.ticketClass
        );

        history.push(pathname);
        break;

      case TicketStatus.DELETED:
        closeNotificationsPanel(false);
        setIsDeletedTicketPopupOpen(true);
        break;
    }

    closeNotificationsPanel(false);
  };

  // TODO: next phase
  /*const removeNotification = (notificationId: number) => {
    //await deleteNotification()...
    // should we call again getNotifications() ?? or:
    const newNotifications = notifications.filter((not) => not.id !== notificationId);
    setNotifications(newNotifications);
  };*/

  // TODO: next phase
  /*const markAsReadNotification = async (notificationId: number) => {
    const newNotifications = [...notifications];
    const notification = newNotifications.find(
      (notification) => notification.id === notificationId
    );

    if (!notification) {
      console.warn(`Notification with id: ${notificationId} was not found!`);
    }

    if (!notification.isRead) {
      await NotificationsFetcher.updateNotifications({
        read: [notification.id]
      });
    }
    notification!.isRead = true;
    setNotifications(newNotifications);
  };*/

  const toggleNotificationsPanel = () => {
    if (uiStore.isNotificationPanelOpen) {
      closeNotificationsPanel(true);
      return;
    }

    uiStore.setIsNotificationPanelOpen(true);
    setLastOpenTimestamp(Date.now());
    trackNotificationsPanelAnalyticsEvent({
      action: AnalyticEventAction.Open,
      value: hasNewNotifications ? 'with badge' : 'without badge'
    });
  };

  const resetPanel = () => {
    setNotifications(null);
    setHasMore(true);
    setPage(NOTIFICATIONS_FIRST_PAGE);
    setLastOpenTimestamp(null);
  };

  const closeNotificationsPanel = (isCloseByIcon: boolean) => {
    uiStore.setIsNotificationPanelOpen(false);
    resetPanel();

    trackNotificationsPanelAnalyticsEvent({
      action: AnalyticEventAction.Close,
      value: isCloseByIcon ? 'by bell icon' : 'not by bell icon'
    });
  };

  const noNotifications = notifications && notifications.length === 0;
  const showNoNotifications = !isLoading && noNotifications;
  const showInitialLoadError = hasError && notifications === null;
  const showNotificationsLoader = hasMore && !hasError;
  const showPostInitialLoadError = hasError && notifications?.length;

  return (
    <>
      {isDeletedPopupOpen && (
        <ItemDeletedPopup
          isOpen={isDeletedPopupOpen}
          setIsPopupOpen={setIsDeletedTicketPopupOpen}
        />
      )}

      <StyledTooltip
        shouldTruncate={false}
        reference={notificationIconRef}
        label={
          <StyledNotificationIconContainer
            container
            justifyContent="center"
            alignItems="center"
            className="menu-item"
            onClick={toggleNotificationsPanel}
            ref={notificationIconRef}
          >
            <Box position="relative" className={hasNewNotifications ? 'new-notifications' : ''}>
              <StyledBadge isVisible={hasNewNotifications} />
              <Icon.Notification />
            </Box>
          </StyledNotificationIconContainer>
        }
        maxWidth={NOTIFICATIONS_PANEL_WIDTH}
        offset={[-80, 10]}
        maxHeight="100vh"
        controller={{
          onClickOutside: () => closeNotificationsPanel(false),
          visible: uiStore.isNotificationPanelOpen
        }}
      >
        <StyledContainer>
          <Typography variant="h2" ml={12} mb={12}>
            Notifications
          </Typography>

          {newNotifications.length > 0 && (
            <div ref={newNotificationsRef}>
              <Notifications
                notifications={newNotifications}
                onNotificationClick={onNotificationClick}
              />
            </div>
          )}

          {previouslySeenNotifications.length > 0 && (
            <>
              <Grid container alignItems="center" wrap="nowrap">
                <StyledSeenNotificationsSectionTitle variant="body2" my={12} pl={12}>
                  EARLIER
                </StyledSeenNotificationsSectionTitle>
                <StyledSeparator />
              </Grid>

              <Notifications
                notifications={previouslySeenNotifications}
                onNotificationClick={onNotificationClick}
              />
            </>
          )}

          {showNoNotifications && <EmptyNotifications />}
          {showInitialLoadError && <NotificationsLoadError />}
          {showPostInitialLoadError && <NotificationsPagingError />}
          {showNotificationsLoader && <NotificationLoader ref={loaderRef} />}
        </StyledContainer>
      </StyledTooltip>
    </>
  );
};

export const NotificationPanel = observer(BaseNotificationPanel);

const StyledBadge = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isVisible'
})<{ isVisible: boolean }>(
  ({ theme, isVisible }) => css`
    transition: opacity 800ms;
    opacity: ${isVisible ? '1' : '0'};
    position: absolute;
    top: 1px;
    right: -2px;
    height: 13px;
    width: 13px;
    border-radius: ${theme.borderRadius.full};
    background-color: ${theme.palette.error.main};
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  `
);

const StyledNotificationIconContainer = styled(Grid)(
  ({ theme }) => css`
    margin-left: 0;
    width: 48px;
    height: 48px;
    border-radius: ${theme.borderRadius.full};

    &:hover {
      background-color: ${theme.palette.natural.inactiveBackground};
    }
  `
);

const StyledTooltip = styled(Tooltip)`
  .tippy-content {
    max-height: 100%;
  }
`;

const StyledContainer = styled(Box)(
  ({ theme }) => css`
    height: calc(100vh - ${HEADER_HEIGHT}px - ${PANEL_BOTTOM_SPACE}px);
    width: ${NOTIFICATIONS_PANEL_WIDTH}px;
    background-color: ${theme.palette.natural.white};
    border-radius: ${theme.borderRadius.small};
    padding-top: ${theme.spacing(20)};
    position: relative;
    overflow: auto;
  `
);

const StyledSeparator = styled('hr')(
  ({ theme }) => css`
    width: 100%;
    margin-left: ${theme.spacing(8)};
  `
);

const StyledSeenNotificationsSectionTitle = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.text.disabled};
    white-space: nowrap;
  `
);
