// @ts-strict-ignore
import { flow, intersection } from 'lodash/fp';

import { UserType } from 'utils/AccountUtils';
import { TicketSubTypeOption } from 'utils/TicketType.utils';

import {
  TICKET_TYPE_ORAL_ONCO_OVERDUE_ID,
  TICKET_TYPE_OVERDUE_ID
} from 'constants/itemTypes.const';

import Department from 'models/Department';
import { ClinicianUserStatus } from 'models/Doctor';
import Patient from 'models/Patient';
import SeverityReportedCause from 'models/ReportedCause';
import Ticket from 'models/Ticket';
import { TicketTypeKind } from 'models/TicketTypes';

import { PatientFilters } from 'views/Filters/filters.types';

import { ISelectOption } from 'views/Widgets/StyledSelect';

import { isSubStringCaseInsensitive } from './StringUtils';

// Patients filters:

export const getFilteredPatients = (patients: Patient[], selectedFilters: PatientFilters) => {
  return patients.filter((patientModel: Patient) => {
    // Filter by search term
    if (
      selectedFilters.searchTerm &&
      !filterByNameOrMrn(patientModel, selectedFilters.searchTerm)
    ) {
      return false;
    }

    if (
      selectedFilters.patientTags?.length &&
      !filterTags(
        patientModel,
        selectedFilters.patientTags.map((tag) => tag.value)
      )
    ) {
      return false;
    }

    // Filter by provider
    if (
      selectedFilters.providers?.length &&
      !isPatientInProviders(
        patientModel,
        selectedFilters.providers.map((provider) => provider.value.id)
      )
    ) {
      return false;
    }

    // Filter by location
    if (
      selectedFilters.locations?.length &&
      !filterLocations(
        patientModel,
        selectedFilters.locations.map((location) => location.value)
      )
    ) {
      return false;
    }

    // Filter by monitoring status
    if (
      selectedFilters.monitoringConsent?.length &&
      !filterMonitoringStatus(
        patientModel,
        selectedFilters.monitoringConsent.map((status) => status.value)
      )
    ) {
      return false;
    }
    return true;
  });
};

export const filterByNameOrMrn = (patientModel: Patient, searchTerm: string) => {
  if (
    !isSubStringCaseInsensitive(patientModel.fullName, searchTerm) &&
    !(patientModel.mrn && isSubStringCaseInsensitive(patientModel.mrn, searchTerm))
  ) {
    return false;
  }
  return true;
};

export const filterTags = (patientModel: Patient, tagIds: number[]) => {
  if (!patientModel.tags) {
    return false;
  }

  return tagIds.some((tagId) => patientModel.tags.includes(tagId));
};

export const filterLocations = (patientModel: Patient, locationsIds: number[]) => {
  return locationsIds.includes(patientModel.locationId);
};

export const filterTicketLocations = (ticket: Ticket, locationsIds: number[]) => {
  if (ticket.isOperatorTicket) {
    return locationsIds.includes(ticket.operatorTicket.locationId);
  }
  return true;
};

export const filterMonitoringStatus = (patientModel: Patient, statuses: string[]) => {
  for (let i = 0; i < statuses.length; i++) {
    const status: string = statuses[i];
    if (patientModel.remoteMonitoringStatus === status) {
      return true;
    }
  }
  return false;
};

export const filterByReportCauses = (patient: Patient, ticketFilters: TicketSubTypeOption[]) => {
  const { unacknowledgedReports: reports } = patient;
  if (reports.length === 0) {
    return false;
  }

  return reports.some((report) => {
    const causeIds = report.answer.causes.map(({ causeId }: SeverityReportedCause) =>
      String(causeId)
    );
    const ticketTypeIds = ticketFilters.map(
      ({ value, parentKind }) => parentKind === TicketTypeKind.symptom && value
    );
    const causesInFilters = flow(intersection(ticketTypeIds))(causeIds);
    return Boolean(causesInFilters.length);
  });
};

export const isTicketInProviders = (ticket: Ticket, selectedProviders: string[]) => {
  if (ticket.isOperatorTicket) {
    return selectedProviders.includes(ticket.operatorTicket.providerId);
  }

  return true;
};

export const isPatientInProviders = (patient: Patient, selectedProviders: string[]) => {
  return selectedProviders.includes(patient.providerId);
};

export const isChildDepartment = (department: Department) => Boolean(department?.parentId);

// Clinician filters:

export interface CliniciansSupportedFilters {
  searchTerm?: string;
  credentialIds?: ISelectOption<number>[];
  userTypes?: ISelectOption<UserType>[];
  userStatuses?: ISelectOption<ClinicianUserStatus>[];
}

const checkBothOverdueFiltersOn = (filterKeys: Set<string>) => {
  const hasOverdue = filterKeys.has(TICKET_TYPE_OVERDUE_ID);
  const hasOralOverdue = filterKeys.has(TICKET_TYPE_ORAL_ONCO_OVERDUE_ID);
  return (hasOverdue && hasOralOverdue) || (!hasOverdue && !hasOralOverdue);
};

export const filterOverdue = (patient: Patient, filterKeys: Set<string>) => {
  const hasOverdue = filterKeys.has(TICKET_TYPE_OVERDUE_ID);
  if (!hasOverdue) {
    return true;
  }
  const bothOverdueOn = checkBothOverdueFiltersOn(filterKeys);
  return bothOverdueOn || patient.isSymptomReportOverdue;
};

export const filterOverdueOral = (patient: Patient, filterKeys: Set<string>) => {
  const hasOralOverdue = filterKeys.has(TICKET_TYPE_ORAL_ONCO_OVERDUE_ID);
  if (!hasOralOverdue) {
    return true;
  }
  const bothOverdueOn = checkBothOverdueFiltersOn(filterKeys);
  return bothOverdueOn || patient.isOralReportOverdue;
};
