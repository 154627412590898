import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { observer } from 'mobx-react';

import { useStores } from 'mobx/hooks/useStores';
import { TicketTypesStore } from 'mobx/stores';

import CallbackTicket, { CallbackType } from 'models/CallbackTicket';
import OperatorTicket, { OperatorTicketSource } from 'models/OperatorTicket';

import Patient from 'models/Patient';
import QuestionnaireAnswer, { ReportSource } from 'models/QuestionnaireAnswer';
import { ProtocolName } from 'models/ScheduledProtocol';
import Ticket from 'models/Ticket';

import { useClinicianFullNameWithCredentials } from 'hooks/useClinicianFullNameWithCredentials';

import { PatientContextProvider } from 'components/Patient/PatientContextProvider';
import { usePatientModel } from 'components/Patient/usePatientModel';

import { useTicketProviderLocation } from 'components/Ticket/TicketRow/shared';

// based on server's HandlebarsService.ts (patientTicketTypeForReport)
const getReportTitle = (patient: Patient, ticket: Ticket) => {
  const reports = patient.questionnairesAnswers.filter((report) => report.ticketId === ticket.id);
  if (reports && reports.length) {
    const oralReport = reports.find(
      (report) => report.type === ProtocolName.oralOncolytics.toLowerCase()
    );
    if (oralReport) {
      return `Oral Oncolytics Assessment (${oralReport.answer.oral.regimen}${
        oralReport.answer.oral.isCustom ? ' - Custom' : ''
      }) via ${getTicketSource(reports)}`;
    }
    return `Symptom Report via ${getTicketSource(reports)}`;
  }
  // Should throw or log an error here but leaving as is for now
  return '';
};

const getTicketSource = (reports: QuestionnaireAnswer[]): string => {
  const typesSet = new Set();
  for (const report of reports) {
    switch (report.source) {
      case ReportSource.Ivr:
        typesSet.add('Report Line');
        break;
      case ReportSource.Web:
        typesSet.add('Web');
        break;
      case ReportSource.Mobile:
        typesSet.add('App');
        break;
      default:
        throw new Error('Unknown report source');
    }
  }
  return Array.from(typesSet).join(' and ');
};
// based on server's HandlebarsService.ts (callbackRequestType)
const getCallbackRequestTitle = (callbackType: CallbackType) => {
  switch (callbackType) {
    case CallbackType.LearnMore:
    case CallbackType.DobMismatchWeb:
    case CallbackType.DobMismatchIvr:
      return 'Callback Request';
    case CallbackType.NoBirthdayDefined:
      return 'Invitation Error';
    default:
      return '';
  }
};

// based on server's HandlebarsService.ts (callbackRequestSource)
const getCallbackRequestCreator = (callbackType: CallbackType) => {
  switch (callbackType) {
    case CallbackType.LearnMore:
    case CallbackType.DobMismatchWeb:
      return 'Via Web';
    case CallbackType.DobMismatchIvr:
      return 'Via Report Line';
    case CallbackType.NoBirthdayDefined:
    default:
      return '';
  }
};

// based on server's HandlebarsService.ts and  OperatorTicketRow.tsx(getTitleText)
const getOperatorTicketTitle = (
  operatorTicket: OperatorTicket,
  ticketTypesStore: TicketTypesStore
): string => {
  if (operatorTicket.kind === 'other') {
    const [subTypeId] = operatorTicket.subTicketTypeIds;
    const subTypeNode = ticketTypesStore.getOperatorTicketTypeById(subTypeId);
    return subTypeNode?.fullName;
  }
  return 'Symptom Report via Triage Call';
};

const getOperatorTicketCreator = (operatorTicket: OperatorTicket, reportingDoctorName: string) => {
  if (operatorTicket?.source === OperatorTicketSource.Patient) {
    return 'Via App';
  }
  return `By ${reportingDoctorName}`;
};

// based on server's HandlebarsService.ts (operatorTicketTypeNotes)
const getOperatorTicketDescription = (
  operatorTicket: OperatorTicket,
  ticketTypesStore: TicketTypesStore
) => {
  const content = operatorTicket.notes;
  const hasPatientOperatorTicketSummaryPrefix =
    operatorTicket.source === OperatorTicketSource.Patient && Boolean(operatorTicket.notes);
  let prefix = '';

  if (operatorTicket.kind === 'symptom') {
    const { subTypes } = operatorTicket.getOperatorTicketTypes;
    const subTypesText = subTypes.map((type) => ticketTypesStore.getTicketType(type).fullName);
    prefix = `${subTypesText.join(', ')}${content ? ': ' : ''}`;
  } else if (hasPatientOperatorTicketSummaryPrefix) {
    prefix = 'Patient Request via App: ';
  }
  return `${prefix}${content}`;
};

// based on server's HandlebarsService.ts (callbackRequestDescription)
const getCallbackRequestDescription = (callbackTicket: CallbackTicket) => {
  switch (callbackTicket.callbackType) {
    case CallbackType.LearnMore:
      return `Patient indicated that they would like to learn more before consenting to report via web.`;
    case CallbackType.DobMismatchWeb:
      return `Patient was unable to authenticate their birthdate to submit a report via web. \n
      They indicated a correct date of ${callbackTicket.callbackDOB}.`;
    case CallbackType.DobMismatchIvr:
      return `Patient failed to enter their birthdate correctly to authenticate themselves on the Report Line. \nThey indicated a four-digit birthdate (mmdd) of ${callbackTicket.callbackDOB}.`;
    case CallbackType.NoBirthdayDefined:
      return 'Could not request a report from this patient, as they have no birthdate in the system. ';
    default:
      return '';
  }
};

const ConnectedTicket: FC<{ ticket: Ticket }> = ({ ticket }) => {
  const { ticketTypesStore } = useStores();
  const patient = usePatientModel(ticket.patientId);
  const providerAndLocation = useTicketProviderLocation(ticket);
  const reportingDoctorName = useClinicianFullNameWithCredentials(
    ticket.operatorTicket?.reportingDoctor.firstName,
    ticket.operatorTicket?.reportingDoctor.lastName,
    ticket.operatorTicket?.reportingDoctor.credentialId
  );
  let ticketTitle;
  let ticketCreator;
  let phoneNumber = patient.phone;
  let description = null;
  if (ticket.operatorTicket) {
    ticketTitle = getOperatorTicketTitle(ticket.operatorTicket, ticketTypesStore);
    ticketCreator = getOperatorTicketCreator(ticket.operatorTicket, reportingDoctorName);
    phoneNumber = ticket.operatorTicket.contactPhoneNumber;
    description = getOperatorTicketDescription(ticket.operatorTicket, ticketTypesStore);
  } else if (ticket.isCallbackRequestTicket) {
    ticketTitle = getCallbackRequestTitle(ticket.callbackTicket?.callbackType);
    ticketCreator = getCallbackRequestCreator(ticket.callbackTicket?.callbackType);
    description = getCallbackRequestDescription(ticket.callbackTicket);
  } else {
    ticketTitle = getReportTitle(patient, ticket);
  }

  return (
    <Box mb={20}>
      <Box mb={20}>
        <div>
          <Typography variant="body1">
            Ticket {ticket.id}: {ticketTitle}
          </Typography>
        </div>
        {ticketCreator && (
          <div>
            <Typography variant="body2">{ticketCreator}</Typography>
          </div>
        )}

        <div>
          <Typography variant="body2">{providerAndLocation}</Typography>
        </div>
        <div>
          <Typography variant="body2">
            Callback Contact: {ticket.operatorTicket?.contactName || 'Patient'}
          </Typography>
        </div>
        <div>
          <Typography variant="body2">Number: {phoneNumber}</Typography>
        </div>
        {ticket.operatorTicket?.patientContactId && (
          <div>
            <Typography variant="body2">
              HIPAA: {ticket.operatorTicket.contactHippaAuthDate ? 'Yes' : 'No'}
            </Typography>
          </div>
        )}
      </Box>
      {description && (
        <Box mb={20}>
          <div>
            <Typography variant="body1">Ticket Description: </Typography>
          </div>
          <div>
            <Typography variant="body2">{description}</Typography>
          </div>
        </Box>
      )}
    </Box>
  );
};

interface Props {
  connectedTickets: Ticket[];
}

const GeneratedConnectedTicketsView: FC<Props> = ({ connectedTickets }) => {
  return (
    <PatientContextProvider>
      {connectedTickets?.map((ticket) => (
        <ConnectedTicket ticket={ticket} key={ticket.id} />
      ))}
    </PatientContextProvider>
  );
};

export default observer(GeneratedConnectedTicketsView);
