import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import { ClinicianNotification } from 'components/NotificationCenter/types';

const httpService = new HttpService('Notifications', true);

interface GetNotificationsQuery {
  recordsPerPage: number;
  pageNumber: number;
  timestamp?: number;
}

interface UpdateNotificationsParams {
  seen?: number[];
  read?: number[];
}

interface GetNewNotificationsResponse {
  unseenNotifications: number;
}

export class NotificationsFetcher {
  static async getNotifications(query: GetNotificationsQuery) {
    return await httpService.get<ClinicianNotification[]>({
      url: API_URLS.NOTIFICATIONS,
      query,
      transformResponse: (notifications) => {
        return notifications.map((notification: ClinicianNotification) => ({
          ...notification,
          createdAt: new Date(notification.createdAt)
        }));
      }
    });
  }

  static async updateNotifications(params: UpdateNotificationsParams) {
    return await httpService.patch({
      url: API_URLS.NOTIFICATIONS,
      data: {
        seen: params.seen || [],
        read: params.read || []
      }
    });
  }

  static async getNewNotificationsNumber() {
    return await httpService.get<GetNewNotificationsResponse>({
      url: API_URLS.CHECK_NEW_NOTIFICATIONS
    });
  }
}
