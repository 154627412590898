// @ts-strict-ignore
import React from 'react';

import classNames from 'classnames';

import useEllipsis from 'mobx/customHooks/useEllipsis';

import Chip from 'components/Chips/Chip';
import { Tooltip } from 'components/Tooltip';

import './AlertTag.scss';

interface Props {
  text: string;
  className?: string;
  isVisible?: boolean;
  withSystemTooltip?: boolean;
}

const AlertTag: React.FC<Props> = ({ text, className, isVisible, withSystemTooltip = false }) => {
  const { isEllipsisActive, ref: tagRef } = useEllipsis(isVisible);

  const isTooltipDisabled = !isEllipsisActive;

  const classes = classNames('tag-item-container', {
    [className]: Boolean(className)
  });

  return (
    <>
      {withSystemTooltip ? (
        <div className="text-truncate">
          <Chip.Item bgColorHex="#FFC2B5" borderless className={classes}>
            <span
              ref={tagRef}
              data-test-hook="alert-tag"
              className="tag-item-truncate"
              title={isTooltipDisabled ? null : text}
            >
              Relevant Dx: {text}
            </span>
          </Chip.Item>
        </div>
      ) : (
        <Tooltip
          label={
            <Chip.Item bgColorHex="#FFC2B5" borderless className={classes}>
              <span ref={tagRef} className="tag-item-truncate" data-test-hook="alert-tag">
                Relevant Dx: {text}
              </span>
            </Chip.Item>
          }
          disabled={isTooltipDisabled}
          maxWidth={342}
        >
          <div className="tag-tooltip-container">{text}</div>
        </Tooltip>
      )}
    </>
  );
};

export default AlertTag;
