import { FC, useCallback, useEffect, useState } from 'react';

import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getNearestNodeOfType, mergeRegister, $findMatchingParent } from '@lexical/utils';
import { Box, css } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  LexicalNode
} from 'lexical';

import { ReactComponent as BoldIcon } from 'img/icons/bold.svg';
import { ReactComponent as ItalicIcon } from 'img/icons/italic.svg';
import { ReactComponent as OlIcon } from 'img/icons/ordered-list.svg';
import { ReactComponent as RedoIcon } from 'img/icons/redo.svg';
import { ReactComponent as UndoIcon } from 'img/icons/undo.svg';
import { ReactComponent as UlIcon } from 'img/icons/unordered-list.svg';

import { ToolbarPluginListeners } from 'components/UIkit/atoms/RichText/Plugins/ToolbarPlugin/ToolbarPlugin.types';

import {
  TOOLBAR_BUTTONS,
  TOOLBAR_CONTAINER
} from 'components/UIkit/atoms/RichText/RichText.constants';

interface Props {
  disabled?: boolean;
  listeners?: ToolbarPluginListeners;
}

export const ToolbarPlugin: FC<Props> = ({ disabled, listeners = {} }) => {
  const [editor] = useLexicalComposerContext();
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState<string>('');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const isUl = blockType === 'bullet';
  const isOl = blockType === 'number';

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();

      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e: LexicalNode) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
          const type = parentList ? parentList.getListType() : element.getListType();
          setBlockType(type);
        } else {
          const type = element.getType();
          setBlockType(type);
        }
      }
    }
  }, [editor]);

  useEffect(
    function registerToEditor() {
      return mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar();
          });
        }),
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          (_payload) => {
            updateToolbar();
            return false;
          },
          COMMAND_PRIORITY_CRITICAL
        ),
        editor.registerCommand(
          CAN_UNDO_COMMAND,
          (payload) => {
            setCanUndo(payload);
            return false;
          },
          COMMAND_PRIORITY_CRITICAL
        ),
        editor.registerCommand(
          CAN_REDO_COMMAND,
          (payload) => {
            setCanRedo(payload);
            return false;
          },
          COMMAND_PRIORITY_CRITICAL
        )
      );
    },
    [editor, updateToolbar]
  );

  return (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      exit={{ height: 0, opacity: 0 }}
      animate={{ height: 'auto', opacity: 1 }}
      transition={{ duration: 0.05, delay: 0.1 }}
      key="toolbar"
      id={TOOLBAR_CONTAINER}
    >
      <StyledToolbarBox display="flex" alignItems="center" gap={4} id={TOOLBAR_CONTAINER}>
        <StyledToolbarButton
          id={TOOLBAR_BUTTONS.UNDO}
          disabled={!canUndo || disabled}
          onClick={() => {
            listeners.onUndoClick && listeners.onUndoClick();
            editor.dispatchCommand(UNDO_COMMAND, undefined);
          }}
          aria-label="Undo"
        >
          <UndoIcon />
        </StyledToolbarButton>

        <StyledToolbarButton
          id={TOOLBAR_BUTTONS.REDO}
          disabled={!canRedo || disabled}
          onClick={() => {
            listeners.onRedoClick && listeners.onRedoClick();
            editor.dispatchCommand(REDO_COMMAND, undefined);
          }}
          aria-label="Redo"
        >
          <RedoIcon />
        </StyledToolbarButton>

        <ToolbarDivider />

        <StyledToolbarButton
          id={TOOLBAR_BUTTONS.BOLD}
          onClick={() => {
            listeners.onBoldClick && listeners.onBoldClick(!isBold);
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
          }}
          isActive={isBold}
          disabled={disabled}
          aria-label="Format Bold"
        >
          <BoldIcon />
        </StyledToolbarButton>

        <StyledToolbarButton
          id={TOOLBAR_BUTTONS.ITALIC}
          onClick={() => {
            listeners.onItalicClick && listeners.onItalicClick(!isBold);
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
          }}
          isActive={isItalic}
          disabled={disabled}
          aria-label="Format Italics"
        >
          <ItalicIcon />
        </StyledToolbarButton>

        <ToolbarDivider />

        <StyledToolbarButton
          id={TOOLBAR_BUTTONS.LIST}
          disabled={disabled}
          isActive={isUl}
          onClick={() => {
            listeners.onListClick && listeners.onListClick(!isUl);
            isUl
              ? editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
              : editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
          }}
        >
          <UlIcon />
        </StyledToolbarButton>

        <StyledToolbarButton
          id={TOOLBAR_BUTTONS.ORDERED_LIST}
          disabled={disabled}
          isActive={isOl}
          onClick={() => {
            listeners.onOrderedListClick && listeners.onOrderedListClick(!isOl);
            isOl
              ? editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
              : editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
          }}
        >
          <OlIcon />
        </StyledToolbarButton>
      </StyledToolbarBox>
    </motion.div>
  );
};

const StyledToolbarBox = styled(Box)(
  ({ theme }) => css`
    width: 100%;
    background: ${theme.palette.natural.contrast};
    padding: ${theme.spacing(2)};
    border-top-left-radius: ${theme.borderRadius.medium};
    border-top-right-radius: ${theme.borderRadius.medium};
  `
);

const StyledToolbarButton = styled('button', { shouldForwardProp: (prop) => prop !== 'isActive' })<{
  isActive?: boolean;
}>(
  ({ theme, isActive }) => css`
    border: 0;
    background: none;
    border-radius: ${theme.borderRadius.small};
    padding: ${theme.spacing(4)};
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    ${isActive &&
    css`
      background-color: ${theme.palette.natural.border};
    `}

    &:disabled {
      cursor: not-allowed;
    }

    &:hover:not([disabled]) {
      background-color: ${theme.palette.natural.hover};
    }

    svg {
      color: ${theme.palette.text.primary};
    }

    &:disabled svg {
      color: ${theme.palette.text.disabled};
    }
  `
);

const ToolbarDivider = styled('span')(
  ({ theme }) => css`
    width: 2px;
    height: 20px;
    background-color: ${theme.palette.natural.border};
    margin: 0 ${theme.spacing(2)};
  `
);
