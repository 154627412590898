// @ts-strict-ignore
import { useMemo } from 'react';

import { useStores } from 'mobx/hooks/useStores';

import { SettingsStore } from 'mobx/stores';

import {
  getGroupedTicketTypesForSelect,
  ITicketTypeSelectOptions,
  TicketSubTypeOption,
  TicketTypeFilterFn
} from 'utils/TicketType.utils';

import { FEATURES } from 'constants/features';

import {
  TICKET_TYPE_CALLBACK_ID,
  TICKET_TYPE_EPISODES_ID,
  TICKET_TYPE_NON_EPISODES_ID,
  TICKET_TYPE_ORAL_ONCO_OVERDUE_ID,
  TICKET_TYPE_ORAL_ONCO_REPORT_ID,
  TICKET_TYPE_OVERDUE_ID,
  TICKET_TYPE_SYMPTOM_ASSESSMENT_REPORT_ID
} from 'constants/itemTypes.const';

import OperatorTicket, {
  SymptomTicketUrgency,
  SymptomTicketUrgencyText,
  OperatorTicketUrgency,
  OperatorTicketUrgencyText
} from 'models/OperatorTicket';
import { PathwayOptionOutcome } from 'models/PathwayTemplates';

// get Ticket Types options
interface TicketTypesOptions {
  filterFn?: TicketTypeFilterFn;
  addCategoryAllOption?: boolean;
  addSpecialFilters?: boolean;
  addTicketTypesAndSubTypes?: boolean;
  showFullNameForNonSymptomTicketTypes?: boolean;
}

const ticketTypesOptionsDefault: TicketTypesOptions = {
  addCategoryAllOption: false,
  addSpecialFilters: false
};

// return true for active node OR included in operatorTicket types
export function useTypeActiveOrIncludedFilterFn(
  operatorTicket: OperatorTicket
): TicketTypeFilterFn {
  const { ticketTypesStore } = useStores();

  if (!operatorTicket) {
    return () => null;
  }

  const { ticketTypeId, subTicketTypeIds, kind } = operatorTicket;
  // collect ids which should pass filter
  const passTypeId = new Set<number>([ticketTypeId, ...subTicketTypeIds]);

  // make sure parentId is included (in case of custom subtype, ticketTypeId is category id)
  const [subTypeId] = subTicketTypeIds;
  const subTypeNode = ticketTypesStore.getTicketTypeByKind(subTypeId, kind);
  if (!passTypeId.has(subTypeNode.parent.id)) {
    passTypeId.add(subTypeNode.parent.id);
  }

  return (node) => node.isActive || (node.kind === kind && passTypeId.has(node.id));
}

const specialOptions: { label: string; value: string; feature: FEATURES }[] = [
  {
    label: 'Oral Oncolytics Assessment',
    value: TICKET_TYPE_ORAL_ONCO_REPORT_ID,
    feature: FEATURES.ORAL_ONCOLYTICS_ASSESSMENT
  },
  {
    label: 'Symptom Assessment',
    value: TICKET_TYPE_SYMPTOM_ASSESSMENT_REPORT_ID,
    feature: FEATURES.SYMPTOM_ASSESSMENT
  },
  {
    label: 'Callback Requests',
    value: TICKET_TYPE_CALLBACK_ID,
    feature: FEATURES.CALLBACK_REQUESTS
  },
  {
    label: 'Episode Tasks',
    value: TICKET_TYPE_EPISODES_ID,
    feature: FEATURES.EPISODE_TASKS
  },
  {
    label: 'Non-Episode Tasks',
    value: TICKET_TYPE_NON_EPISODES_ID,
    feature: FEATURES.NON_EPISODE_TASKS
  },
  {
    label: 'Overdue Symptom Assessment',
    value: TICKET_TYPE_OVERDUE_ID,
    feature: FEATURES.OVERDUE_SYMPTOM_ASSESSMENT
  },
  {
    label: 'Overdue Oral Oncolytics Assessment',
    value: TICKET_TYPE_ORAL_ONCO_OVERDUE_ID,
    feature: FEATURES.OVERDUE_ORAL_ONCOLYTICS_ASSESSMENT
  }
];

const getSpecialOptions = (settingsStore: SettingsStore): TicketSubTypeOption[] => {
  const options: TicketSubTypeOption[] = [];

  specialOptions.forEach((specialOption) => {
    if (settingsStore.hasFeature(specialOption.feature)) {
      options.push({
        label: specialOption.label,
        value: specialOption.value,
        parentId: null,
        parentName: null
      });
    }
  });

  return options;
};

export function useTicketTypesOptions(options?: TicketTypesOptions): ITicketTypeSelectOptions[] {
  const { settingsStore } = useStores();
  options = { ...ticketTypesOptionsDefault, ...options };
  const {
    filterFn,
    addCategoryAllOption,
    addSpecialFilters,
    addTicketTypesAndSubTypes = true,
    showFullNameForNonSymptomTicketTypes = false
  } = options;
  const { ticketTypesStore } = useStores();

  return useMemo(() => {
    let options: ITicketTypeSelectOptions[] = [];

    if (addTicketTypesAndSubTypes) {
      options = getGroupedTicketTypesForSelect(
        ticketTypesStore.ticketTypesTree,
        filterFn,
        showFullNameForNonSymptomTicketTypes
      );
      if (addCategoryAllOption) {
        options = options.map((ticketCategoryOption: ITicketTypeSelectOptions) => {
          const finalOptions = [...ticketCategoryOption.options];
          if (ticketCategoryOption.options.length > 1) {
            finalOptions.unshift({
              label: `All: ${ticketCategoryOption.label}`,
              parentId: null,
              parentName: null,
              value: `${ticketCategoryOption.id}`
            });
          }
          return {
            ...ticketCategoryOption,
            options: finalOptions
          };
        });
      }
    }

    if (addSpecialFilters) {
      const specialOptions = getSpecialOptions(settingsStore);

      options.unshift({
        id: -1,
        label: 'Item Categories',
        kind: null,
        options: specialOptions
      });
    }
    return options;
  }, [
    addCategoryAllOption,
    addSpecialFilters,
    addTicketTypesAndSubTypes,
    ticketTypesStore,
    settingsStore,
    filterFn,
    showFullNameForNonSymptomTicketTypes
  ]);
}

// get Locations options
export function useTicketLocationsOptions() {
  const { locationsStore } = useStores();
  return locationsStore.locationsForSelect;
}

// urgency options
export const urgencyOptions = [
  { value: OperatorTicketUrgency.Standard, label: OperatorTicketUrgencyText.Standard },
  { value: OperatorTicketUrgency.High, label: OperatorTicketUrgencyText.High }
];

export const symptomUrgencyOptions = [
  {
    value: SymptomTicketUrgency.NurseReview,
    label: SymptomTicketUrgencyText.NurseReview
  },
  {
    value: SymptomTicketUrgency.AttentionToday,
    label: SymptomTicketUrgencyText.AttentionToday
  },
  {
    value: SymptomTicketUrgency.ImmediateAttention,
    label: SymptomTicketUrgencyText.ImmediateAttention
  }
];

export const defaultNonSymptomUrgencyValue = {
  value: OperatorTicketUrgency.Standard,
  label: OperatorTicketUrgencyText.Standard
};

export const defaultSymptomUrgencyValue = {
  value: SymptomTicketUrgency.NurseReview,
  label: SymptomTicketUrgencyText.NurseReview
};

export const urgencyToQuestionOutcomeMap: Partial<
  Record<SymptomTicketUrgency, PathwayOptionOutcome>
> = {
  [SymptomTicketUrgency.NurseReview]: PathwayOptionOutcome.NONE,
  [SymptomTicketUrgency.AttentionToday]: PathwayOptionOutcome.CLINIC,
  [SymptomTicketUrgency.ImmediateAttention]: PathwayOptionOutcome.CLINIC_EMERGENT
};

export const questionOutcomeToUrgencyMap: Partial<
  Record<PathwayOptionOutcome, { value: SymptomTicketUrgency; label: SymptomTicketUrgencyText }>
> = {
  [PathwayOptionOutcome.NONE]: {
    value: SymptomTicketUrgency.NurseReview,
    label: SymptomTicketUrgencyText.NurseReview
  },
  [PathwayOptionOutcome.CLINIC]: {
    value: SymptomTicketUrgency.AttentionToday,
    label: SymptomTicketUrgencyText.AttentionToday
  },
  [PathwayOptionOutcome.CLINIC_EMERGENT]: {
    value: SymptomTicketUrgency.ImmediateAttention,
    label: SymptomTicketUrgencyText.ImmediateAttention
  }
};

export const getUrgencyDefaultValue = (isSymptom: boolean) =>
  isSymptom ? defaultSymptomUrgencyValue : defaultNonSymptomUrgencyValue;

export const getUrgencyOptions = (isSymptom: boolean) =>
  isSymptom ? symptomUrgencyOptions : urgencyOptions;

export const ticketTypesSelectCustomFilter = (option: any, searchText: string) => {
  const searchValue = searchText.toLowerCase();
  return (
    option.label.toLowerCase().includes(searchValue) ||
    (Boolean(option.data.parentId) && option.data.parentName.toLowerCase().includes(searchValue))
  );
};
