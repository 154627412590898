import { FC, useEffect } from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';

import { API_URLS } from 'constants/apiUrls';

import Ticket from 'models/Ticket';

import TicketComments from './TicketComments';
import TicketContact from './TicketContact';

import './TicketCommentsAndContact.scss';

interface IProps {
  ticket: Ticket;
  showContact?: boolean;
}

interface LazyCommentsProps {
  ticket: Ticket;
}

const LazyComments: FC<LazyCommentsProps> = observer(({ ticket }) => {
  const { ticketsStore } = useStores();
  const isLoading = useNetworkLoading(API_URLS.GET_COMMENTS(ticket.id, 'ticket'));

  useEffect(
    function fetchTicketComments() {
      ticketsStore.fetchCommentsForTicket(ticket);
    },
    [ticketsStore, ticket]
  );

  return (
    <>
      {isLoading && <span>Loading comments...</span>}
      <TicketComments ticket={ticket} comments={ticket.comments} />
    </>
  );
});

const TicketCommentsAndContact: FC<IProps> = ({ ticket, showContact = true }) => {
  const classes = classNames('tickets-comments-and-contact d-flex flex-column', {
    'comments-only': !showContact
  });

  return (
    <div className={classes}>
      {showContact && <TicketContact ticket={ticket} />}
      <LazyComments ticket={ticket} />
    </div>
  );
};

export default TicketCommentsAndContact;
