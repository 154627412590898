import { observable, makeObservable } from 'mobx';

import Patient from 'models/Patient';

import QuestionnaireAnswer from 'models/QuestionnaireAnswer';

export default abstract class ClinicianAlertCondition {
  @observable type: number;

  protected constructor(type: number) {
    makeObservable(this);
    this.type = type;
  }

  abstract getConditionsString(): string;

  abstract isMet(report: QuestionnaireAnswer, patient?: Patient): boolean;

  static getSeverityString = (severity: number) => {
    switch (severity) {
      case 1:
        return 'Mild';
      case 2:
        return 'Moderate';
      case 3:
        return 'Severe';
      case 4:
        return 'Unbearable';
      default:
        throw new Error(`no severity level ${severity}`);
    }
  };

  compare = (condition: ClinicianAlertCondition) => {
    return condition.type === this.type && condition.getId() === this.getId();
  };
  abstract setBottomThreshold(threshold: number): void;

  abstract getBottomThreshold(): number;

  abstract getId(): number;
}
