"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.developmentPublicUrls = void 0;
exports.developmentPublicUrls = {
    portal: "localhost:8080",
    portalCanary: "localhost:8081",
    login: "localhost:8082",
    api: "localhost:3000",
    apiCanary: "localhost:3001",
    loginApi: "localhost:3002",
};
