import { FC } from 'react';

import { Typography } from '@mui/material';

import { getBadgeForTicket } from 'utils/TicketBadgeUtils';

import { Notification } from 'components/NotificationCenter/Notification';

import {
  ClinicianAssignNotification,
  ClinicianNotification
} from 'components/NotificationCenter/types';
import {
  getNotificationSummary,
  getNotificationTicketClassText
} from 'components/NotificationCenter/utils';

interface Props {
  notification: ClinicianAssignNotification;
  onClick: (notification: ClinicianNotification) => void;
}

export const AssignNotification: FC<Props> = ({ notification, onClick }) => {
  const { changeCreatedBy, patient, ticket, targetDoctor } = notification.notificationDetails;

  const getNotificationTitle = () => {
    if (targetDoctor) {
      return (
        <>
          <Typography variant="body1">
            {changeCreatedBy?.firstName} {changeCreatedBy?.lastName}
          </Typography>{' '}
          reassigned a {ticketOrTask} for{' '}
          <Typography variant="body1">
            {patient?.firstName} {patient?.lastName}
          </Typography>{' '}
          from you to{' '}
          <Typography variant="body1">
            {targetDoctor?.firstName} {targetDoctor?.lastName}
          </Typography>{' '}
        </>
      );
    }

    return (
      <>
        <Typography variant="body1">
          {changeCreatedBy?.firstName} {changeCreatedBy?.lastName}
        </Typography>{' '}
        assigned a {ticketOrTask} to you for{' '}
        <Typography variant="body1">
          {patient?.firstName} {patient?.lastName}
        </Typography>
      </>
    );
  };

  const ticketOrTask = getNotificationTicketClassText(ticket);
  const summary = getNotificationSummary(ticket);
  const badge = getBadgeForTicket(ticket.ticketClass, ticket.ticketKind, ticket.urgency);

  return (
    <Notification
      notification={notification}
      onClick={onClick}
      summary={summary}
      badge={badge}
      title={getNotificationTitle()}
    />
  );
};
