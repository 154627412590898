// @ts-strict-ignore
import { ConditionType } from 'models/ClinicianAlert';
import QuestionnaireAnswer from 'models/QuestionnaireAnswer';

import ClinicianAlertCondition from './ClinicianAlertCondition';

export default class AnyCauseCondition extends ClinicianAlertCondition {
  private minSeverity: number;

  constructor(rawCondition: any) {
    super(ConditionType.AnyCause);
    this.minSeverity = rawCondition.minSeverity;
  }

  getConditionsString(): string {
    return `Any cause at ${ClinicianAlertCondition.getSeverityString(this.minSeverity)} or greater`;
  }

  isMet(report: QuestionnaireAnswer): boolean {
    return (
      report.answer.causes &&
      report.answer.causes.some((cause: any) => cause.severity >= this.minSeverity)
    );
  }

  getBottomThreshold(): number {
    return this.minSeverity;
  }

  setBottomThreshold(threshold: number): void {
    this.minSeverity = threshold;
  }

  getId(): number {
    return null;
  }
}
