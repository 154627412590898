// @ts-strict-ignore

import { FC, useCallback, useEffect, useMemo } from 'react';

import { Grid } from '@mui/material';
import { AnalyticEventAction } from 'analytics';
import { Dialogs } from 'analytics/events/dialog';
import { trackEditConnectedTicketsModalAnalyticsEvent } from 'analytics/events/edit-connected-tickets-modal';

import { flatten } from 'lodash/fp';
import { observer } from 'mobx-react';

import { FormProvider } from 'react-hook-form';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';

import { TicketSubTypeOption } from 'utils/TicketType.utils';

import { API_URLS } from 'constants/apiUrls';

import Call from 'models/Call';
import CallReason, { CallTopicDiscussed } from 'models/CallReason';
import Patient from 'models/Patient';
import Ticket from 'models/Ticket';
import { TicketTypeKind } from 'models/TicketTypes';

import { useToggle } from 'hooks/useToggle';

import {
  callReasonOptionsToValues,
  CallReasonSelectionFields,
  CallReasonsFields,
  useCallReasonFields
} from 'views/Widgets/CallIReasonsFields';
import {
  ConnectTicketToCalls,
  TicketsCallConnectModalWrapper,
  useConnectTicketToCallsState
} from 'views/Widgets/ConnectTicketToCalls';

import FixedLoader from 'components/Loaders/FixedLoader';
import { useTicketTypesOptions } from 'components/Ticket/TicketForms/TicketsInfoFormFragments/OperatorTicketsInfoFormCommon';
import { MessageDialog } from 'components/UIkit/atoms/Dialog';

import './ConnectTicketsToCallModal.scss';

export interface ConnectTicketsToCallModalProps {
  call: Partial<Call> | Call;
  patient: Patient;
  isOpen: boolean;
  onSave?: (
    ticketIds: number[],
    callReasons: CallReason[],
    topicsDiscussed: CallTopicDiscussed[],
    shouldResolveTickets: boolean
  ) => void;
  onClose: () => void;
  virtualPage?: 'call logger';
}
const ConnectTicketsToCallModal: FC<ConnectTicketsToCallModalProps> = ({
  call,
  patient,
  isOpen,
  onSave,
  onClose,
  virtualPage
}) => {
  const { ticketsStore } = useStores();
  const ticketToCallsState = useConnectTicketToCallsState(call, patient, isOpen);
  const {
    isOpen: isResolveWarningOpen,
    toggle: toggleResolveWarning,
    setIsOpen: setResolveWarningOpen
  } = useToggle(false);

  useEffect(
    function sendAnalyticsEventOnOpen() {
      if (isOpen) {
        trackEditConnectedTicketsModalAnalyticsEvent({
          action: AnalyticEventAction.Open,
          virtual_page: virtualPage
        });
      }
    },
    [isOpen, virtualPage]
  );

  const topicOptions = useTicketTypesOptions({
    // do not show deleted types which no are not used by any ticket
    filterFn: (node) => !node.isDeleted || node.hasActiveTickets
  });
  const flattenedTopicOptions = useMemo(
    () => flatten(topicOptions.map((ticketTypeOption) => ticketTypeOption.options)),
    [topicOptions]
  );

  const getSubTypeOption = useCallback(
    (subTypeId: number) => {
      return flattenedTopicOptions.find(
        (option) =>
          option.value === subTypeId.toString() && option.parentKind === TicketTypeKind.other
      );
    },
    [flattenedTopicOptions]
  );
  const initialTopics = useMemo((): TicketSubTypeOption[] => {
    const initialTopicIds = call.topicsDiscussed.map((topic) => parseInt(topic.id, 10));
    const subTypeIdsSet = new Set<number>(initialTopicIds);
    call.ticketIds.forEach((ticketId) => {
      const ticket = ticketsStore.ticketsMap.get(ticketId);
      if (ticket?.isGeneralOperatorTicket) {
        const subTypeId = ticket.operatorTicket.subTicketTypeIds[0];

        if (!subTypeIdsSet.has(subTypeId)) {
          subTypeIdsSet.add(subTypeId);
        }
      }
    });
    return Array.from(subTypeIdsSet).map(getSubTypeOption);
  }, [call.topicsDiscussed, call.ticketIds, getSubTypeOption, ticketsStore.ticketsMap]);

  const { callReasonFieldsMethods, defaultValues } = useCallReasonFields(patient, {
    callReasons: call.callReasons,
    topics: initialTopics
  });

  const handleSubmit = (values: CallReasonSelectionFields, shouldResolve: boolean) => {
    const { callReasons, topicsDiscussed } = callReasonOptionsToValues(values);
    onSave(ticketToCallsState.callTicketIds, callReasons, topicsDiscussed, shouldResolve);
  };

  const closeModal = () => {
    trackEditConnectedTicketsModalAnalyticsEvent({
      action: AnalyticEventAction.Cancel,
      virtual_page: virtualPage
    });
    onClose();
  };

  const isSaveInProgress = useNetworkLoading([
    API_URLS.SAVE_PATIENT_CALL(patient.id),
    API_URLS.UPDATE_SAVED_CALL(call.id)
  ]);

  const connectTicketToCall = (ticketId: number) => {
    onConnectedTicketChange(ticketId, 'add');
    ticketToCallsState.connectTicketToCall(ticketId);
  };

  const disconnectTicketFromCall = (ticketId: number) => {
    onConnectedTicketChange(ticketId, 'remove');
    ticketToCallsState.disconnectTicketFromCall(ticketId);
  };

  const onConnectedTicketChange = (ticketId: number, action: 'add' | 'remove') => {
    const ticket = ticketsStore.ticketsMap.get(ticketId);

    if (!ticket?.isGeneralOperatorTicket) {
      return;
    }
    const handlers = {
      add: addTopicByTicket,
      remove: removeTopicByTicket
    };
    handlers[action](ticket);
  };

  const countTopicsOfKind = (subtypeId: number) => {
    return Array.from(ticketToCallsState.connectedTicketIds).reduce((currCount, ticketId) => {
      const ticket = ticketsStore.ticketsMap.get(ticketId);
      if (
        ticket?.operatorTicket?.subTicketTypeIds[0] === subtypeId &&
        ticket.isGeneralOperatorTicket
      ) {
        return currCount + 1;
      }
      return currCount;
    }, 0);
  };

  const addTopicByTicket = (ticket: Ticket) => {
    const subTypeId = ticket.operatorTicket.subTicketTypeIds[0];
    if (countTopicsOfKind(subTypeId) === 0) {
      const currentTopics = callReasonFieldsMethods.getValues().topicsDiscussed || [];
      const newOption = getSubTypeOption(subTypeId);
      callReasonFieldsMethods.setValue('topicsDiscussed', [...currentTopics, newOption]);
    }
  };

  const removeTopicByTicket = (ticket: Ticket) => {
    const subTypeId = ticket.operatorTicket.subTicketTypeIds[0];
    if (countTopicsOfKind(subTypeId) === 1) {
      const currentTopics = callReasonFieldsMethods.getValues().topicsDiscussed || [];
      callReasonFieldsMethods.setValue(
        'topicsDiscussed',
        currentTopics.filter((option) => option.value !== subTypeId.toString())
      );
    }
  };

  const connectTicketsProps = {
    ...ticketToCallsState,
    disconnectTicketFromCall,
    connectTicketToCall
  };

  const onSaveClick = (shouldResolve: boolean) => {
    return callReasonFieldsMethods.handleSubmit(
      (fields: CallReasonSelectionFields) => {
        if (shouldResolve) {
          trackEditConnectedTicketsModalAnalyticsEvent({
            action: AnalyticEventAction.SaveAndResolve,
            virtual_page: virtualPage
          });
          return toggleResolveWarning();
        }
        trackEditConnectedTicketsModalAnalyticsEvent({
          action: AnalyticEventAction.Save,
          virtual_page: virtualPage
        });
        handleSubmit(fields, shouldResolve);
      },
      () =>
        trackEditConnectedTicketsModalAnalyticsEvent({
          action: shouldResolve ? AnalyticEventAction.SaveAndResolve : AnalyticEventAction.Save,
          virtual_page: virtualPage,
          value: 'missing fields'
        })
    )();
  };

  const submitAndResolveTickets = () => {
    setResolveWarningOpen(false);
    handleSubmit(callReasonFieldsMethods.getValues(), true);
  };

  return (
    <>
      <TicketsCallConnectModalWrapper
        isLoading={isSaveInProgress}
        isOpen={isOpen}
        onSave={onSaveClick}
        onClose={closeModal}
        showResolveButton={ticketToCallsState.callTicketIds.length > 0}
        resetDataAfterClose={ticketToCallsState.resetConnectedTickets}
        methods={callReasonFieldsMethods}
        defaultValues={defaultValues}
      >
        <MessageDialog
          id={Dialogs.ResolveConnectedTickets}
          isOpen={isResolveWarningOpen}
          handleClose={() => setResolveWarningOpen(false)}
          title="Resolve Connected Tickets?"
          primaryActionProps={{ text: 'Resolve Tickets', onClick: submitAndResolveTickets }}
          secondaryActionProps={{ text: 'Cancel', onClick: () => setResolveWarningOpen(false) }}
        >
          Are you sure you want to resolve the tickets connected to this call?
        </MessageDialog>

        <Grid container direction="column" flexWrap="nowrap" height="100%">
          <div className="connect-tickets-to-calls-container">
            <ConnectTicketToCalls {...connectTicketsProps} />
          </div>

          {ticketToCallsState.isLoading && <FixedLoader background />}

          <FormProvider {...callReasonFieldsMethods}>
            <CallReasonsFields openFormMenusUp patient={patient} defaultValues={defaultValues} />
          </FormProvider>
        </Grid>
      </TicketsCallConnectModalWrapper>
    </>
  );
};

export default observer(ConnectTicketsToCallModal);
