// @ts-strict-ignore
import { ConditionType } from 'models/ClinicianAlert';
import QuestionnaireAnswer from 'models/QuestionnaireAnswer';

import ClinicianAlertCondition from './ClinicianAlertCondition';

export default class DistressCondition extends ClinicianAlertCondition {
  private minSeverity: number;

  constructor(rawCondition: any) {
    super(ConditionType.Distress);
    this.minSeverity = rawCondition.minSeverity;
  }

  getConditionsString(): string {
    return `Distress level over ${this.minSeverity}`;
  }

  isMet(report: QuestionnaireAnswer): boolean {
    return report.answer.distressLevel && report.answer.distressLevel >= this.minSeverity;
  }

  getBottomThreshold(): number {
    return this.minSeverity;
  }

  setBottomThreshold(threshold: number): void {
    this.minSeverity = threshold;
  }

  getId(): number {
    return null;
  }
}
