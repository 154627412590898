import { ChangeEvent, FC } from 'react';

import { Input } from 'reactstrap';

import { Modal } from 'components/UIkit/atoms/Modal';
import { Text } from 'components/UIkit/atoms/Text';

import './SendSmsPersonalRequest.scss';

const MAX_CHARS = 500;

interface Props {
  onSendClicked: () => void;
  onCancelClicked: () => void;
  isOpen: boolean;
  isSending: boolean;
  title: string;
  subtitle?: string;
  hideMaxChars?: boolean;
  onMessageChanged: (message: string) => void;
  message: string;
}

const SendSmsPersonalRequest: FC<Props> = ({
  onSendClicked,
  onCancelClicked,
  isOpen,
  isSending,
  title,
  hideMaxChars,
  onMessageChanged,
  message
}) => (
  <Modal
    isOpen={isOpen}
    title={title}
    confirmActions={[
      {
        onClick: onSendClicked,
        disabled: isSending,
        text: isSending ? 'Sending…' : 'Send',
        testHook: 'send-sms-reminder-submit-button'
      }
    ]}
    closeAction={{ onClick: onCancelClicked, disabled: false }}
    testHook="sms-personal-request-modal"
  >
    <div className="send-text-modal">
      <div className="send-text-modal-header">
        <Text variant="body2" mb={12} color="error" component="div">
          Not HIPAA compliant, do not include PHI.
        </Text>
        <div className="input-wrapper">
          <Input
            type="textarea"
            className="message-content"
            name="message"
            value={message}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (!hideMaxChars && e.target.value.length > MAX_CHARS) {
                return;
              }
              onMessageChanged(e.target.value);
            }}
            data-test-hook="smsReminderTextarea"
          />

          {!hideMaxChars && (
            <div className={`char-count ${message.length === MAX_CHARS ? 'text-danger' : ''}`}>
              {`${message.length} / ${MAX_CHARS}`}
            </div>
          )}
        </div>
      </div>
    </div>
  </Modal>
);

export default SendSmsPersonalRequest;
