import { FC } from 'react';

import { TicketSubTypeOption } from 'utils/TicketType.utils';

import { BaseSelect } from 'components/UIkit/atoms/Dropdown/BaseSelect';

import {
  InlineLabelComponents,
  TicketTypeCustomControl,
  TicketTypeMultiValueLabel,
  TicketTypeMultiValueRemove,
  TicketTypeSingleValueLabel,
  ValueContainer
} from 'components/UIkit/atoms/Dropdown/Select/ReactSelectComponents';
import {
  BaseSelectProps,
  ReactSelectInternalProps,
  SelectComponents
} from 'components/UIkit/atoms/Dropdown/Select.shared';

import { FormProps } from 'components/UIkit/atoms/Dropdown/withForm.shared';
import { withFormSelect } from 'components/UIkit/atoms/Dropdown/withFormSelect';

// === standard (i.e. Not form) components ===//

function getComponents(displaySelectedSummary: boolean): SelectComponents {
  const components: SelectComponents = {};
  if (displaySelectedSummary) {
    // show summary of selected items (ex: "3 Selected")
    components.ValueContainer = ValueContainer;
  }
  return components;
}

export const Autocomplete: FC<AutocompleteProps> = (props) => <BaseSelect {...props} />;

export interface AutocompleteProps extends BaseSelectProps {
  displaySelectedSummary?: boolean;
  components?: SelectComponents;
  blurInputOnSelect?: boolean;
}

export interface CareManagementMultiAutocompleteProps extends AutocompleteProps {
  selectedTableRowsCount: number;
}

export const MultiAutocomplete: FC<AutocompleteProps> = ({
  displaySelectedSummary = true,
  components = {},
  ...props
}) => (
  <BaseSelect
    isMulti
    components={{ ...getComponents(displaySelectedSummary), ...components }}
    {...props}
  />
);

// === form components ===//
// since react-form-hook is setting the value, it can be omitted
interface FormBaseSelectProps extends Omit<BaseSelectProps, 'value'>, FormProps {}

const FormBaseSelect = withFormSelect<FormBaseSelectProps & ReactSelectInternalProps>(BaseSelect);

export const FormAutocomplete: FC<FormBaseSelectProps> = (props) => <FormBaseSelect {...props} />;

// remove "export" when views/Widgets/StyledSelect.tsx removed
// multi select is not editable. to support editable multi select, syncCurrentOption should be updated in withFormSelect HOC
interface FormBaseSelectPropsNonEditable extends Omit<FormBaseSelectProps, 'onEditClick'> {
  isSub?: boolean;
}

interface FormMultiAutocompleteProps extends FormBaseSelectPropsNonEditable {
  displaySelectedSummary?: boolean;
}

export const FormMultiAutocomplete: FC<FormMultiAutocompleteProps> = ({
  displaySelectedSummary = true,
  ...props
}) => <FormBaseSelect isMulti components={getComponents(displaySelectedSummary)} {...props} />;

// === ticket type components ===//
const ticketTypeProps = {
  // use parentId + id for uniqueness, because some subtypes have the same ID
  getOptionValue: (option: TicketSubTypeOption) => `${option.parentId}_${option.value}`
};
// removing getOptionValue, as this prop treated here internally
interface TicketTypeProps extends Omit<BaseSelectProps, 'getOptionValue'> {}
interface FormTicketTypeProps extends Omit<FormBaseSelectPropsNonEditable, 'getOptionValue'> {}

export const TicketTypeMultiAutocomplete: FC<TicketTypeProps> = ({ ...props }) => {
  return <MultiAutocomplete {...ticketTypeProps} {...props} />;
};

export const FormTicketTypeAutocomplete: FC<FormTicketTypeProps> = (props) => {
  const components: SelectComponents = {
    Control: TicketTypeCustomControl,
    SingleValue: TicketTypeSingleValueLabel
  };

  return <FormBaseSelect {...ticketTypeProps} components={components} {...props} />;
};

export interface FormTicketTypeAutocompleteProps extends FormTicketTypeProps {
  warnOnRemove?: boolean;
  noBorderRadiusBottom?: boolean;
  isSub?: boolean;
}

export const FormTicketTypeMultiAutocomplete: FC<FormTicketTypeAutocompleteProps> = ({
  warnOnRemove = false,
  noBorderRadiusBottom,
  ...props
}) => {
  const components: SelectComponents = { MultiValueLabel: TicketTypeMultiValueLabel };
  if (warnOnRemove) {
    // show "are you sure you want to remove" popup
    components.MultiValueRemove = TicketTypeMultiValueRemove;
  }

  if (noBorderRadiusBottom) {
    components.Control = TicketTypeCustomControl;
  }

  return <FormBaseSelect isMulti {...ticketTypeProps} components={components} {...props} />;
};

// === to be removed after ticket type re-design ===//
export const FormAutocompleteInlineLabel: FC<FormBaseSelectProps> = (props) => (
  <FormBaseSelect {...props} components={InlineLabelComponents} />
);
