import { FC, useState } from 'react';

import ReactDOM from 'react-dom';

import { OptionProps } from 'react-select';

import { ClearCmTableSelectionPopup } from 'views/Modals/ClearCmTableSelectionPopup';

import { DefaultMenuOption } from 'components/UIkit/atoms/Dropdown/Select/ReactSelectOptionComponent';

interface CmOptionProps extends OptionProps {
  selectedTableRowsCount: number;
}

export const CmFilterOption: FC<CmOptionProps> = (props) => {
  const [showWarning, setShowWarning] = useState<boolean>(false);

  return (
    <div onClick={(event) => event.stopPropagation()}>
      {ReactDOM.createPortal(
        <ClearCmTableSelectionPopup
          isOpen={showWarning}
          onClose={() => setShowWarning(false)}
          onSave={props.innerProps.onClick as any}
          selectedTableRowsCount={props.selectedTableRowsCount}
        />,
        document.body
      )}

      <DefaultMenuOption
        {...props}
        innerProps={{
          ...props.innerProps,
          onClick: () => setShowWarning(true)
        }}
      >
        {props.children}
      </DefaultMenuOption>
    </div>
  );
};
