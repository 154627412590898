import { FC } from 'react';

import { WqSectionTriggerProps } from 'views/WorkQueue/WqSectionTrigger';

import { Section } from 'components/Ticket/TicketsContainers/TicketsContainers.constants';

export interface Props extends Omit<WqSectionTriggerProps, 'sectionName'> {
  totalPatients: number;
}

export const WqTasksSectionTrigger: FC<Props> = ({ totalItems, totalPatients }) => (
  <div className="report-section-header" data-test-hook={`${Section.TasksDue}_header`}>
    {Section.TasksDue} ({totalItems} Tasks • {totalPatients} Patients)
  </div>
);
