// @ts-strict-ignore
import { action, computed, observable, runInAction, makeObservable } from 'mobx';

import { computedFn } from 'mobx-utils';

import { DataMap } from 'mobx/stores/DataMap';

import { LocationsFetcher } from 'fetchers/LocationsFetcher';

import { extractIdsFromItems } from 'utils/helpers';

import PatientLocation from 'models/PatientLocation';

import { ISelectOption } from 'views/Widgets/StyledSelect';

import { RootStore } from './rootStore';

export const SELECTED_LOCATIONS_STORAGE_KEY = '_selectedLocations';

export default class LocationsStore {
  @observable
  locations: DataMap<PatientLocation> = new DataMap<PatientLocation>();

  @observable
  selectedLocationIds: Set<number> = new Set();

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  async fetchPatientLocations() {
    const fetchedLocations = await LocationsFetcher.getPatientLocations();
    runInAction(() => {
      this.locations.setItems(fetchedLocations);
      this.setSelectedLocationsAfterFetch();
    });
  }

  @action
  async updateLocation(updatedLocation: PatientLocation) {
    await LocationsFetcher.updateLocation(updatedLocation);
    runInAction(() => {
      this.locations.set(updatedLocation.id, updatedLocation);
    });
  }

  setSelectedLocationsAfterFetch = () => {
    const localStorageLocationIds = JSON.parse(
      localStorage.getItem(SELECTED_LOCATIONS_STORAGE_KEY)
    );
    if (localStorageLocationIds && localStorageLocationIds.length > 0) {
      this.setSelectedLocationIds(localStorageLocationIds);
    } else {
      this.setSelectedLocationIds(extractIdsFromItems(this.locations.items));
    }
  };

  @action
  setSelectedLocationIds(selectedLocationIds: number[]) {
    this.selectedLocationIds = new Set(selectedLocationIds);
  }

  @action
  addSelectedLocationId(selectedLocationId: number) {
    this.selectedLocationIds.add(selectedLocationId);
  }

  @action
  removeSelectedLocationId(selectedLocationId: number) {
    this.selectedLocationIds.delete(selectedLocationId);
  }

  @computed
  get visibleLocations() {
    return this.locations.items.filter((location) => location.visible);
  }

  getLocationById = computedFn((locationId: string | number): PatientLocation => {
    return this.locations.get(locationId);
  });

  @computed
  get locationsForSelect(): ISelectOption<PatientLocation>[] {
    return this.visibleLocations.map((location: PatientLocation) => ({
      value: location,
      label: location.name
    }));
  }
}
