// @ts-strict-ignore
import { computed, observable, makeObservable } from 'mobx';

import { PopulationType } from 'models/ClinicianAlert';

export enum AlertTargetPopulationName {
  AllPatients = 'All Patients',
  MyPatients = 'My Patients',
  SpecificPatients = 'Specific Patients',
  SpecificCareTeams = 'Specific Care Teams',
  PatientsWithDiagnosis = 'Patients With Diagnosis',
  PatientsWithSpecificDrug = 'Patients With Specific Drug'
}

export default class AlertTargetPopulation {
  @observable type: PopulationType;
  @observable patients: { patientId: number; firstName: string; lastName: string }[];
  @observable careTeamIds: number[];
  @observable patientIds: number[];

  constructor() {
    makeObservable(this);
  }

  @computed
  get name(): AlertTargetPopulationName {
    switch (this.type) {
      case PopulationType.All:
        return AlertTargetPopulationName.AllPatients;
      case PopulationType.MyPatients:
        return AlertTargetPopulationName.MyPatients;
      case PopulationType.SpecificPatients:
        return AlertTargetPopulationName.SpecificPatients;
      case PopulationType.SpecificCareTeams:
        return AlertTargetPopulationName.SpecificCareTeams;
      case PopulationType.PatientsWithDiagnosis:
        return AlertTargetPopulationName.PatientsWithDiagnosis;
      case PopulationType.PatientsWithSpecificDrug:
        return AlertTargetPopulationName.PatientsWithSpecificDrug;
      default:
        throw new Error(` no population type ${this.type}`);
    }
  }
}
