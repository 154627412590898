import { getDefaultDatesRequestParams } from 'utils/serverFiltersUtils';

import {
  WorkQueueRequestFilters,
  WorkQueueSectionFilters,
  WorkQueueSectionName,
  WorkQueueSections,
  WorkQueueSectionStatusData
} from 'views/WorkQueue/WorkQueue.types';

import { Section } from 'components/Ticket/TicketsContainers/TicketsContainers.constants';

export const defaultWqSectionDataMap: WorkQueueSections = {
  [WorkQueueSectionName.AssignedToMe]: { totalItems: 0, totalPages: 0, items: [], pageNumber: 0 },
  [WorkQueueSectionName.UrgentPatientReports]: {
    totalItems: 0,
    totalPages: 0,
    items: [],
    pageNumber: 0
  },
  [WorkQueueSectionName.TicketsAndCallbackRequests]: {
    totalItems: 0,
    totalPages: 0,
    items: [],
    pageNumber: 0
  },
  [WorkQueueSectionName.OtherPatientReports]: {
    totalItems: 0,
    totalPages: 0,
    items: [],
    pageNumber: 0
  },
  [WorkQueueSectionName.TasksDue]: {
    totalItems: 0,
    totalPages: 0,
    items: [],
    totalPatients: 0,
    pageNumber: 0
  },
  [WorkQueueSectionName.OverdueReports]: { totalItems: 0, totalPages: 0, items: [], pageNumber: 0 }
};

export const defaultWqSectionCurrentPageMap: Record<WorkQueueSectionName, number> = {
  [WorkQueueSectionName.AssignedToMe]: 0,
  [WorkQueueSectionName.UrgentPatientReports]: 0,
  [WorkQueueSectionName.OtherPatientReports]: 0,
  [WorkQueueSectionName.TasksDue]: 0,
  [WorkQueueSectionName.TicketsAndCallbackRequests]: 0,
  [WorkQueueSectionName.OverdueReports]: 0
};

export const defaultWqSectionStatusData: WorkQueueSectionStatusData = {
  newItemsCount: 0,
  maxUrgency: null
};

export const defaultWqSectionStatusDataMap: Record<
  WorkQueueSectionName,
  WorkQueueSectionStatusData
> = {
  [WorkQueueSectionName.AssignedToMe]: defaultWqSectionStatusData,
  [WorkQueueSectionName.UrgentPatientReports]: defaultWqSectionStatusData,
  [WorkQueueSectionName.OtherPatientReports]: defaultWqSectionStatusData,
  [WorkQueueSectionName.TasksDue]: defaultWqSectionStatusData,
  [WorkQueueSectionName.TicketsAndCallbackRequests]: defaultWqSectionStatusData,
  [WorkQueueSectionName.OverdueReports]: defaultWqSectionStatusData
};

export const defaultWqFiltersQuery: WorkQueueRequestFilters = {
  fromDate: getDefaultDatesRequestParams().fromDate
};

export const WQ_EXPAND_KEY = 'WQ_EXPAND_STATE';

export const allWqSectionNames: WorkQueueSectionName[] = [
  WorkQueueSectionName.AssignedToMe,
  WorkQueueSectionName.UrgentPatientReports,
  WorkQueueSectionName.TicketsAndCallbackRequests,
  WorkQueueSectionName.OtherPatientReports,
  WorkQueueSectionName.TasksDue,
  WorkQueueSectionName.OverdueReports
];

export const defaultWqSectionFiltersMap: Record<WorkQueueSectionName, WorkQueueSectionFilters> = {
  [WorkQueueSectionName.AssignedToMe]: {
    lastRetrievedTimestamp: null
  },
  [WorkQueueSectionName.UrgentPatientReports]: {
    lastRetrievedTimestamp: null
  },
  [WorkQueueSectionName.OtherPatientReports]: {
    lastRetrievedTimestamp: null
  },
  [WorkQueueSectionName.TasksDue]: {
    lastRetrievedTimestamp: null
  },
  [WorkQueueSectionName.TicketsAndCallbackRequests]: {
    lastRetrievedTimestamp: null
  },
  [WorkQueueSectionName.OverdueReports]: {
    lastRetrievedTimestamp: null
  }
};

export const sectionToNameMap: Record<WorkQueueSectionName, Section> = {
  [WorkQueueSectionName.AssignedToMe]: Section.Assigned,
  [WorkQueueSectionName.UrgentPatientReports]: Section.Urgent,
  [WorkQueueSectionName.OtherPatientReports]: Section.NonUrgent,
  [WorkQueueSectionName.TasksDue]: Section.TasksDue,
  [WorkQueueSectionName.OverdueReports]: Section.Overdue,
  [WorkQueueSectionName.TicketsAndCallbackRequests]: Section.Callback
};

export const sectionDisplayedNameToNameMap: Record<Section, WorkQueueSectionName> = {
  [Section.Assigned]: WorkQueueSectionName.AssignedToMe,
  [Section.Urgent]: WorkQueueSectionName.UrgentPatientReports,
  [Section.NonUrgent]: WorkQueueSectionName.OtherPatientReports,
  [Section.TasksDue]: WorkQueueSectionName.TasksDue,
  [Section.Overdue]: WorkQueueSectionName.OverdueReports,
  [Section.Callback]: WorkQueueSectionName.TicketsAndCallbackRequests
};

export const SECTION_HEADER_OFFSET = 30;
export const WQ_SECTION_WITHOUT_TICKETS_PAGE_NUMBER_SERVER_RESPONSE = -1;
