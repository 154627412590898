import { FC } from 'react';

import { Typography } from '@mui/material';

import { getBadgeForTicket } from 'utils/TicketBadgeUtils';

import { Notification } from 'components/NotificationCenter/Notification';

import {
  ClinicianMentionNotification,
  ClinicianNotification
} from 'components/NotificationCenter/types';
import {
  getNotificationSummary,
  getNotificationTicketClassText
} from 'components/NotificationCenter/utils';

interface Props {
  notification: ClinicianMentionNotification;
  onClick: (notification: ClinicianNotification) => void;
}

export const MentionNotification: FC<Props> = ({ notification, onClick }) => {
  const { changeCreatedBy, patient, ticket } = notification.notificationDetails;

  const ticketOrTask = getNotificationTicketClassText(ticket);
  const title = (
    <>
      <Typography variant="body1">
        {changeCreatedBy?.firstName} {changeCreatedBy?.lastName}
      </Typography>{' '}
      mentioned you on a {ticketOrTask} for{' '}
      <Typography variant="body1">
        {patient?.firstName} {patient?.lastName}
      </Typography>
    </>
  );

  const summary = getNotificationSummary(ticket);
  const badge = getBadgeForTicket(ticket.ticketClass, ticket.ticketKind, ticket.urgency);

  return (
    <Notification
      notification={notification}
      onClick={onClick}
      summary={summary}
      badge={badge}
      title={title}
    />
  );
};
