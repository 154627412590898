import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Show | AnalyticEventAction.Hide;
type Value = 'task' | 'ticket';

interface Properties {
  action: Action;
  value: Value;
  page_number: number;
  type: 'long ticket description' | null;
}

export const trackShowMoreAnalyticsEvent = ({ action, value, page_number, type }: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.ShowDetails,
    properties: { action, value, page_number, type }
  });
};
