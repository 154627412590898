// @ts-strict-ignore
import { ReactNode } from 'react';

import { AxiosError } from 'axios';
import { UnauthorizedError, ErrorWithUi, ErrorName } from 'errors';

import { isProd } from 'utils/EnvUtils';

import { HttpStatusCode } from 'constants/httpStatusCode.const';

import { reportError } from './sentryService';

// since react does not provide a way to catch all errors (especially for async actions)
// we use global event handlers for errors and promise rejections
// globalErrorHandler globalUnhandledRejectionHandler being used in index.html
function stopPropagationAndDefault(event: Event) {
  event.preventDefault(); // prevent re-throw the error
  event.stopImmediatePropagation(); // stop Propagation to global react & sentry global handlers
}

type onLogoutAction = () => void;
type onDisplayWithPopUpAction = (error: Error, description: ReactNode) => void;
interface InjectableErrorActions {
  onLogout: onLogoutAction;
  onDisplayWithPopUp: onDisplayWithPopUpAction;
}

const injectedActions: InjectableErrorActions = {
  onLogout: () => {
    throw new Error('Error logout action not set');
  },
  onDisplayWithPopUp: () => {
    throw new Error('Error DisplayWithPopUp action not set');
  }
};

export function initErrorHandling(actions: InjectableErrorActions) {
  injectedActions.onLogout = actions.onLogout;
  injectedActions.onDisplayWithPopUp = actions.onDisplayWithPopUp;
}

export function attachGlobalErrorHandlers() {
  (window as any).globalErrorHandler = (errorEvent: ErrorEvent) => {
    stopPropagationAndDefault(errorEvent);
    handleError(errorEvent.error);
  };

  (window as any).globalUnhandledRejectionHandler = (
    promiseRejectionEvent: PromiseRejectionEvent
  ) => {
    stopPropagationAndDefault(promiseRejectionEvent);
    handleError(promiseRejectionEvent.reason);
  };
}

// ============== IMPORTANT =================//
// This service will replace errorHandler.ts //
// After integration is finished (EH-2300)   //
// ==========================================//

export enum ErrorActions {
  reportToSentry,
  logout,
  displayWithPopUp
}

export const checkUnauthorizedError = (error: AxiosError) => {
  if (error.response.status === HttpStatusCode.UNAUTHORIZED) {
    throw new UnauthorizedError('User Unauthorized');
  }
};

function getErrorActions(error: Error): ErrorActions[] {
  switch (error.name) {
    case ErrorName.Unauthorized:
      return [ErrorActions.logout];
    case ErrorName.NoNetwork: //  Offline
    case ErrorName.DisableNonEmptyLocation: // User tried to disable in-use location
    case ErrorName.ResolveTicketsConflict: //  Error while trying to resolve tickets - conflict with other tickets)
    case ErrorName.ResolveClosedTicket: //  Error while trying to resolve tickets - already resolved
    case ErrorName.TicketDoublePickup: //  Ticket has already been picked up by another user
    case ErrorName.OverlappingIntervals: //  Interval overlaps (only UI notification required)
    case ErrorName.UserBlockSendingSms: //  Patient has Blocked SMS Messages
    case ErrorName.DraftCallActionFailed: // Try to modify call which no longer exist
    case ErrorName.DuplicateEpisodeEndReason:
    case ErrorName.UserMissingData: // user is missing data (email / phone)
    case ErrorName.MrnInUse:
    case ErrorName.TicketConnectedToDraft:
    case ErrorName.ThoughtSpotNewUser:
    case ErrorName.BulkInviteInProgress:
    case ErrorName.LandPhoneOnAutoProtocol:
      return [ErrorActions.displayWithPopUp];
    // No action (in-page error displayed only)
    case ErrorName.DuplicateEpisodeName:
    case ErrorName.InvalidEpisodeDuration:
    case ErrorName.MissingPermission:
    case ErrorName.CareIntervalFailed:
    case ErrorName.NoServerResponse:
    case ErrorName.RequestCancelled:
      return [];
    // errors which are always silent (if error is silent in specific cases - use handleErrorSilently)
    case ErrorName.FailedToGetHistory:
    case ErrorName.AuditFailed:
    case ErrorName.UpdateIntroFeatureFailed:
    case ErrorName.MissingEntity:
      return [ErrorActions.reportToSentry];
    //Add more error-specific behavior here
    default:
      return [ErrorActions.reportToSentry, ErrorActions.displayWithPopUp];
  }
}

export function handleError(error: Error, actions?: ErrorActions[]) {
  if (!isProd) {
    console.error(error);
  }

  // error actions can be overridden by actions parameter
  actions = actions || getErrorActions(error);
  actions.forEach((action) => {
    switch (action) {
      case ErrorActions.logout:
        injectedActions.onLogout();
        break;
      case ErrorActions.reportToSentry:
        reportError(error);
        break;
      case ErrorActions.displayWithPopUp:
        // TODO: change postError to use internally error.description
        // instead of errorDescription. then second param can be  removed
        const description = error instanceof ErrorWithUi ? error.ui.description : error?.message;
        injectedActions.onDisplayWithPopUp(error, description);
        break;
      default:
        console.error('[handleError] unknown action');
    }
  });
}

export function handleErrorSilently(error: Error) {
  const actions = getErrorActions(error).filter(
    (action) => action !== ErrorActions.displayWithPopUp
  );
  handleError(error, actions);
}
