import React, { FC, ForwardRefExoticComponent, forwardRef } from 'react';

import { Typography, useTheme, TypeText, css } from '@mui/material';
import { styled } from '@mui/material/styles';

import { TypographyProps } from '@mui/material/Typography';

import { Testable } from 'utils/TypeUtils';

export interface TextProps extends Omit<TypographyProps, 'noWrap'>, Testable {
  isEllipsis?: boolean;
  color?: keyof TypeText;
  width?: string;
  component?: React.ElementType;
  maxNumberOfLines?: number;
}

export const Text: FC<TextProps> = forwardRef(
  (
    {
      children,
      color = 'primary',
      width,
      isEllipsis = false,
      component = 'span',
      testHook,
      maxNumberOfLines,
      ...typographyProps
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <StyledTypography
        noWrap={isEllipsis || Boolean(maxNumberOfLines)}
        color={theme.palette.text[color]}
        width={width}
        flex={isEllipsis ? 1 : 'initial'}
        maxNumberOfLines={maxNumberOfLines}
        component={component}
        data-test-hook={testHook}
        {...typographyProps}
        ref={ref}
      >
        {children}
      </StyledTypography>
    );
  }
) as ForwardRefExoticComponent<TextProps>;

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'maxNumberOfLines'
})<{ maxNumberOfLines?: number }>(
  ({ maxNumberOfLines }) => css`
    ${maxNumberOfLines &&
    css`
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: ${maxNumberOfLines};
      white-space: normal;
    `}
  `
) as typeof Typography;
